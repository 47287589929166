import React, { Fragment } from "react";
import loadable from "@loadable/component";
const LoadableLazyLoadImage = loadable.lib(() => import("react-lazy-load-image-component"));

const LazyImage = (props) => (
    <Fragment>
        <LoadableLazyLoadImage fallback={<img {...props} />}>
            {({ LazyLoadImage }) => <LazyLoadImage {...props} effect="blur" />}
        </LoadableLazyLoadImage>
        <noscript>
            <img {...props} />
        </noscript>
    </Fragment>
);

export default LazyImage;
