/* eslint-disable max-statements */

/* eslint-disable max-params */
export default (api) => {

    const fetchLeadAppointmentInfo = (appointmentId, params) => {
        return api.get(`/v1/sell-online/appointment/apt-${appointmentId}`, { params });
    };

    const fetchVehicleSpecs = () => {
        return api.get(`/v1/config/vehicle-inspection-metric`);
    };

    const createLeadAppointment = (payload) => {
        return api.post(`/v1/sell-online/appointment`, { ...payload });
    };

    const updateLeadAppointment = (appointmentId, payload) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}`, { ...payload });
    };

    const submitConditionQuestion = (appointmentId, payload) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/question`, { ...payload });
    };

    const sendAppointmentOtp = (appointmentId) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/otp/send`, null, {
            headers: {
                X_COUNTRY: "AE"
            }
        });
    };

    const verifyAppointmentOtp = (appointmentId, payload) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/otp/verify`, { ...payload });
    };

    const fetchDsOfferedPrice = (appointmentId) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/offered-price`);
    };

    const fetchAppointmentSlots = () => {
        return api.get(`/v1/sell-online/appointment/booking-slot`);
    };

    const confirmLeadAppointment = (appointmentId, payload) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/confirm-appointment`, { ...payload });
    };

    const fetchPricePostInspection = (appointmentId) => {
        return api.get(`/v1/sell-online/appointment/apt-${appointmentId}`);
    };

    const updateInspectionEvaluationStatus = (appointmentId, statusCode) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/post-inspection/${statusCode}`);
    };

    const submitDisputesToInspection = (appointmentId, payload) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/raise-dispute`, { ...payload });
    };

    const uploadSellerDocuments = (appointmentId, formData) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/upload-documents`, formData, {
            headers: {
                "content-type": "multipart/form-data;"
            }
        });
    };

    const updateStatePostUpload = (appointmentId) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/upload-document`);
    };

    const updatePriceStatus = (appointmentId) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/accept-price`);
    };

    const fetchCarStores = () => {
        return api.get(`/v1/config/cars24-stores`);
    };

    const cancelSellerInspection = (appId, payload) => {
        return api.put(`/v1/sell-online/appointment/apt-${appId}/cancel-appointment`, payload);
    };

    const getAppointmentsWithPhone = (payload) => {
        return api.post(`v1/sell-online/sell-orders?page=1&size=30`, payload);
    };

    const provideCRMLeadConfirmation = (appointmentId) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/provide-appointment-confirmation`);
    };

    const getFeedbackOptions = () => {
        return api.get(`/v1/config/reasons`);
    };
    const submitFeedbackReason = (appointmentId, payload) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/submit-feedback`, payload);
    };

    const bookInspection = (appointmentId, type, payload) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/book-appointment/${type}`, { ...payload });
    };

    const uploadDocument = (appointmentId, payload, token) => {
        return api.post(`/v1/document/${appointmentId}/upload`, payload, {
            headers: {
                "content-type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const removeDocument = (appointmentId, documentId, token) => {
        return api.delete(`/v1/document/${appointmentId}/remove/${documentId}`,  {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const fetchDocuments = (appointmentId, token) => {
        return api.get(`/v1/document/fetch/${appointmentId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getConfig = () => {
        return api.get(`/v1/config/variables`);
    };

    const getPaymentStatus = (appId, bookingOrderId, orderId, secureToken) => api.get(`/v1/appointment-booking/apt-${appId}/payment-status/${bookingOrderId}/${orderId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});

    return {
        fetchVehicleSpecs,
        fetchLeadAppointmentInfo,
        createLeadAppointment,
        updateLeadAppointment,
        submitConditionQuestion,
        sendAppointmentOtp,
        verifyAppointmentOtp,
        fetchDsOfferedPrice,
        fetchAppointmentSlots,
        confirmLeadAppointment,
        fetchPricePostInspection,
        submitDisputesToInspection,
        updateInspectionEvaluationStatus,
        uploadSellerDocuments,
        updateStatePostUpload,
        updatePriceStatus,
        fetchCarStores,
        cancelSellerInspection,
        getAppointmentsWithPhone,
        provideCRMLeadConfirmation,
        getFeedbackOptions,
        submitFeedbackReason,
        bookInspection,
        uploadDocument,
        removeDocument,
        fetchDocuments,
        getConfig,
        getPaymentStatus
    };
};
