/* eslint-disable max-statements */
import React, { memo, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

import LandingHeroBanner from "../landing-hero-banner";
import SeasonalSaleHome from "../seasonal-sale-home";
import UsedCarsCatagory from "../used-cars-catagory";
import LazyComponent from "../../shared/lazy-component";
import ErrorHandler from "../../shared/with-error-handler";
import MetaTagsCarHome from "../../shared/meta-tags-car-home";

// Components
const FaqSection = loadable(() => import("../faq-section"), { ssr: true });
const OurPresenceInRegions = loadable(() => import("../presence-regions"), { ssr: true });
const TestimonialsLanding = loadable(() => import("../car-landing-testimonials"), { ssr: true });
const Cars24ExclusiveBenefit = loadable(() => import("../cars24-exclusive-benefits"));
const HomeCarSection = loadable(() => import("../home-car-section"));

// Utilities
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";

// Constant
import homeObject from "../../../locales/desktop/home";

// Constants
import { COOKIE_CONSTANTS, NUMBER, HOME_CAR_SECTION } from "../../../constants/app-constants";

// Utilities
import parseCookie from "../../../utils/helpers/parse-cookie";
import LoanTypeBannerLanding from "../loan-type-banner-landing";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
// import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
// import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import SellCarBanner from "../sell-car-banner";

const MemoizedHomePageSection = memo(({ warrantyMonths, updateLocationPopupShown }) => {
    return (
        <Fragment>
            <div styleName={"styles.exclusiveBenefitWrap"}>
                <LazyComponent>
                    <Cars24ExclusiveBenefit locales={homeObject.exclusiveBenefits} warrantyDuration={warrantyMonths} updateLocationPopupShown={updateLocationPopupShown} />
                </LazyComponent>
            </div>
        </Fragment>
    );
});

const Home = ({
    isSSR,
    selectedCityName,
    reloadCarListConnect,
    clearAllFiltersConnect,
    updateSSRStatusConnect,
    warrantyMonths,
    isReturningUser,
    fetchFaqConnect,
    setIsReturningUserConnect,
    homePageFaq = [],
    showRecentlyUsedCars,
    updateLocationPopupShownConnect,
    activeSaleConfig
}) => {
    // const { customPush } = useCustomHistoryOperations();
    const recentlyViewedCars = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]") || [];
    const hasUserVisitedBefore = recentlyViewedCars?.length !== NUMBER.ZERO || false;
    const showRecentlyUsedCarsSection = showRecentlyUsedCars || recentlyViewedCars?.length >= NUMBER.THREE;
    useEffect(() => {
        if (isSSR) {
            updateSSRStatusConnect(false);
        }
    }, [isSSR, updateSSRStatusConnect]);

    useEffect(() => {
        if (!homePageFaq?.length) {
            fetchFaqConnect({ isHomepage: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isReturningUser) {
            setIsReturningUserConnect(hasUserVisitedBefore);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserVisitedBefore]);

    useEffect(() => {
        reloadCarListConnect(true);
        clearAllFiltersConnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleSellCar = async () => {
    //     trackDesktopCustomEventsAE("buySellMyCar", { eventLabel: "sell_banner" });
    //     await customPush(SELLER_HOME_ROUTE);
    // };

    const getHomePageFaqs = () => {
        let faqsForSchema = [];
        (homePageFaq || []).forEach(({ faqList }) => {
            faqsForSchema = [...faqsForSchema, ...faqList];
        });
        return faqsForSchema;
    };

    const onViewAllCallback = () => {
        trackDesktopCustomEventsAE("faqViewAllClick");
    };

    const handleFaqExpand = () => {
        trackDesktopCustomEventsAE("faqClickHome");
    };
    const onScreenFilters = [FILTER_ENTITIES.MAKE, FILTER_ENTITIES.PRICE, FILTER_ENTITIES.BODY_TYPE, FILTER_ENTITIES.CATEGORY];
    return (
        <ErrorHandler>
            <MetaTagsCarHome />
            <section styleName={"styles.heroBannerContainer"}>
                {activeSaleConfig?.active ?
                    <SeasonalSaleHome /> :
                    <LandingHeroBanner
                        searchPageLocale={homeObject.searchPage}
                        bannerInfoLocales={homeObject.bannerInfo}
                    />
                }
            </section>
            <section styleName={"styles.usedCarCategory"}>
                <UsedCarsCatagory filterTypes={onScreenFilters} />
            </section>
            <section styleName={"styles.carSectionWrap"}>
                {isReturningUser && (
                    <React.Fragment>
                        {showRecentlyUsedCarsSection && <HomeCarSection sectionName={HOME_CAR_SECTION.RECENTLY_VIEWED} />}
                        <HomeCarSection sectionName={HOME_CAR_SECTION.RECOMMENDED} />
                    </React.Fragment>
                )}
                {!isReturningUser && <HomeCarSection sectionName={HOME_CAR_SECTION.POPULAR_CARS} />}
            </section>
            <section styleName="styles.financeWrap">
                <div styleName={"styles.container"}>
                    <div styleName={"styles.flexRow"}>
                        <LoanTypeBannerLanding />
                        <SellCarBanner />
                    </div>
                </div>
            </section>
            <MemoizedHomePageSection warrantyMonths={warrantyMonths} updateLocationPopupShown={updateLocationPopupShownConnect} />
            <section styleName={"styles.ourPresenceSection"}>
                <OurPresenceInRegions />
            </section>
            <section styleName={"styles.testimonialSection"}>
                <TestimonialsLanding />
            </section>
            <section styleName={"styles.faqWrapper"}>
                <FaqSection
                    selectedCityName={selectedCityName}
                    faqs={getHomePageFaqs()}
                    onViewAllCallback={onViewAllCallback}
                    onFaqExpandCallback={handleFaqExpand}
                />
            </section>
        </ErrorHandler>
    );
};

Home.propTypes = {
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    fetchFeaturedCarsListConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    isSSR: PropTypes.bool,
    fetchListingCountConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func,
    warrantyMonths: PropTypes.number,
    fetchRecentlyAddedCarsConnect: PropTypes.func,
    recentlyAddedCars: PropTypes.array,
    hasCitySkip: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    recommendedCars: PropTypes.array,
    homePageFaq: PropTypes.array,
    fetchFaqConnect: PropTypes.func,
    setIsReturningUserConnect: PropTypes.func,
    showRecentlyUsedCars: PropTypes.bool,
    updateLocationPopupShownConnect: PropTypes.func,
    activeSaleConfig: PropTypes.object
};

MemoizedHomePageSection.propTypes = {
    warrantyMonths: PropTypes.number,
    updateLocationPopupShown: PropTypes.func
};

export default Home;
