import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarListing from "./component";
import {
    fetchFilters,
    clearAllFilters,
    updateSelectedOnScreenFilter,
    updateAllFiltersVisibility,
    updateSortModalVisibility,
    updateFilterOptionData,
    updateSelectedSuggestions,
    updateAppliedSuggestions,
    updateAppliedFilters
} from "../filters/actions";
import { reloadCarList, resetCarList, updateSSRStatus } from "./actions";
import { setComparingActive } from "../../ae.mobile/compare-widget/actions";

const mapStateToProps = ({
    config: {
        activeSales: { ramadan } = {},
        activeSaleConfig = {}
    } = {},
    user: {
        isLoggedIn
    },
    filters: {
        allFilters,
        showAllFilters,
        appliedFilters,
        urlOptions,
        isSortModalOpen,
        isSSR,
        selectedFilters,
        selectedSort,
        recentlyViewedCars
    },
    carListing: {
        metaContent,
        isFetched,
        isLoading,
        error,
        content,
        config: {
            imageHost
        },
        totalCars
    },
    location: {
        selectedCity
    }
}) => ({
    showAllFilters,
    metaContent,
    selectedCity,
    appliedFilters,
    ramadan,
    isFetched,
    urlOptions,
    isLoading,
    isSortModalOpen,
    error,
    content,
    imageHost,
    totalCars,
    allFilters,
    isSSR,
    selectedFilters,
    selectedSort,
    recentlyViewedCars,
    activeSaleConfig,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    clearAllFiltersConnect: clearAllFilters,
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    updateAllFiltersVisibilityConnect: updateAllFiltersVisibility,
    updateSortModalVisibilityConnect: updateSortModalVisibility,
    updateFilterOptionDataConnect: updateFilterOptionData,
    resetCarListConnect: resetCarList,
    updateSelectedSuggestionsConnect: updateSelectedSuggestions,
    updateAppliedSuggestionsConnect: updateAppliedSuggestions,
    updateAppliedFiltersConnect: updateAppliedFilters,
    reloadCarListConnect: reloadCarList,
    setComparingActiveConnect: setComparingActive,
    updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarListing);
