/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import Checkbox from "../../shared/checkbox/component";
import { FILTER_DISPLAY_TEXT } from "../../../utils/filters-v2/constants";

const DiscountType = ({appliedFilters, filter, onOptionSelection}) => {
    const {
        options
    } = filter;

    const selectOption = (filterKey, optionKey, isSelected, displayText) => {
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };
    return (
        <React.Fragment>
            {
                options.filter((opt) => opt.displayText === "true").map((option) => {
                    const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: filter.key }, appliedFilters);
                    return (
                        <div key={option.key} styleName={"styles.itemWrap"}>
                            <Checkbox checkedStatus={isSelected} onClickCallback={selectOption.bind(null, filter.key, filter.key, !isSelected, option.displayText)} id={`${filter.key}${option.key}`} name="" />
                            <div styleName={`styles.listTextRevamp ${isSelected ? `styles.active` : ``}`}>
                                {FILTER_DISPLAY_TEXT[filter.key]}
                            </div>
                        </div>
                    );
                })
            }
        </React.Fragment>
    );
};
DiscountType.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    allFilters: PropTypes.object
};
export default DiscountType;
