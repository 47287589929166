/* eslint-disable complexity */
import { LISTING_TYPE, NUMBER } from "../../constants/app-constants";
import { FILTER_ENTITIES } from "../../utils/filters-v2/constants";

const basicParser = filter => filter.map(fv => fv.displayText).join("|");
const rangeParser = (filter = []) => (filter && filter[0]) ? [filter[0].min, filter[0].max].join("-") : undefined;
const budgetParser = (filter = []) => (filter && filter[0]) ? {
    min: filter[0].min,
    max: filter[0].max
} : {};

const parserMap = {
    [FILTER_ENTITIES.PRICE]: budgetParser,
    [FILTER_ENTITIES.MAKE]: basicParser,
    [FILTER_ENTITIES.ODOMETER_READING]: rangeParser,
    [FILTER_ENTITIES.YEAR]: rangeParser,
    [FILTER_ENTITIES.FUEL_TYPE]: basicParser,
    [FILTER_ENTITIES.TRANSMISSION]: basicParser,
    [FILTER_ENTITIES.BODY_TYPE]: basicParser,
    [FILTER_ENTITIES.REGISTRATION_CITY]: basicParser,
    [FILTER_ENTITIES.DISCOUNT]: basicParser,
    [FILTER_ENTITIES.BOOKED]: basicParser
};

export const parseFilterToDimensionGA4 = (filters, applied, selectedSort) => {

    const appliedFilters = Object.keys(filters || {})
        .filter(filter => applied.filter(f => f.filterKey === filter).length && parserMap[filter])
        .reduce((pv, cv) => ({
            ...pv,
            ...{ [cv]: parserMap[cv](applied.filter(f => f.filterKey === cv)) }
        }), {});

        return {
            ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension65: appliedFilters[FILTER_ENTITIES.PRICE].min }),
            ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension66: appliedFilters[FILTER_ENTITIES.PRICE].max }),
            ...(appliedFilters[FILTER_ENTITIES.MAKE] && { dimension67: appliedFilters[FILTER_ENTITIES.MAKE] }),
            ...(appliedFilters[FILTER_ENTITIES.ODOMETER_READING] && { dimension68: appliedFilters[FILTER_ENTITIES.ODOMETER_READING] }),
            ...(appliedFilters[FILTER_ENTITIES.YEAR] && { dimension69: appliedFilters[FILTER_ENTITIES.YEAR] }),
            ...(appliedFilters[FILTER_ENTITIES.FUEL_TYPE] && { dimension70: appliedFilters[FILTER_ENTITIES.FUEL_TYPE] }),
            ...(appliedFilters[FILTER_ENTITIES.TRANSMISSION] && { dimension71: appliedFilters[FILTER_ENTITIES.TRANSMISSION] }),
            ...(appliedFilters[FILTER_ENTITIES.BODY_TYPE] && { dimension72: appliedFilters[FILTER_ENTITIES.BODY_TYPE] }),
            ...(appliedFilters[FILTER_ENTITIES.REGISTRATION_CITY] && { dimension73: appliedFilters[FILTER_ENTITIES.REGISTRATION_CITY] }),
            ...(appliedFilters[FILTER_ENTITIES.DISCOUNT] && { dimension74: appliedFilters[FILTER_ENTITIES.DISCOUNT] }),
            ...(appliedFilters[FILTER_ENTITIES.BOOKED] && { dimension75: appliedFilters[FILTER_ENTITIES.BOOKED] }),
            ...(selectedSort && selectedSort.key && { dimension76: selectedSort.key })
        };
};

const carData = (carDetailsData, {
    position,
    excludedKeys = [ ],
    filterDimensions = { },
    bookingAmount = NUMBER.ZERO,
    listingType =  LISTING_TYPE.HOME_PAGE,
    locationId = ""
}) => {
    const {
        appointmentId,
        carId,
        price,
        lastPrice,
        listingPrice,
        make,
        body = "",
        bodyType = "",
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        model,
        transmissionType,
        variant,
        discount = NUMBER.ZERO
    } = carDetailsData || {};

    const data = {
        item_id: appointmentId || carId,
        item_name: `${year} ${make} ${model}`,
        coupon: "",
        currency: "AED",
        discount,
        ...(position && { index: position }),
        item_brand: make,
        ps_index: position,
        item_category: body || bodyType,
        item_list_id: listingType,
        item_list_name: listingType,
        item_variant: variant,
        location_id: locationId,
        price: price || lastPrice || listingPrice,
        quantity: NUMBER.ONE,
        dimension51: city,
        dimension52: views,
        dimension53: odometerReading || kilometerDriven,
        dimension54: year,
        dimension55: transmissionType,
        dimension56: model,
        dimension64: "",
        ...filterDimensions,
        dimension103: bookingAmount
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

export const carListingEventDataParserGA4 = (payload, properties) => ({
    ecommerce: {
        items: [carData(payload, properties)]
    }
});
