/* eslint-disable max-len */
import { appUrl } from "./url-constants";

export const stringConstants = {
    YES: "yes",
    NO: "no"
};

export const LANGUAGES = {
    en: { code: "en", name: "English", dir: "ltr", apiLocaleCode: "EN" }
};

export const directionConstant = {
    LEFT: "Left",
    RIGHT: "Right"
};

export const FASTLY_BASE_URL = "https://media-ae.cars24.com";

export const NUMBER = {
    ZERO: 0,
    DECIMAL_THREE: 0.3,
    DECIMAL_FIVE: 0.5,
    DECIMAL_EIGHT: 0.8,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    ELEVEN: 11,
    TWELVE: 12,
    THIRTEEN: 13,
    FOURTEEN: 14,
    FIFTEEN: 15,
    SIXTEEN: 16,
    SEVENTEEN: 17,
    EIGHTEEN: 18,
    NINETEEN: 19,
    TWENTY: 20,
    TWENTY_TWO: 22,
    TWENTY_THREE: 23,
    TWENTY_FOUR: 24,
    TWENTY_FIVE: 25,
    TWENTY_SEVEN: 27,
    TWENTY_EIGHT: 28,
    TWENTY_NINE: 29,
    THIRTY: 30,
    THIRTY_FOUR: 34,
    FOURTY: 40,
    FIFTY: 50,
    FIFTY_SIX: 56,
    SIXTY: 60,
    SIXTY_EIGHT: 68,
    SEVENTY: 70,
    SEVENTY_TWO: 72,
    SEVENTY_SIX: 76,
    SEVENTY_SEVEN: 77,
    EIGHTY: 80,
    EIGHTY_FOUR: 84,
    EIGHTY_FIVE: 85,
    EIGHTY_SIX: 86,
    EIGHT_THOUSAND: 8000,
    NINETY: 90,
    NINETY_FIVE: 95,
    NINETY_NINE: 99,
    HUNDRED: 100,
    HUNDRED_FIVE: 105,
    HUNDRED_FIFTY: 150,
    TWO_HUNDRED: 200,
    FOUR_HUNDRED: 400,
    ONE_HUNDRED_TWENTY_FIVE: 125,
    ONE_HUNDRED_THIRTY: 130,
    TWO_HUNDRED_SIXTY: 260,
    ONE_HUNDRED_EIGHTY: 180,
    ONE_HUNDRED_EIGHTY_EIGHT: 188,
    ONE_HUNDRED_THIRTY_FOUR: 134,
    TWO_HUNDRED_SEVENTY: 270,
    TWO_HUNDRED_EIGHTY: 280,
    TWO_HUNDRED_NINETY_TWO: 292,
    THREE_HUNDRED: 300,
    THREE_HUNDRED_TWENTY_NINE: 329,
    THREE_HUNDRED_SIXTY: 360,
    THREE_HUNDRED_SIXTY_FIVE: 365,
    FIVE_HUNDRED: 500,
    THOUSAND: 1000,
    THOUSAND_TWENTY_FOUR: 1024,
    TWELVE_HINDRED: 1200,
    FIFTEEN_HUNDRED: 1500,
    NINETEEN_HUNDRED: 1900,
    TWO_THOUSAND: 2000,
    TWO_THOUSAND_TEN: 2010,
    TWO_THOUSAND_THIRTEEN: 2013,
    TWO_THOUSAND_SXTEEN: 2016,
    TWO_THOUSAND_TWENTY_THREE: 2023,
    TWO_THOUSAND_FIVE_HUNDRED: 2500,
    THREE_THOUSAND: 3000,
    FOUR_THOUSAND: 4000,
    FIVE_THOUSAND: 5000,
    SIX_THOUSAND: 6000,
    TEN_THOUSAND: 10000,
    TWENTY_FIVE_THOUSAND: 25000,
    TWENTY_THOUSAND: 20000,
    FIFTY_THOUSAND: 50000,
    SIXTY_THOSAND: 60000,
    SIXTY_FIVE_THOSAND: 65000,
    ONE_LAKH: 100000,
    TWO_LAKH: 200000,
    TEN_LAKH: 1000000,
    THIRTY_LAKH: 3000000,
    NINETY_NINE_LAKH: 9900000,
    TWENTY_ONE: 21,
    TWENTY_SIX: 26,
    THIRTY_ONE: 31,
    THIRTY_TWO: 32,
    THIRTY_THREE: 33,
    THIRTY_SIX: 36,
    THIRTY_SEVEN: 37,
    THIRTY_NINE: 39,
    FOURTY_ONE: 41,
    FOURTY_FOUR: 44,
    FOURTY_SIX: 46,
    FOURTY_NINE: 49,
    FOURTY_SEVEN: 47,
    FIFTY_FOUR: 54,
    FIFTY_NINE: 59,
    TWO_TWENTY_NINE: 229,
    NINETEEN_SEVENTY: 1970,
    THOUSAND_ONE_FORTY_THREE: 1143,
    NINE_THOUSAND_NINE_HUNDRED_NINETY_NINE: 9999,
    EIGHT_CRORE_SIXTY_FOUR_LAKHS: 86400000
};

export const MAX_ALLOWED_UPLOAD_SIZE = NUMBER.FIVE * NUMBER.THOUSAND_TWENTY_FOUR * NUMBER.THOUSAND;

export const resetReducerKeys = {
    RESET_CAR_EVALUATION: "RESET_CAR_EVALUATION",
    RESET_CAR_FINDER: "RESET_CAR_FINDER",
    RESET_BRANDS: "RESET_BRANDS",
    RESET_MODELS: "RESET_MODELS",
    RESET_YEARS: "RESET_YEARS",
    RESET_VARIANTS: "RESET_VARIANTS",
    RESET_REGISTRATION_STATES: "RESET_REGISTRATION_STATES",
    RESET_KILOMETERS_DRIVEN: "RESET_KILOMETERS_DRIVEN",
    RESET_BLOGS: "RESET_BLOGS",
    RESET_TESTIMONIALS: "RESET_TESTIMONIALS",
    RESET_EXPERTS: "RESET_EXPERTS",
    RESET_FAQS: "RESET_FAQS",
    RESET_RECENTLY_INSPECTED: "RESET_RECENTLY_INSPECTED",
    RESET_HEADER: "RESET_HEADER",
    RESET_FOOTER: "RESET_FOOTER",
    META_TAGS: "RESET_META_TAGS",
    RESET_NEWS: "RESET_NEWS",
    RESET_LEAD_DATA: "RESET_LEAD_DATA",
    RESET_SEO_MODELS: "RESET_SEO_MODELS",
    RESET_BRANCH_LOCATION: "RESET_BRANCH_LOCATION",
    RESET_CITIES: "RESET_CITIES",
    RESET_APPOINTMENT_LOCATION: "RESET_APPOINTMENT_LOCATION",
    RESET_APPOINTMENT_DETAILS: "RESET_APPOINTMENT_DETAILS",
    RESET_USER: "RESET_USER",
    RESET_LOCATION: "RESET_LOCATION",
    RESET_RESCHEDULE: "RESET_RESCHEDULE",
    RESET_CONFIRMATION_DETAILS: "RESET_CONFIRMATION_DETAILS",
    RESET_SETTINGS: "RESET_SETTINGS",
    RESET_WIDGET: "RESET_WIDGET",
    RESET_REVIEW: "RESET_REVIEW",
    RESET_BRANCH_LOCATOR: "RESET_BRANCH_LOCATOR",
    RESET_ONE_STEP_APPOINTMENT: "RESET_ONE_STEP_APPOINTMENT",
    RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",
    RESET_REVIEW_SUBMISSION: "RESET_REVIEW_SUBMISSION",
    RESET_RC_TRANSFER: "RESET_RC_TRANSFER",
    RESET_TOP_SELLING_USED_CARS: "RESET_TOP_SELLING_USED_CARS",
    RESET_NEED_HELP: "RESET_NEED_HELP",
    RESET_SEO_TESTIMONIALS: "RESET_SEO_TESTIMONIALS",
    RESET_RATED_TESTIMONIALS: "RESET_RATED_TESTIMONIALS",
    RESET_BRANCH_FILTER: "RESET_BRANCH_FILTER",
    RESET_TRUECALLER: "RESET_TRUECALLER",
    RESET_RTO_INFO: "RESET_RTO_INFO",
    RESET_LEAD_BRANCHES: "RESET_LEAD_BRANCHES",
    RESET_LEAD_BRANCH_DATE: "RESET_LEAD_BRANCH_DATE",
    RESET_LEAD_PROGRESS_BAR: "RESET_LEAD_PROGRESS_BAR",
    RESET_SEO_DETAILS: "RESET_SEO_DETAILS",
    RESET_AB_EXPERIMENTS: "RESET_AB_EXPERIMENTS",
    RESET_CAR_LIST: "RESET_CAR_LIST",
    RESET_FILTERS: "RESET_FILTERS",
    RESET_CONFIG: "RESET_CONFIG",
    RESET_FUEL_PRICE: "RESET_FUEL_PRICE",
    RESET_LEAD_RTO: "RESET_LEAD_RTO",
    RESET_CAR_DETAILS: "RESET_CAR_DETAILS",
    RESET_EXPLORE_USED_CARS: "RESET_EXPLORE_USED_CARS",
    RESET_SIMILAR_CARS: "RESET_SIMILAR_CARS",
    RESET_RECENTLY_VIEWED_CARS: "RESET_RECENTLY_VIEWED_CARS",
    RESET_SELECT_OFFER_PRICE: "RESET_SELECT_OFFER_PRICE",
    RESET_PARKING_YARD: "RESET_PARKING_YARD",
    FEATURED_CARS: "FEATURED_CARS",
    RESET_CONTENT_HOOK: "RESET_CONTENT_HOOK",
    RESET_LEGACY_LEAD_FORM: "RESET_LEGACY_LEAD_FORM",
    RESET_SELL_BIKE: "RESET_SELL_BIKE",
    RESET_SCRIPT_LOADER: "RESET_SCRIPT_LOADER",
    RESET_REFER: "RESET_REFER",
    RESET_FRANCHISE_FORM: "RESET_FRANCHISE_FORM",
    RESET_PARTNER_FORM: "RESET_PARTNER_FORM",
    RESET_LOAN_FORM: "RESET_LOAN_FORM",
    RESET_BIKE_FORM: "RESET_BIKE_FORM",
    RESET_BIKE_BRANDS: "RESET_BIKE_BRANDS",
    RESET_BIKE_BRANCHES: "RESET_BIKE_BRANCHES",
    RESET_BIKE_BRANCH_DATE: "RESET_BIKE_BRANCH_DATE",
    RESET_CLASSIFIED: "RESET_CLASSIFIED",
    MAKE_AN_OFFER: "MAKE_AN_OFFER",
    USED_CAR_LEAD_INFO: "USED_CAR_LEAD_INFO",
    RESET_MARKETING_LEAD_FORM: "RESET_MARKETING_LEAD_FORM",
    RESET_LEAD_BRANCH_SELECTIONS: "RESET_LEAD_BRANCH_SELECTIONS",
    RESET_TOP_SELLING_CARS: "RESET_TOP_SELLING_CARS",
    RESET_FEATURED_CARS: "RESET_FEATURED_CARS",
    RESET_EXCHANGE_CAR_DETAILS: "RESET_EXCHANGE_CAR_DETAILS",
    RESET_TOP_DEALS_REDUCER: "RESET_TOP_DEALS_REDUCER",
    RESET_LISTING_INFO: "RESET_LISTING_INFO",
    RESET_LOAN_CALCULATION_DATA: "RESET_LOAN_CALCULATION_DATA",
    RESET_BUYER_SELLER_DETAIL: "RESET_BUYER_SELLER_DETAIL",
    RESET_BIKE_RESCHEDULE: "RESET_BIKE_RESCHEDULE",
    RESET_BIKE_CONFIRMATION_DETAILS: "RESET_BIKE_CONFIRMATION_DETAILS",
    RESET_WISHLISTED_CARS: "RESET_WISHLISTED_CARS",
    RESET_TOF_SERVICE: "RESET_TOF_SERVICE",
    RESET_TOF_SERVICE_SCHEDULE: "RESET_TOF_SERVICE_SCHEDULE",
    RESET_WISHLISTED_LOGIN: "RESET_WISHLISTED_LOGIN",
    RESET_TOAST_MESSAGE: "RESET_TOAST_MESSAGE",
    RESET_BACK_TO_TOP: "RESET_BACK_TO_TOP",
    RESET_CAR_FINDER_LISTING: "RESET_CAR_FINDER_LISTING",
    RESET_CAR_FINDER_CAR_LIST: "RESET_CAR_FINDER_CAR_LIST",
    RESET_CAR_SERVICES_DATA: "RESET_CAR_SERVICES_DATA",
    RESET_CAR_SERVICE_LEAD_FORM: "RESET_CAR_SERVICE_LEAD_FORM",
    RESET_CAR_SERVICE_INFO: "RESET_CAR_SERVICE_INFO",
    RESET_CAR_SERVICE_BOOK: "RESET_CAR_SERVICE_BOOK",
    RESET_LEAD_FUEL_TYPE: "RESET_LEAD_FUEL_TYPE",
    RESET_DEAL_PAYMENT_INFO: "RESET_DEAL_PAYMENT_INFO",
    RESET_CARS24_SELECT: "RESET_CARS24_SELECT",
    RESET_GS_BUY_CAR_DETAILS: "RESET_GS_BUY_CAR_DETAILS",
    RESET_CONSUMER_FINANCE: "RESET_CONSUMER_FINANCE",
    RESET_MY_BOOKINGS: "RESET_MY_BOOKINGS",
    RESET_MY_BOOKING_SUMMARY: "RESET_MY_BOOKING_SUMMARY",
    RESET_LOCATION_PICKER_MODAL: "RESET_LOCATION_PICKER_MODAL",
    RESET_CHECKOUT: "RESET_CHECKOUT",
    RESET_CHAT_ICON: "RESET_CHAT_ICON",
    RESET_CALLBACK_DETAILS: "RESET_CALLBACK_DETAILS",
    RESET_C24Q_PRICE: "RESET_C24Q_PRICE",
    RESET_CUSTOMER_CAR_INSPECTION_PARAMETER: "RESET_CUSTOMER_CAR_INSPECTION_PARAMETER",
    SAVED_FILTERS: "SAVED_FILTERS",
    RESET_RECOMMENDED_CARS: "RESET_RECOMMENDED_CARS",
    RESET_ALL_TICKETS: "RESET_ALL_TICKETS",
    RESET_SELL_ONLINE_OFFER_PRICE: "RESET_SELL_ONLINE_OFFER_PRICE",
    RESET_EMI_DETAILS: "RESET_EMI_DETAILS",
    RESET_SELLER_REVALUATION: "RESET_SELLER_REVALUATION",
    RESET_USER_CF_DETAILS: "RESET_USER_CF_DETAILS",
    RESET_CAR_CF_DETAILS: "RESET_CAR_CF_DETAILS",
    RESET_HOME_LIST: "RESET_HOME_LIST",
    RESET_SALESFORCE_UPLOADS: "RESET_SALESFORCE_UPLOADS",
    RESET_COMPARE_CARS: "RESET_COMPARE_CARS",
    RESET_PAYMENT_STATUS: "RESET_PAYMENT_STATUS",
    RESET_POSTBC_DETAILS: "RESET_POSTBC_DETAILS",
    RESET_FEEDBACK: "RESET_FEEDBACK",
    RESET_SELLER_APPOINTMENT_ID: "RESET_SELLER_APPOINTMENT_ID",
    RESET_TD_SCHEDULE: "RESET_TD_SCHEDULE",
    RESET_C2C_TD_SCHEDULE: "RESET_C2C_TD_SCHEDULE",
    RESET_DELIVERY_SCHEDULE: "RESET_DELIVERY_SCHEDULE",
    RESET_CAR_STORES: "RESET_CAR_STORES",
    RESET_POST_PRICE_ACCEPTANCE: "RESET_POST_PRICE_ACCEPTANCE",
    RESET_SELLER_LEADS: "RESET_SELLER_LEADS",
    RESET_RETURN_SLOT_SCHEDULE: "RESET_RETURN_SLOT_SCHEDULE",
    RESET_POSTBC_VAS: "RESET_POSTBC_VAS",
    RESET_SELLER_NPS: "RESET_SELLER_NPS",
    RESET_LOAN_PRE_APPROVAL: "RESET_LOAN_PRE_APPROVAL",
    RESET_POST_BOOKING_DELIVERY: "RESET_POST_BOOKING_DELIVERY",
    RESET_CAR_LOAN: "RESET_CAR_LOAN",
    RESET_SEO_CAR_DETAILS: "RESET_SEO_CAR_DETAILS",
    RESET_AI_RECOMMENDED_CARS: "RESET_AI_RECOMMENDED_CARS",
    RESET_LOAN_ELIGIBILITY: "RESET_LOAN_ELIGIBILITY",
    RESET_MARKET_PLACE: "RESET_MARKET_PLACE",
    RESET_SELLER_CENTER: "RESET_SELLER_CENTER"
};

export const redirect = {
    FAQ_AE: `${appUrl()}/faq`
};

export const appRedirect = {
    BUYUSEDCAR: "/buy-used-cars"
};
export const CITY_KEY = "selectedCity";

export const MISSED_CALL_CHECK_INTERVAL = 30000;

export const STEP_NAMES = {
    Brand: "brand",
    Model: "model",
    FuelType: "fuelType",
    Transmission: "transmission",
    Year: "year",
    Variant: "variant",
    State: "registrationState",
    Confirmation: "confirmation",
    KilometerDriven: "kilometersDriven",
    Branch: "branch",
    Date: "date",
    Time: "time",
    Override: "override",
    User: "user",
    Location: "location",
    Price: "price",
    Body: "body",
    Priority: "priority",
    CarService: "carService",
    Personal: "personal",
    Residential: "residential",
    Income: "income"
};

export const OTP_MAX_LENGTH = 4;

export const STATUS_TYPE = {
    SUCCESS: "SUCCESS",
    ON_HOLD: "ON_HOLD"
};

export const HTTP_CODES = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    SUCCESS: 200,
    FOUND: 302,
    MOVED_PERMANENTLY: 301,
    BAD_REQUEST: 400,
    REMOVED_PERMANENTLY: 410
};

export const KEY_DOWN_CODES = {
    DELETE: 8
};

export const DEFAULT_SLIDER_SETTINGS = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrow: true
};

export const DEFAULT_MOBILE_SLIDER_SETTINGS = {
    slidesToShow: 1.14,
    slidesToScroll: 1,
    infinite: false,
    arrow: false
};

export const COOKIE_SEARCH_KEYWORDS = "c2b-searched-keywords";
export const IGNORE_APIS = [];
export const IGNORE_PRIVATE_PRODUCT_APIS = [];
export const LOCATION_REQUEST_TYPE = {
    COORDINATES: "coordinates",
    PLACE: "place"
};

export const MAP_TYPE = {
    GOOGLE: "googlemap",
    MMI: "mapmyindia"
};

export const GOOGLE_MAP_PATH = {
    SELLER_APPOINTMENT_ADDRESS: "book-appointment/address",
    BUYER_TD_SCHEDULE_LOCATION: "test-drive/schedule/location",
    BUYER_DELIVERY_SCHEDULE_LOCATION: "delivery/schedule/location",
    BUYER_HOME_PAGE: "/",
    LOCATION_CAPTURE: "/location-capture",
    SERVICING_SCHEDULE: "/tof-service",
    SERVICING_SELECTION: "/service-contract",
    TEST_DRIVE_SCHEDULE_DESKTOP: "testDrive"
};

export const DISPOSITION_STATUS = {
    SOLD: "SOLD",
    PUBLISHED: "PUBLISHED",
    BOOKED: "BOOKED",
    LOST: "LOST",
    RE_PUBLISH: "RE-PUBLISH",
    RE_PUBLISH_1: "RE_PUBLISH",
    AUCTIONED: "AUCTIONED",
    REJECTED: "REJECTED",
    DRAFT: "DRAFT"
};

export const PRICE_RANGE_THRESHOLD = 1500000;
export const PRICE_RANGE_STEP = 10000;
export const PRICE_RANGE_BAR_GRAPH_STEP = 40000;
export const MAX_RANGE_THRESHOLD = 9999999;
export const DEFAULT_C2C_CITY = {
    city_id: "8597",
    city_name: "Delhi-NCR",
    city_slug: "delhi-ncr",
    pincode: "110001"
};
export const MIN_LOAN_AMOUNT = 50000;

export const CAR_LIST_EVENT_NAMES = {
    LISTING: "Buy_used_cars_C2C_Car_Listing_Page",
    B2C_LISTING: "Buy_used_cars_B2C_Car_Listing_Page",
    MONEYBACK: "Buy_used_cars_C2C_Car_Listing_Page_Moneyback",
    INSTANTLY_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Instantly_Available",
    FINANCE_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Finance_Available",
    PRICE_DROP: "Buy_used_cars_C2C_Car_Listing_Page_Price_Drop",
    HOME_PAGE: "Buy_used_cars_C2C_Car_Home_Page",
    B2C_HOME_PAGE: "Buy_used_cars_B2C_Homepage",
    FILTER_PAGE: "Buy_used_cars_C2C_filter_page",
    DETAILS: "Buy_used_cars_C2C_Car_Details_Page",
    VIEW_360_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_View_360_Available"
};

export const SCRIPT_TYPES = {
    mmi: "mmi"
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B"
};

export const HIDE_POPUP_FOR_URLS = [
    /([a-z]*)(confirmation)([a-z]*)/gi,
    /([a-z]*)(customer-review)([a-z]*)/gi,
    /(\/mobile-apps)/gi,
    /(offer|car-prices|specifications|images|variants|reviews|colors|mileage|service-cost|faqs|rto-vehicle-registration-details|-prices-in-|car-finder)/gi,
    /(mycar)([a-z]*)/gi,
    /(buyer-details)([a-z]*)/gi,
    /(seller-details)([a-z]*)/gi,
    /tokenpayment([a-z]*)/gi,
    /cars24-select/gi
];

export const PATHS = [
    /(\/centres)/gi,
    /(\/mobile-apps)/gi,
    /(\/customer-review)/gi,
    /(\/faq)/gi,
    ...HIDE_POPUP_FOR_URLS
];

export const FORM_TYPE = {
    CAR: "car",
    BIKE: "bike",
    CLASSIFIED_RESCHEDULE: "classified_reschedule",
    ONE_STEP: "one_step",
    BIKE_RESCHEDULE: "bike_reschedule",
    PAYWALL_BOOK_NOW: "paywall_book_now",
    PAYWALL_MAKE_OFFER: "paywall_make_offer",
    GS_BOOK_NOW: "gs_book_now",
    TOKEN_PAYMENT: "token_payment",
    VONE_STEP: "vone_step",
    CHECKOUT: "checkout"
};

export const INACTIVE_CAR_STATES = [
    DISPOSITION_STATUS.LOST,
    DISPOSITION_STATUS.SOLD,
    DISPOSITION_STATUS.DRAFT,
    DISPOSITION_STATUS.AUCTIONED,
    DISPOSITION_STATUS.REJECTED
];

export const PAYMENT_OPTIONS = {
    FINANCE: "FINANCE",
    CASH: "CASH"
};

export const ANGLE = {
    ZERO: 0,
    NINETY: 90,
    HUNDRED_EIGHTY: 180,
    TWO_HUNDRED_SEVENTY: 270,
    THREE_HUNDRED_SIXTY: 360
};

export const DEFAULT_CITY = {
    AE: {
        city_id: "36088469",
        city_name: "Dubai",
        city_slug: "dubai",
        code: "DU_DUBAI"
    }
};

export const COUNTRY_CODES = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

export const CURRENCY = {
    IN: "₹",
    AE: "AED",
    AU: "$"
};

export const COUNTRY_CODE = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

// export const AB_TEST_CONST = AB_TEST_VARIATIONS.VARIANT_A;
export const ACTIVE_EXPERIMENT_TEST = AB_TEST_VARIATIONS.VARIANT;
export const ACTIVE_VARIANT_SCREEN = {
    FUELTYPE: "fueltype",
    TRANSMISSIONTYPE: "transmissionType",
    VARIANT: "variant"
};

export const AE_CITY = "ae_city";
export const DEFAULT_AE_CITY_ID = "36088469";
export const DEFAULT_AE_CITY_CODE = "DU_DUBAI";
export const DEFAULT_AE_CITY_NAME = "Dubai";
export const C24_COUNTRY = "c24_country";
export const ABU_DHABI_CODE = "CC_AE_2";
export const DUBAI_CODE = "DU_DUBAI";
export const SHARJAH_CODE = "CC_AE_6";
export const AJMAN_CODE = "CC_AE_5";
export const UAE_CITY_NAME = "United Arab Emirates";
export const AL_AIN = "CC_AE_8";
export const UMM_AL_QUWAIN = "CC_AE_7";
export const RAS_AL_KHAIMAH = "CC_AE_4";
export const FUJAIRAH = "CC_AE_3";
export const AU_PAGINATION_SIZE = 4;
export const AU_CITY = "au_city";
export const UAE_CODE = "DU";
export const UAE_NAME = "Uae";
export const DEFAULT_AU_CITY_CODE = "AU_VIC_MEL";

export const HELPLINE_NUMBER_DISPLAY = "89299 59858";
export const HELPLINE_NUMBER_TEL = "8929959858";

// TODO: need to change from object to array for cities id.
export const APPLICABLE_CITIES_FOR_SELL_ONLINE_FLOW = {
    // "delhi": 2,
    // "gurgaon": 5,
    // "noida": 134,
    // "faridabad": 6,
    // "ghaziabad": 132,
    // "sonipat": 62,
    "mumbai": 2378

};

export const HELPLINE_NUMBER_AU = { label: "+61 410 584621", value: "+61410584621" };
export const SMS_NUMBER_AU = { label: "+61 410 584621", value: "+61410584621" };
export const HELPLINE_NUMBER = { label: "+971 567 322438", value: "+971567322438", whatsapp: "971567322438" };
export const CALL_CENTER_NUMBER = { label: "8001110900", value: "8001110900" };
export const SELLER_HELPLINE_NUMBER = { label: "+971 507 246436", value: "+971507246436", whatsapp: "971507246436" };
export const BUY_DEFAULT_CHAT_MESSAGE = `Hi, \r\n I want to get in touch with a CARS24 agent.`;
export const SELL_DEFAULT_CHAT_MESSAGE = `Hi,\r\n I want to sell my Car.`;

export const MAIL_CARE_HELP_CENTER = "care.uae@cars24.com";
export const REQUEST_CALL_TIME_TEXT = "From 7:30 am to 11:30 pm";
export const CHAT_ON_WHATSAPP_TEXT = "From 7:30 am to 11:30 pm";

export const USER_CHOICE = {
    OFFER_ACCEPTED: "accepted_offer",
    NEED_SOME_TIME: "need_some_time",
    NOT_INTERESTED: "not_interested"
};

export const COACH_DOWNLOAD_APP_URL = "https://cars24uae.app.link/wmfTrGTzplb";
export const DOWNLOAD_APP_URL = "https://cars24uae.app.link/J9U3qFmS7gb";
export const DOWNLOAD_APP_URL_IOS = "https://apps.apple.com/ae/app/cars24-uae-buy-used-cars/id1564454362";
export const DOWNLOAD_APP_URL_EMAIL = "http://onelink.to/b4cq9k";
export const TWITTER_URL = "https://twitter.com/cars24uae";
export const INSTAGRAM_URL = "https://www.instagram.com/cars24uae/";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCyFzd-mz8mC6QE-Pn2QsIWQ";
export const LINKEDIN_URL = "https://www.linkedin.com/company/cars24arabia/";
export const FACEBOOK_URL = "https://www.facebook.com/CARS24UAE";
export const HOW_TO_SELL_URL = "https://www.youtube.com/watch?v=UG9DLHjWgto";
export const GLOBAL_URL = "/buy-used-cars-uae/";
export const DEPRICATED_BASE_URL = "https://www.cars24.com";
export const CALL_NUMBER = "8001110900";
// export const HELPLINE_NUMBER

export const AE_HOMEPAGE_PAGINATION_SIZE = 5;
export const AE_DESKTOP_HOMEPAGE_PAGINATION_SIZE = 10;
const SALES_DATE_NUM = {
    YEAR: 2021,
    MONTH: 7,
    DATE: 24
};

export const SALES_EXPIRY_DATE = new Date(SALES_DATE_NUM.YEAR, SALES_DATE_NUM.MONTH, SALES_DATE_NUM.DATE);

export const RESERVATION_PERIOD_IN_HOURS = 96;
export const RESERVATION_PERIOD_IN_DAYS = 4;
export const AE_LISTING_API_COUNT = 25;

export const BOOKING_CONFIRMATION_SOURCE = {
    MY_BOOKINGS: "bookings",
    LISTING: "listing",
    DETAILS: "details",
    CONFIRMATION: "confirmation",
    POSTBC: "postbc"
};

export const MEDIA_SOURCE = {
    HELLO_AR: "helloAR",
    SPIN: "spincar"
};

export const DEVICE_TYPE = {
    MOBILE: "mobile",
    DESKTOP: "desktop"
};
export const GID_TRACKING_HELLOAR = process.env.GA_ID;

export const IMAGE_UPLOADER_ENUMS = {
    UPLOAD: "upload",
    DELETE: "delete",
    VALIDATION_FAILURE: "validationFailure"
};

export const ADDRESS_LEVEL_TYPE_GOOGLE_MAP = {
    SUBPREMISE: "subpremise",
    STREET_NUMBER: "street_number",
    ROUTE: "route",
    LOCALITY: "locality",
    ADMINISTRATIVE_AREA_LEVEL_2: "administrative_area_level_2",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    COUNTRY: "country",
    POSTAL_CODE: "postal_code",
    NEIGHBOURHOOD: "neighbourhood",
    SUBLOCALITY: "sublocality",
    POLITICAL: "political"
};

export const BOOKING_TYPE = {
    "NEX": "NEX",
    "EX": "EX",
    "MRL": "MRL"
};

export const TEST_DRIVE_TYPE = {
    HUB: "HUB",
    HOME: "HOME",
    "MRL": "MRL",
    "EX": "EX"
};

export const VAS_WARRANTY_KEY = {
    "WARRANTY": "WARRANTY",
    "INSURANCE": "INSURANCE"
};

export const testimonials = [
    {
        videoUrl: `${FASTLY_BASE_URL}/ae/testimonial/Shuaib.mp4`,
        title: "The vehicle information given is very clear!",
        subtitle: "",
        thumb: `${FASTLY_BASE_URL}/ae/testimonial/thumb_shuaib.jpg`
    },
    {
        videoUrl: `${FASTLY_BASE_URL}/ae/testimonial/Sushant.mp4`,
        title: "They have really amazing options in most of the brands!",
        subtitle: "",
        thumb: `${FASTLY_BASE_URL}/ae/testimonial/thumb_sushant.jpg`
    },
    {
        videoUrl: `${FASTLY_BASE_URL}/ae/testimonial/Suraj.mp4`,
        title: "I found the car I was looking for!",
        subtitle: "",
        thumb: `${FASTLY_BASE_URL}/ae/testimonial/thumb_suraj.jpg`
    },
    {
        videoUrl: `${FASTLY_BASE_URL}/ae/testimonial/Sameer.mp4`,
        title: "The car got delivered right to my doorstep!",
        subtitle: "",
        thumb: `${FASTLY_BASE_URL}/ae/testimonial/thumb_sameer.jpg`
    },
    {
        videoUrl: `${FASTLY_BASE_URL}/ae/testimonial/Bilal.mp4`,
        title: "Within a few clicks you can get your dream car!",
        subtitle: "",
        thumb: `${FASTLY_BASE_URL}/ae/testimonial/thumb_bilal.jpg`
    }
];

export const PRIMARY_CTA_TEXT = {
    START_PURCHASE: "Start Purchase",
    BOOK_TEST_DRIVE: "Book Test Drive",
    BOOK_FREE_TEST_DRIVE: "Book Free Test Drive",
    TRACK_BOOKING: "Track Booking",
    NOTIFY_ME: "NOTIFY WHEN AVAILABLE",
    REMOVE_FROM_WISHLIST: "REMOVE FROM WISHLIST",
    RESERVED: "Reserved",
    SIMILAR_CARS: "SEE SIMILAR CARS",
    NOT_AVAILABLE: "Not Available",
    FREE_TEST_DRIVE: "FREE TEST DRIVE",
    WISHLISTED: "WISHLISTED",
    NOTIFY: "NOTIFY ME",
    MAKE_AN_OFFER: "Make an offer",
    WISHLIST_NOW: "WISHLIST NOW",
    BACK_TO_SELLER_CENTER: "Back to Seller Centre"
};

export const CAR_STATUS = {
    RESERVED: "RESERVED",
    SOLD: "SOLD",
    AVAILABLE: "AVAILABLE"
};

export const GA_EVENT_SOURCE = {
    DETAIL_PAGE: "detail_page",
    GALLERY_PAGE: "gallery",
    EMI_POP: "emi_calc",
    EXCLUSIVE_BENEFIT_POPUP: "ExclusiveBenefitPopUp",
    OTHER: "others",
    WISHLIST: "wishlist",
    GALLERY: "gallery"
};

export const GA_EVENT_PAGE_SOURCE = {
    HOME_PAGE: "Home_page",
    LISTING_PAGE: "Listing_page",
    DETAIL_PAGE: "Detail_page",
    BOOKING_PAGE: "My_bookings_page"
};

export const PROCEED_YOUTUBE_LINK = "https://www.youtube.com/watch?v=g3ryu8DNUMg";

export const COOKIE_CONSTANTS = {
    HIDE_DISCOUNT_BANNER: "aeHideDiscountBanner",
    GA: "_ga",
    CBD_DEBUG_PATH: "cbdDebugPath",
    RECENTLY_VIEWED_CARS: "ae_rvcc",
    SEEN_OBD_GUIDE: "seen_obd_guide",
    SEEN_FINAL_PAYMENT_POPUP: "seen_final_payment_popup",
    LOCKED_COUPONS: "seenLoginForCouponNudge",
    SHOW_FILTER_RESUME_SESSION: "show_filter_resume_session",
    LOAN_APPROVED: "loan_approved",
    HAS_OPTED_OUT_DEF_BUNDLE: "hasOptedOutDefaultBundle",
    SELLER_SECTION_VIEWED: "seller_section_viewed",
    LOAN_SECTION_VIEWED: "loan_section_viewed",
    LISTING_FILTER_NUDGE_SHOWN: "listing_filter_nudge_shown",
    USER_MOBILE: "user_mobile",
    TOKEN: "updated_tkn",
    APPT_IDS_FILTER_RESUME_SESSION: "appt_ids_filter_resume_session",
    APPT_IDS_BI_BUT_NO_BC: "appt_ids_bi_but_no_bc",
    APPT_IDS_SP_BUT_NO_BI: "appt_ids_sp_but_no_bi",
    LOCATION_POPUP_SHOWN: "locationShown",
    CAR_LOAN_CALCULATOR: "car_loan_calculator",
    USER_DOCS_NOTES: "user_docs_notes",
    HIDE_AI_BOT_POPUP: "hide_ai_bot_popup",
    AI_EXPANDED_ICON: "ai_expanded_icon",
    HIDE_SWIPE_NUDGE: "HIDE_SWIPE_NUDGE",
    AE_CITY: "ae_city",
    LOAN_NUDGE_SHOWN: "LOAN_NUDGE_SHOWN",
    FIRST_TIME_VISIT_IN_A_DAY: "FIRST_TIME_VISIT_IN_A_DAY",
    BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN: "BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN"
};

export const LOCALSTORAGE_CONSTANTS = {
    FILTER_RESUME_SESSION: "FILTER_RESUME_SESSION",
    PREV_APPLIED_FILTERS: "PREV_APPLIED_FILTERS",
    PUSH_FP_UNLOCK_BANNER: "PUSH_FP_UNLOCK_BANNER",
    PAYMENT_INITIATION_SORUCE: "PAYMENT_INITIATION_SORUCE",
    TD_RATE_NOW_POPUP_ORDER_ID: "TD_RATE_NOW_POPUP_ORDER_ID"
};

export const PAYMENT_INITIATION_SORUCE_TYPE = {
    TD_MODE_SELECTION_PAGE: "TD_MODE_SELECTION_PAGE",
    SLOTS_SELECTION_PAGE: "SLOTS_SELECTION_PAGE",
    CAR_PAYMENT_PAGE: "CAR_PAYMENT_PAGE"
};

export const SENTRY_ERRORS = {
    GA_ID_ERROR: "GA_ID_ERROR",
    GA_ID_CATCH_ERROR: "GA_ID_CATCH_ERROR",
    INVALID_ORDER_ID: "INVALID_ORDER_ID",
    INVALID_APP_ID: "INVALID_APP_ID",
    CBD_ERROR: "CBDUAEPG - ERROR"
};

export const CBD_ALREADY_INITIATED = "INITIATED_TRANSACTION_ALREADY_EXIST";

export const IDENTIFIERS = {
    BUY: "BUY",
    SELL: "SELL"
};

export const FAQ_CATEGORIES = {
    TINTING: "Tinting",
    COATING: "Coating",
    SERVICE_CONTRACT: "Service Contracts",
    INSURANCE: "Insurance",
    WARRANTY: "Warranty & Coverage",
    GAP_INSURANCE: "GAP (Guaranteed Asset Protection)",
    ILOE: "ILOE",
    REFURB_AND_WARRANTY: "Refurb & Warranty"
};

export const TICKET_TYPE = {
    REPAIR: "REPAIR",
    RETURN: "RETURN"
};

export const SORT_DATA = {
    "RECENTLY_ADDED": {
        key: "recently-added",
        displayText: "Recently Added"
    },
    "POPULAR_CARS": {
        key: "releavance",
        displayText: "Relevance"
    }
};

export const CITIES_WHERE_TD_AVAILABLE = [ABU_DHABI_CODE, DUBAI_CODE];
export const TICKET_STATUS = {
    OPEN: "OPEN",
    CLOSED: "CLOSED"
};

export const DEFAULT_IMG_MORE_CARS = `${FASTLY_BASE_URL}/ae/icon/DEFAULT.svg`;

export const FINAL_PAYMENT_METHOD = {
    DAPI: "DAPI",
    NOON: "NOON",
    CBDUAEPG: "CBDUAEPG"
};
export const COMPLIMENTRY_BENEFIT_DETAIL_PAGE = {
    "warrantyFee": "Included",
    "insuranceFee": "Included",
    "shippingFee": "FREE"
};

export const COMPLIMENTRY_BENEFIT_CHECKOUT_PAGE = {
    "warrantyFee": "INCLUDED",
    "insuranceFee": "INCLUDED",
    "shippingFee": "FREE"
};

export const SIDEBAR_POPUP = {
    HOW_TO_SELL: "howToSell",
    FINANCING: "financing",
    WARRANTY: "warranty",
    SELL_ORDER: "sellOrder"
};

export const DEFAULT_LOGIN_TITLE = "Login for a personalised experience";

export const LOGIN_TITLE = {
    WISHLIST: "Please login to view your wishlist",
    ADD_TO_WISHLIST: "Please login to wishlist this car",
    FINANCING: "Please login to view finance plans",
    LOAN_PRE_APPROVAL: "Login to continue with your application",
    TOF_SERVICE_CONTRACT: "Please login to buy service contract",
    couponNudge: (discountAmt = NUMBER.ZERO) => `Please login to unlock your coupon of AED ${discountAmt}`,
    startPurchase: (carName) => `Please login to book your ${carName} free test drive`,
    makeOffer: (carName) => `Please login to make an offer for ${carName}`,
    MY_BOOKINGS: "Please login to view your myBookings",
    ACTIVE_SALE: "Login to view discounted prices",
    SAVE_FILTERS: "Login to save your filters!"
};

export const DAPI_STATUS = {
    "FULFILLED": "fulfilled"
};

export const DAPI_ERROR_TYPE = [
    "BENEFICIARY_ALREADY_EXISTS",
    "BENEFICIARY_NAME_TAKEN",
    "BENEFICIARY_NICKNAME_NAME_TAKEN"
];

export const TRANSACTION_CHARGES = {
    CONVENIENCE_FEE: "Service Charge"
};

export const ZERO_DP_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED"
};

export const DETAIL_POPUP_EVENT = {
    EMI_POPUP: "EMI_POPUP",
    STATIC_PAGE: "STATIC_PAGE",
    VIEW_ALL_FEATURES: "VIEW_ALL_FEATURES"
};

export const UP_ARROW_ROTATE_DEGREE = NUMBER.ZERO;
export const DOWN_ARROW_ROTATE_DEGREE = NUMBER.ONE_HUNDRED_EIGHTY;

export const LEFT_ARROW_ROTATE_DEGREE = NUMBER.ZERO;
export const RIGHT_ARROW_ROTATE_DEGREE = NUMBER.ONE_HUNDRED_EIGHTY;

export const IS_NEW_USER = {
    SIGN_IN: "no",
    SIGN_UP: "yes"
};

export const USER_LOGIN_STATUS = {
    LOGGED_IN: "loggedin",
    LOGGED_OUT: "loggedout"
};

export const USER_LOGIN_MEDIUM = {
    PHONE: "phone",
    NONE: ""
};

export const LISTING_TYPE = {
    HOME_PAGE: "home_page",
    CAR_DETAILS_PAGE: "car_details_page",
    CAR_LISTING_PAGE: "listing",
    WISHLIST_PAGE: "wishlist_page",
    CHECKOUT_PAGE: "checkout_page",
    AI_PICKED_CARS: "ai_car_finder",
    SIMILAR_CARS: "similar",
    RECOMMENDED: "recommended",
    PERSONALIZED_CARS: "personalized_for_you"
};

export const ENBD_LOGO = `${FASTLY_BASE_URL}/ae/partnerBankLogos/ENBD.png`;
export const SERVICE_EXTRA_CHARGES_KEYS = {
    PICKUP_CHARGES: "pickupCharges",
    TRANSFER_CHARGES: "transferCharges",
    WINDSCREEN_CHARGES: "windScreen"
};

export const SERVICE_EXTRA_CHARGES_INITIAL = {
    offerPrice: NUMBER.ZERO,
    fullPrice: NUMBER.ZERO
};

export const PAYMENT_STATUS_NOON = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED"
};

export const SERVICE_ADD_ONS = {
    [SERVICE_EXTRA_CHARGES_KEYS.PICKUP_CHARGES]: {
        title: "Don't leave the comfort of your home to get your car serviced anymore!",
        description: [
            "Live hassle-free with Cars24 complimentary pick-up-drop facility"
        ],
        placeHolder: "Pick-up drop facility",
        priceTitle: "Pick-up-drop facility charges"
    },
    [SERVICE_EXTRA_CHARGES_KEYS.TRANSFER_CHARGES]: {
        title: "Along with the car transfer, now also transfer the service contract to the new owner.",
        description: [
            "Hassle free transfer if you sell your car",
            "Appreciates your car value when you sell"
        ],
        placeHolder: "Ownership transfer",
        priceTitle: "Ownership transfer charges"
    },
    [SERVICE_EXTRA_CHARGES_KEYS.WINDSCREEN_CHARGES]: {
        title: "",
        description: [],
        placeHolder: "",
        priceTitle: "Front windscreen clear charges"
    }
};

export const HOME_CAR_SECTION = {
    RECOMMENDED: "RECOMMENDED",
    RECENTLY_ADDED: "RECENTLY_ADDED",
    RECENTLY_VIEWED: "RECENTLY_VIEWED",
    POPULAR_CARS: "POPULAR_CARS"
};

export const PLATFORM = "web";

export const GEOGRAPHIES_LINKS = [
    {
        name: "India",
        href: "https://www.cars24.com/",
        target: "_blank"
    },
    {
        name: "Australia",
        href: "https://www.cars24.com.au/",
        target: "_blank"
    }
];

export const LOAN_TYPE = {
    "ZERO_DP": "ZERO_DOWN_PAYMENT",
    "CUSTOM_DP": "CUSTOM_DOWN_PAYMENT",
    "PERSONAL_LOAN": "PERSONAL_LOAN",
    "GREEN_CUSTOM_DOWN_PAYMENT": "GREEN_CUSTOM_DOWN_PAYMENT",
    "GREEN_ZERO_DOWN_PAYMENT": "GREEN_ZERO_DOWN_PAYMENT"
};

export const RECENT_SEARCHES_LABEL = "Recent Searches";

export const LISTING_CARD_CLICK_SECTION = {
    SEARCH_EXACT: "search_exact",
    SEARCH_SIMILAR: "search_similar",
    FILTER_EXACT: "filter_exact",
    FILTER_SIMILAR: "filter_similar",
    SEARCH_FILTER_EXACT: "search_filter_exact",
    SEARCH_FILTER_SIMILAR: "search_filter_similar",
    BOTTOM_RECOMMENDED: "bottom_recommeded",
    NORMAL_LISTING: "normal_listing",
    RECENTLY_VIEWED: "recently_viewed",
    NEWLY_ADDED: "newly_added",
    RECOMMENDED_NEW: "recommended_new",
    RECOMMENDED_REPEAT: "recommended_repeat"
};

export const TD_MRL_BANNER_VIDEO_URL = `${FASTLY_BASE_URL}/ae/video/CARS24UAEMRL.mp4`;
export const TINTING_BANNER_VIDEO_URL = "https://www.youtube.com/watch?v=nfCPB--MiWQ";
export const COATING_BANNER_VIDEO_URL = "https://www.youtube.com/watch?v=l9yTHNgmWGw";

export const SERVICE_HISTORY_URL = `${FASTLY_BASE_URL}/ae/car_detail/service_history/`;
export const CAR_CONDITION_URL = `${FASTLY_BASE_URL}/ae/testimonial/Car_condition_MRL_Video.m4v`;
export const CAR_PRIME_URL = `${FASTLY_BASE_URL}/ae/testimonial/CARS24_certified_static_video.mp4`;

export const LISTING_DETAILS_REGEX = /^buy-used(-([\w-]+))?(-cars-([\w\s-]+))(\?.*)?$/;
export const BLACK_LISTED_EMIRATES = [
    DUBAI_CODE,
    AL_AIN,
    UMM_AL_QUWAIN,
    RAS_AL_KHAIMAH,
    FUJAIRAH
];

export const EMIRATES_URL = `${FASTLY_BASE_URL}/ae/content/emirates/`;
export const HOME_LISTING_BANNERS = `${FASTLY_BASE_URL}/ae/banner/`;

export const ASSORTMENT_CATEGORY_LABEL = {
    PRIME: "PRIME",
    LITE: "LITE",
    GREEN: "GREEN",
    PRIVATE_SELLER: "PRIVATE_SELLER",
    PRIVATE_SELLER_PRO: "PRIVATE_SELLER_PRO"
};

export const ASSORTMENT_FLAG = {
    GREEN: "GREEN",
    PRIVATE_SELLER: "C2C",
    PRIVATE_SELLER_PRO: "GAMMA"
};
export const INVENTORY_TYPE_LABEL = {
    C2C: "C2C",
    B2C: "B2C"
};

export const DEFAULT_PAGE_SIZE = NUMBER.TWENTY;

export const SIMILAR_CAR_SOURCES = {
    LISTING: "LISTING",
    WISHLIST: "WISHLIST",
    CAR_DETAIL: "CAR_DETAIL",
    SIMILAR_CARS: "SIMILAR_CARS",
    TDS: "TDS",
    LEAVING_MODAL: "LEAVING_MODAL"
};

export const LOCATION_SOURCE = {
    LISTING: "listingHeader"
};

export const C2C_SELLER_CONFIRMATION_STATES = {
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED",
    WAITING: "WAITING"
};

export const PROMISE_STATE = {
    FULFILLED: "fulfilled",
    REJECTED: "rejected"
};

export const dynamicConfigs = {
    SALE_BANNER: "sale_banner"
};
