
/* eslint-disable max-len */
import { combineReducers } from "redux";
import { resettableReducer } from "../utils/redux/resettable-reducer";
import userReducer from "../components/ae.configuration/reducers";
import carListReducer from "../components/ae.desktop/car-listing/reducers";
import { resetReducerKeys } from "../constants/app-constants";
import carDetailsReducer from "../components/ae.desktop/car-details/reducers";
import filtersReducer from "../components/ae.desktop/filters/reducers";
import configReducer from "../components/ae.desktop/config-ssr/reducers";
import locationReducer from "../components/ae.desktop/header-revamp/reducers";
import footerReducer from "../components/ae.desktop/footer-v2/reducers";
import checkoutReducer from "../components/ae.desktop/checkout-revamp-v2/reducers";
import tdScheduleReducer from "../components/ae.desktop/td-schedule/reducers";
import PostBcReducer from "../components/ae.desktop/post-booking-confirmation/reducers";
import recentlyViewedCarsReducer from "../components/ae.desktop/recently-viewed-car-list/reducers";
import experimentsReducer from "../components/shared/ab-experiment/reducers";
import faqReducer from "../components/ae.desktop/faq/reducers";
import toastMessageReducer from "../components/shared/toast-message/reducers";
import myBookings from "../components/ae.desktop/my-bookings/reducers";
import myWishlist from "../components/ae.desktop/my-wishlist/reducers";
import carLoan from "../components/ae.desktop/car-loan-home/reducers";
import seoCarDetails from "../components/ae.desktop/seo-car-details/reducers";
import chatIconReducer from "../components/ae.desktop/chat-icon/reducers";

// Seller Reducers
import brandsReducer from "../components/ae.desktop/seller-lead-brands/reducers";
import modelsReducer from "../components/ae.desktop/seller-lead-models/reducers";
import yearsReducer from "../components/ae.desktop/seller-lead-years/reducers";
import carEvaluationReducer from "../components/ae.desktop/seller-car-evaluation/reducers";
import carStoresReducer from "../components/ae.desktop/sell-car-appointment-store/reducers";

// listen for the action type of 'RESET', you can change this.
const resettableUserReducer = resettableReducer(resetReducerKeys.RESET_USER);
const resettableCarDetailsReducer = resettableReducer(resetReducerKeys.RESET_CAR_DETAILS);
const resettableConfig = resettableReducer(resetReducerKeys.RESET_CONFIG);
const resettableCarList = resettableReducer(resetReducerKeys.RESET_CAR_LIST);
const resettableFilters = resettableReducer(resetReducerKeys.RESET_FILTERS);
const resettableLocationPicker = resettableReducer(resetReducerKeys.RESET_LOCATION_PICKER_MODAL);
const resettableFooter = resettableReducer(resetReducerKeys.RESET_FOOTER);
const resettableCheckoutReducer = resettableReducer(resetReducerKeys.RESET_CHECKOUT);
const resettablePostBcReducer = resettableReducer(resetReducerKeys.RESET_POSTBC_DETAILS);
const resettableTdScheduleReducer = resettableReducer(resetReducerKeys.RESET_TD_SCHEDULE);
const resettableRecentlyViewedCarsReducer = resettableReducer(resetReducerKeys.RESET_RECENTLY_VIEWED_CARS);
const resettableFaqReducer = resettableReducer(resetReducerKeys.RESET_FAQS);
const resettableToastMessageReducer = resettableReducer(resetReducerKeys.RESET_TOAST_MESSAGE);
// const resettableFeaturedCarsReducer = resettableReducer(resetReducerKeys.FEATURED_CARS);
const resettableMyBookings = resettableReducer(resetReducerKeys.RESET_MY_BOOKINGS);
const resettableMyWishlist = resettableReducer(resetReducerKeys.RESET_WISHLISTED_CARS);
const resettableCarLoan = resettableReducer(resetReducerKeys.RESET_CAR_LOAN);
const resettableExperiments = resettableReducer(resetReducerKeys.RESET_AB_EXPERIMENTS);
const resettableChatIconReducer = resettableReducer(resetReducerKeys.RESET_CHAT_ICON);
// seller
const resettableBrands = resettableReducer(resetReducerKeys.RESET_BRANDS);
const resettableModels = resettableReducer(resetReducerKeys.RESET_MODELS);
const resettableYears = resettableReducer(resetReducerKeys.RESET_YEARS);
const resettableCarEvaluation = resettableReducer(resetReducerKeys.RESET_CAR_EVALUATION);
const resettableCarStores = resettableReducer(resetReducerKeys.RESET_CAR_STORES);
const resettableSeoCarDetails = resettableReducer(resetReducerKeys.RESET_SEO_CAR_DETAILS);

export default combineReducers({
    user: resettableUserReducer(userReducer),
    config: resettableConfig(configReducer),
    location: resettableLocationPicker(locationReducer),
    carDetails: resettableCarDetailsReducer(carDetailsReducer),
    carListing: resettableCarList(carListReducer),
    filters: resettableFilters(filtersReducer),
    footer: resettableFooter(footerReducer),
    checkout: resettableCheckoutReducer(checkoutReducer),
    postBcDetails: resettablePostBcReducer(PostBcReducer),
    tdSchedule: resettableTdScheduleReducer(tdScheduleReducer),
    recentlyViewed: resettableRecentlyViewedCarsReducer(recentlyViewedCarsReducer),
    faq: resettableFaqReducer(faqReducer),
    toast: resettableToastMessageReducer(toastMessageReducer),
    experiments: resettableExperiments(experimentsReducer),
    // featuredCars: resettableFeaturedCarsReducer(featuredCarsReducer),
    myBookings: resettableMyBookings(myBookings),
    myWishlist: resettableMyWishlist(myWishlist),
    carLoan: resettableCarLoan(carLoan),
    chatIcon: resettableChatIconReducer(chatIconReducer),
    // Seller
    carEvaluation: resettableCarEvaluation(carEvaluationReducer),
    brands: resettableBrands(brandsReducer),
    models: resettableModels(modelsReducer),
    years: resettableYears(yearsReducer),
    carStores: resettableCarStores(carStoresReducer),
    seoCarDetails: resettableSeoCarDetails(seoCarDetails)
});
