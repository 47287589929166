import React, { useState } from "react";
import PropTypes from "prop-types";

//Styles
import styles from "./styles.css";

//Images
import ImageSale from "./images/sale.png";
import IconArrowRight from "./images/right-arrow.svg";

//Components
import Button from "../../shared/button";
import Login from "../login-revamp";

//Utils
import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";

const SaleStripListing = ({ activeSaleConfig }) => {
    const {
        active: isSaleActive = false,
        saleDetails = {}
    } = activeSaleConfig || {};

    const { listingPageBannerInfo = {} } = saleDetails || {};
    const { listingPageTopNudgeInfo = {}} = listingPageBannerInfo || {};

    const [showLogin, setShowLogin] = useState(false);

    const { title = "" } = parseSeasonalSaleData(listingPageTopNudgeInfo);
    const {imageUrl = ""} = listingPageTopNudgeInfo || {};

    const handleButtonClick = () => {
        setShowLogin(true);
    };
    const onLoginSuccess = () => {
        setShowLogin(false);
    };
    const closeLoginModal = () => {
        setShowLogin(false);
    };

    if (!isSaleActive || !title) {
        return null;
    }

    return (
        <Login isVisible={showLogin} onSuccess={onLoginSuccess} onClose={closeLoginModal}>
            <div styleName={"styles.wrapper"}>
                <img src={imageUrl || ImageSale} alt="" width={40} height={27} />
                <p>{title}</p>
                <div styleName={"styles.ctaWrap"}>
                    <Button text="LOGIN TO VIEW DISCOUNTS" onClick={handleButtonClick} />
                    <img src={IconArrowRight} alt="" width={16} height={16} />
                </div>
            </div>
        </Login>
    );
};
SaleStripListing.propTypes = {
    activeSaleConfig: PropTypes.object,
    isLoggedIn: PropTypes.bool

};
export default SaleStripListing;
