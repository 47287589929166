/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router-dom";

// Styles
import styles from "./styles.css";

// Components
import Login from "../login";
import withLogin from "../with-login";
import withWishlist from "../with-wishlist";
import CarDetailsCtaV2 from "../car-details-cta-v2";
import NotifyMeModal, { NotifiedDefaultModal } from "../notify-me-modal/component";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params.js";

// Utilities
import deleteCookie from "../../../utils/helpers/delete-cookie";
import { getPostBcCustomUrl } from "../../../utils/helpers/get-postbc-url";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";

// Constants
import { C2C_OFFER_STATUS, ORDER_STATUS } from "../../../constants/ae/checkout-constants";
import { BOOKING_CONFIRMATION_SOURCE, PRIMARY_CTA_TEXT, GA_EVENT_SOURCE, LISTING_TYPE, COOKIE_CONSTANTS, NUMBER, INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";

// Tracking
import { trackMobileCustomEventsAE } from "../../../tracking";
import carListingEventDataParser from "../../../tracking/parser/car-listing";
import cleverTap from "../../../tracking/clevertap";
import { carListingEventDataParserGA4 } from "../../../tracking/parser/car-listing-ga4";

// Utils
import { capitalizeFirstLetter } from "../../../utils/helpers/title-to-upperCase";
import { addToSpButNoBiIds } from "../../../utils/helpers/custom-recommened-car";
import parseCookie from "../../../utils/helpers/parse-cookie";

// Images
import ErrorInfoIcon from "./images/error-info.svg";
import { SOURCES } from "../login/constants";
import getSimilarCarsUrl from "../../../utils/helpers/get-similar-cars-url";

const DetailsCtaSectionV4 = ({
    match: { params: { lid: carId } },
    history,
    showLogin,
    isLoggedIn,
    secureToken,
    setShowLogin,
    isWishlistedCar,
    order,
    slideAnimation = true,
    carDetails,
    source = null,
    isSticky,
    selectedCity,
    updateSpincarModalStatusConnect,
    fetchOrderPostBcDetailsV2Connect,
    isExculsiveBenefitClick = false,
    bookingAllowed,
    onClickCallback = () => { },
    handleContinueBooking = () => { },
    activeBookingCount,
    isGalleryIcon = false,
    resetPostBcConnect,
    mobile,
    showPriceSection = false,
    hidePopularSection = false,
    isTdModal = false
}) => {
    const { content: carDetailsContent = {}, galleryProps = {} } = carDetails || {};
    const { appointmentId, booked, listingActive, model, make, year, city, inventoryType, sellerId } = carDetailsContent || {};
    const { status: orderStatus, orderId, latestOffer = {} } = order || {};
    const { offerStatus = "" } = latestOffer || {};

    const isReservedCar = booked && orderStatus !== ORDER_STATUS.BOOKED;
    const carName = `${make} ${model}`;
    const { relativeURL: carCheckoutUrl } = getDetailPageUrl({ year, carName, carId: carId || appointmentId, city, isCheckout: true, country: "ae" });
    const apptIdsSpButNoBi = JSON.parse(parseCookie(COOKIE_CONSTANTS.APPT_IDS_SP_BUT_NO_BI) || "[]");

    const [isNotified, setIsNotified] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false);
    const [isNotifiedIconClicked, setIsNotifiedIconClicked] = useState(false);
    const [showNotifyDefaultModal, setShowDefaultNotifyModal] = useState(false);
    const [showIndicator, setShowIndicator] = useState(false);

    const routeLocation = useLocation();
    const queryParams = parseLocationSearchParams(routeLocation.search);

    const isSeller = queryParams?.isSeller;

    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;

    if (isC2C && isSeller) {
        showPriceSection = false;
    }

    const getPrimaryCtaState = () => {

        if (isSeller && isC2C) {
            return {
                text: PRIMARY_CTA_TEXT.BACK_TO_SELLER_CENTER,
                isDisabled: false,
                notify: false
            };
        }

        let text = isC2C ? PRIMARY_CTA_TEXT.MAKE_AN_OFFER : PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE;

        if (showPriceSection && text === PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE) {
            text = PRIMARY_CTA_TEXT.FREE_TEST_DRIVE;
        }

        let isDisabled = false;
        let notify = false;
        const isCarWishListed = isWishlistedCar(appointmentId);
        if (isLoggedIn && secureToken && listingActive) {
            if (
                (orderStatus === ORDER_STATUS.BOOKED || orderStatus === ORDER_STATUS.SOLD)
                || ((orderStatus === ORDER_STATUS.CREATED) && (offerStatus === C2C_OFFER_STATUS.ACCEPTED))
            ) {
                text = PRIMARY_CTA_TEXT.TRACK_BOOKING;
                isDisabled = false;
            } else if (isReservedCar && !isCarWishListed) {
                text = PRIMARY_CTA_TEXT.NOTIFY_ME;
                notify = true;
            } else if (isReservedCar && isCarWishListed) {
                text = PRIMARY_CTA_TEXT.REMOVE_FROM_WISHLIST;
                notify = true;
            } else {
                isDisabled = !bookingAllowed;
            }
        } else if (isReservedCar && !isCarWishListed && listingActive) {
            text = PRIMARY_CTA_TEXT.NOTIFY_ME;
            notify = true;
        } else if (isReservedCar && isCarWishListed && listingActive) {
            text = PRIMARY_CTA_TEXT.REMOVE_FROM_WISHLIST;
            notify = true;
        } else if (booked && listingActive) {
            text = PRIMARY_CTA_TEXT.RESERVED;
            isDisabled = true;
        }

        if (!listingActive && booked && !orderId) {
            text = PRIMARY_CTA_TEXT.NOT_AVAILABLE;
            isDisabled = true;
        } else if ((!listingActive && booked && orderId) || (!listingActive && !booked && orderId)) {
            text = PRIMARY_CTA_TEXT.TRACK_BOOKING;
            isDisabled = false;
        } else if (!listingActive) {
            text = PRIMARY_CTA_TEXT.SIMILAR_CARS;
            isDisabled = false;
        } else if (isC2C && sellerId === mobile) {
            isDisabled = true;
        }

        return {
            text,
            isDisabled,
            notify
        };
    };

    const primaryCtaState = getPrimaryCtaState();

    const onLoginClose = () => {
        setShowLogin(false);
    };

    const navigateToPostBcJourney = () => {
        const { relativeURL: postBcUrlRelativeUrl } = getPostBcCustomUrl({ lid: appointmentId }, { source: "detail" });
        history.push(postBcUrlRelativeUrl);
    };

    const navigateToBookingConfirmation = () => {
        history.push(`${carCheckoutUrl}?source=${source ? source : BOOKING_CONFIRMATION_SOURCE.DETAILS}`);
    };

    const navigateToSellerCenterPage = () => {
        history.push(`/seller-center?lid=${appointmentId}`);
    };

    const checkIfPostBcDataExists = (oId) => {
        fetchOrderPostBcDetailsV2Connect(oId, secureToken).then((res) => {
            if (res?.orderId) {
                resetPostBcConnect();
                navigateToPostBcJourney();
            } else {
                navigateToBookingConfirmation();
            }
        }).catch(() => {
            navigateToBookingConfirmation();
        });
    };

    const initiateCheckoutFlow = () => {
        if (orderStatus === ORDER_STATUS.BOOKED || orderStatus === ORDER_STATUS.DELIVERED) {
            checkIfPostBcDataExists(appointmentId);
        } else {
            navigateToBookingConfirmation();
        }
    };

    const callGaEvents = () => {
        if (isLoggedIn) {
            // clevertap event
            cleverTap.mobileCleverTapEvent(cleverTap.mobileConstants.START_PURCHASE, {
                carDetailsContent,
                cityName: selectedCity?.name || ""
            });
            // ecommerce event
            const data = carListingEventDataParser(carDetailsContent, {});
            trackMobileCustomEventsAE("startPurchase", data.startPurchase);
        } else {
            addToSpButNoBiIds(apptIdsSpButNoBi, appointmentId);
        }
    };

    const addReservedCarToWishlist = () => {
        if (isWishlistedCar(appointmentId, secureToken)) {
            setIsNotified(false);
        } else {
            setIsNotified(true);
        }
        setIsNotifiedIconClicked(false);
        setShowDefaultNotifyModal(false);
        setShowNotifyModal(true);
    };

    const onNotifyIconClick = () => {
        if (isLoggedIn) {
            setShowDefaultNotifyModal(true);
        } else {
            setIsNotifiedIconClicked(true);
            setShowLogin(true);
        }
    };

    const handleProceedFlow = () => {
        if (isLoggedIn) handleContinueBooking();
        if (isReservedCar && listingActive) {
            addReservedCarToWishlist();
        } else {
            initiateCheckoutFlow();
        }
    };

    const triggerGA4AddToCartEvent = () => {
        const dataGA4 = carListingEventDataParserGA4(carDetailsContent, {
            filterDimensions: {},
            listingType: LISTING_TYPE.CAR_DETAILS_PAGE
        });
        trackMobileCustomEventsAE("clearEccomerce");
        trackMobileCustomEventsAE("ga4AddToCart", {
            user_id: mobile,
            booking_id: orderId || "NA",
            appointment_id: appointmentId,
            ...dataGA4
        });
    };

    const handleViewSimilarIconClick = () => {
        const similarCarUrl = getSimilarCarsUrl({ year, appointmentId: carId, make, model });
        history.push(similarCarUrl);
    };

    const onPrimaryCtaClick = (ctaText) => {

        if (isC2C && isSeller) {
            navigateToSellerCenterPage();
            return;
        }
        onClickCallback(isLoggedIn);
        // GA event: start purchase clicked - only if car not reserved
        // GA event: reserved notify me clicked - if car is reserved
        if (isReservedCar && !isWishlistedCar(appointmentId, secureToken) && !isLoggedIn && listingActive) {
            trackMobileCustomEventsAE("b2cNotifymeClick", {
                eventLabel: appointmentId
            });
        } else if (isExculsiveBenefitClick) {
            trackMobileCustomEventsAE("startPurchaseDetailPage", {
                bookingId: orderId || "NA",
                appointment_id: carId,
                eventLabel: GA_EVENT_SOURCE.EXCLUSIVE_BENEFIT_POPUP
            });
        } else if (isGalleryIcon) {
            trackMobileCustomEventsAE("startPurchaseDetailPage", {
                appointment_id: carId,
                eventLabel: GA_EVENT_SOURCE.GALLERY,
                bookingId: orderId || "NA"
            });
            if (isLoggedIn) {
                trackMobileCustomEventsAE("newGalleryBookingInititated");
            }
        } else if (!isWishlistedCar(appointmentId, secureToken) && listingActive) {
            trackMobileCustomEventsAE("startPurchaseDetailPage", {
                appointment_id: carId,
                eventLabel: GA_EVENT_SOURCE.DETAIL_PAGE,
                bookingId: orderId || "NA"
            });
            callGaEvents();
        }

        if (ctaText === PRIMARY_CTA_TEXT.TRACK_BOOKING) {
            navigateToPostBcJourney();
            return;
        }

        if (!listingActive) {
            handleViewSimilarIconClick();
            return;
        }

        updateSpincarModalStatusConnect(false);
        if (isLoggedIn) {
            handleProceedFlow();
        } else {
            setShowLogin(true);
            deleteCookie("galleryView");
            triggerGA4AddToCartEvent();
        }

        if (isTdModal) {
            trackMobileCustomEventsAE("startPurchaseTDModal");
        }
    };

    const onLoginSuccessCallback = () => {
        setShowLogin(false);
        handleContinueBooking();
        if (isGalleryIcon) {
            trackMobileCustomEventsAE("newGalleryBookingInititated");
        }
        if (isNotifiedIconClicked) {
            trackMobileCustomEventsAE("b2cNotifymeClick", {
                eventLabel: appointmentId
            });
            setShowDefaultNotifyModal(true);
        } else if (booked && orderStatus !== ORDER_STATUS.BOOKED) {
            trackMobileCustomEventsAE("b2cNotifymeClick", {
                eventLabel: appointmentId
            });
            addReservedCarToWishlist();
        } else {
            callGaEvents();
            handleProceedFlow();
        }
    };

    const handleWishlistClick = () => {
        updateSpincarModalStatusConnect(false);
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Set showIndicator to true when scrolling starts
        if (scrollPosition > 0 && !showIndicator) {
            setShowIndicator(true);
        }
        if (window.scrollY < NUMBER.FIVE) {
            setShowIndicator(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ctaProps = {
        onPrimaryCtaClick: () => onPrimaryCtaClick(primaryCtaState.text),
        handleWishlistClick,
        onNotifyIconClick,
        text: primaryCtaState.text,
        isPrimaryCtaDisabled: primaryCtaState.isDisabled,
        disabled: primaryCtaState.isDisabled,
        notify: primaryCtaState.notify,
        showPopularSection: galleryProps.isOpen || hidePopularSection || !showIndicator,
        showPriceSection
    };
    const bookTestDriveLoginText = `Please login to book your ${capitalizeFirstLetter(make)} ${capitalizeFirstLetter(model)} test drive`;
    return (
        <React.Fragment>
            <Login
                isVisible={showLogin}
                onClose={onLoginClose}
                onSuccess={onLoginSuccessCallback}
                loginSource={bookTestDriveLoginText}
                loginFor={SOURCES.START_PURCHASE}
            >
                <div styleName={`styles.buttonWrap ${!slideAnimation ? "styles.slidingAnimation" : ""} styles.fiveBookingDone ${isSticky ? "styles.sticky" : ""}`
                }>
                    {ctaProps?.disabled && !bookingAllowed && (
                        <p styleName={"styles.bookingDoneStrip"}>
                            <img src={ErrorInfoIcon} alt="" />
                            Maximum {activeBookingCount} bookings in parallel allowed
                        </p>
                    )}
                    <CarDetailsCtaV2
                        {...ctaProps}
                        appointmentId={appointmentId}
                        carDetails={carDetails}
                    />
                </div>
            </Login>
            {showNotifyModal && <NotifyMeModal isNotified={isNotified} carId={appointmentId} onClose={() => setShowNotifyModal(false)} />}
            {showNotifyDefaultModal && !isNotified && <NotifiedDefaultModal onClick={addReservedCarToWishlist} onClose={() => setShowDefaultNotifyModal(false)} />}
        </React.Fragment>
    );
};

DetailsCtaSectionV4.propTypes = {
    carDetails: PropTypes.object,
    isSticky: PropTypes.bool,
    slideAnimation: PropTypes.bool,
    order: PropTypes.object,
    isWishlistedCar: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    secureToken: PropTypes.string,
    match: PropTypes.object,
    selectedCity: PropTypes.object,
    showLogin: PropTypes.bool,
    source: PropTypes.string,
    setShowLogin: PropTypes.func,
    handleContinueBooking: PropTypes.func,
    history: PropTypes.object,
    updateSpincarModalStatusConnect: PropTypes.func,
    fetchOrderPostBcDetailsV2Connect: PropTypes.func,
    isExculsiveBenefitClick: PropTypes.bool,
    bookingAllowed: PropTypes.bool,
    activeBookingCount: PropTypes.number,
    isGalleryIcon: PropTypes.bool,
    resetPostBcConnect: PropTypes.func,
    onClickCallback: PropTypes.func,
    mobile: PropTypes.string,
    showPriceSection: PropTypes.bool,
    hidePopularSection: PropTypes.bool,
    isTdModal: PropTypes.bool
};

export default withRouter(withWishlist(withLogin(DetailsCtaSectionV4)));
