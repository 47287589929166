/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { imageMappingMap } from "../../../utils/image-mapping/image-mapping";
import { LANGUAGES } from "../../../constants/app-constants";
const BodyTypeRevamp = ({ appliedFilters, filter, onOptionSelection }) => {
    const {
        options
    } = filter;
    const BODY_TYPE = imageMappingMap[LANGUAGES.en.code].imageMapping.bodyType;
    const largerBodyTypeDisplayTexts = ["double cab utility"];

    const selectOption = (filterKey, optionKey, isSelected, displayText) => {
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };

    return (
        <div styleName="styles.bodyTypeWrap">
            {options.map((option) => {
                const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: option.key }, appliedFilters);
                return (
                    <div onClick={selectOption.bind(null, filter.key, option.key, !isSelected, option.displayText)} key={option.key} styleName={`styles.bodyNameCardWrapperRevamp ${isSelected ? `styles.active` : ``}`}>
                        <span styleName={"styles.bodyImage"}><img src={BODY_TYPE[option.key.toLowerCase()] || BODY_TYPE?.sedan || BODY_TYPE.default} /></span>
                        <span styleName={`styles.bodyName ${largerBodyTypeDisplayTexts.includes(option.key.toLowerCase()) ? "styles.font-size-8" : ""} `}>{option.displayText}</span>
                    </div>
                );
            })}
        </div>
    );
};
BodyTypeRevamp.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array
};
export default BodyTypeRevamp;
