/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import ICON_CAR_SPECS from "../../shared/icon-car-specs";
import BasicDetailsContent from "../../shared/basic-details-content";

// Constants
import { NUMBER } from "../../../constants/app-constants";
import { SECTION_NAME, FEATURE_ICON } from "./constant";

import IconInfo from "../../shared/images/icons/info-icon-outline.svg";

const InfoPopup = ({renderIcon, description, title}) => {
    return (
        <div className="infoWrapper">
            <div styleName={"styles.infoImage"}>
                {renderIcon}
                <span styleName={"styles.infoPopup"}>
                    <p styleName={"styles.title"}>{title}</p>
                    <p>{description}</p>
                </span>

            </div>
        </div>
    );
};
const CarDetailOverview = ({
    carHighlights,
    carUsps = [],
    warrantyDuration
}) => {

    return (
        <React.Fragment>
            {(carHighlights || []).length > NUMBER.ZERO && (
                        <div styleName={"styles.overviewSection"}>
                            <h2>Overview</h2>
                            <div styleName={"styles.gridRow"}>
                                {(carHighlights || [])?.slice(NUMBER.ZERO, NUMBER.THREE).map((item) => (
                                    <div key={item?.key} styleName="styles.overviewItems">
                                        <div styleName={"styles.iconWrap"}>
                                            <img src={ICON_CAR_SPECS[item.iconName] || ICON_CAR_SPECS.default} alt={item.name} width="36" height="36" />
                                        </div>
                                        <div styleName={"styles.content"}>
                                            <h4>{item.name}</h4>
                                            <div styleName={"styles.flexRow"}>
                                                <p>{item.description}</p>
                                                {item.key === "manufactureWarranty" && <InfoPopup title={"Manufacturer warranty"} description={item?.longDescription} renderIcon={<img src={IconInfo} alt="" width="16" height="16" />} />}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                <BasicDetailsContent isDesktop={true}/>
                <div styleName={"styles.featureScroll"}>
                    {(carUsps || []).map((item, index) => (
                        <div
                            key={index}
                            styleName={"styles.item"}
                            // onClick={() => openPrimeModal(item.sectionName, item.ga_name)}
                        >
                            <img src={FEATURE_ICON[item.sectionName]} alt="" width="24" height="24" />
                            <h3>{item.title}</h3>
                            <p>{item.sectionName === SECTION_NAME.FREE_WARRANTY ? `upto ${warrantyDuration || NUMBER.THREE} months` : item.desc}</p>
                        </div>
                    ))}
                </div>

        </React.Fragment>
    );
};

CarDetailOverview.propTypes = {
    carHighlights: PropTypes.object,
    warrantyDuration: PropTypes.number,
    carUsps: PropTypes.array
};

InfoPopup.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    renderIcon: PropTypes.object
};

export default CarDetailOverview;
