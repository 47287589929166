export const FILTER_ENTITIES = {
    BODY_TYPE: "bodyType",
    PRICE: "quotedPrice",
    PRICE_WITHOUT_VAT: "priceWithoutVat",
    FUEL_TYPE: "fuelType",
    OWNERS: "ownerNumber",
    ODOMETER_READING: "odometerReading",
    TRANSMISSION: "transmissionType",
    REGISTRATION_CITY: "registrationCity",
    YEAR: "year",
    MAKE: "make",
    MODEL: "modelCluster",
    MAKE_MODEL: "model",
    COLOR: "carColor",
    OPTIONS_TYPE: "optionsType",
    CAR_LOCATION: "cars24Hub",
    DRIVE_TYPE: "driveType",
    DOORS_SEATS: "doorsAndSeats",
    NO_OF_SEATS: "numberOfSeats",
    NO_OF_DOORS: "numberOfDoors",
    ENGINE: "engine",
    ENGINE_SIZE: "engineSize",
    NO_OF_CYLINDERS: "noOfCylinders",
    DISCOUNT: "discount",
    LIGHTENING_DEAL: "lighteningDeal",
    FINANCE: "finance",
    DOWNPAYMENT: "downPayment",
    EMI: "emi",
    BOOKED: "booked",
    FEATURES: "features",
    LOW_IMPERFECTION: "lowImperfection",
    CATEGORY: "category",
    CAR_TYPE: "carType",
    CARS24_HUB: "cars24Hub",
    SAVED_FILTERS: "savedFilters"
};

export const MORE_FILTER_ENTITIES = {
    FUEL_TYPE: "fuelType",
    TRANSMISSION: "transmissionType",
    COLOR: "carColor",
    OPTIONS_TYPE: "optionsType"
};

export const PRICE_FILTER_TYPE = {
    FULL_AMOUNT: "FULL_AMOUNT",
    EMI: "EMI"
};

export const DOWN_ARROW_ROTATE_DEGREE = 90;
export const UP_ARROW_ROTATE_DEGREE = -90;

export const FILTER_TAB_TYPES = {
    FILTER: "FILTER",
    SORT: "SORT"
};

export const FILTER_PRIORITY = [
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CAR_NAME,
    FILTER_ENTITIES.BUDGET,
    FILTER_ENTITIES.OWNERS,
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE
];

export const MOBILE_ON_SCREENT_FILTERS = [
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.MAKE
];
export const MOBILE_OFF_SCREEN_FILTERS = [
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.COLOR
];

export const ON_SCREENT_FILTERS = ["quotedPrice", "make", "year", "bodyType", "odometerReading"];
export const OFF_SCREEN_FILTERS = ["transmissionType", "bodyType"];
export const VARIANT_FILTERS = ["discount", "finance", "engine", "doorAndSeats", "optionType"];

export const FILTER_TYPES = {
    RF: "rf",
    SF: "sf",
    GF: "gf"
};

export const SORT_ORDER = [];

export const APPLIED_FILTER_META = {
    [FILTER_ENTITIES.PRICE]: {
        prefix: "AED"
    },
    [FILTER_ENTITIES.FINANCE]: {
        prefix: "AED"
    },
    [FILTER_ENTITIES.ODOMETER_READING]: {
        suffix: "KMs"
    },
    [FILTER_ENTITIES.PRICE_WITHOUT_VAT]: {
        prefix: "AED"
    }
};

export const ALL_FILTERS_REVAMP = [
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.DISCOUNT,
    FILTER_ENTITIES.ENGINE,
    FILTER_ENTITIES.DOORS_SEATS,
    FILTER_ENTITIES.OPTIONS_TYPE,
    FILTER_ENTITIES.BOOKED
];

export const GROUPED_FILTERS = {
    FINANCE: "finance"
};

// export const PRIMARY_FILTER_PRIORITY = [
//     FILTER_ENTITIES.BUDGET,
//     FILTER_ENTITIES.CAR_NAME,
//     FILTER_ENTITIES.ASSURED,
//     FILTER_ENTITIES.PARKING_YARD,
//     FILTER_ENTITIES.FINANCE,
//     FILTER_ENTITIES.ODOMETER_READING,
//     FILTER_ENTITIES.YEAR,
//     FILTER_ENTITIES.FUEL_TYPE,
//     FILTER_ENTITIES.TRANSMISSION,
//     FILTER_ENTITIES.BODY_TYPE,
//     FILTER_ENTITIES.OWNERS,
//     FILTER_ENTITIES.REGISTRATION_CITY
// ];

// export const RESETTABLE_FILTER_ENTITIES = [
//     FILTER_ENTITIES.PARKING_YARD,
//     FILTER_ENTITIES.REGISTRATION_CITY
// ];

export const ALL_FILTERS_MOBILE = [
    FILTER_ENTITIES.SAVED_FILTERS,
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.FINANCE,
    FILTER_ENTITIES.DOWNPAYMENT,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.CAR_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CATEGORY,
    FILTER_ENTITIES.FEATURES,
    FILTER_ENTITIES.LOW_IMPERFECTION,
    FILTER_ENTITIES.DISCOUNT,
    FILTER_ENTITIES.OPTIONS_TYPE,
    FILTER_ENTITIES.CARS24_HUB,
    FILTER_ENTITIES.DRIVE_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.ENGINE,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.BOOKED,
    FILTER_ENTITIES.DOORS_SEATS
];

export const ALL_FILTERS_DESKTOP = [
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.FINANCE,
    FILTER_ENTITIES.DOWNPAYMENT,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.CAR_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CATEGORY,
    FILTER_ENTITIES.FEATURES,
    FILTER_ENTITIES.LOW_IMPERFECTION,
    FILTER_ENTITIES.DISCOUNT,
    FILTER_ENTITIES.OPTIONS_TYPE,
    FILTER_ENTITIES.CARS24_HUB,
    FILTER_ENTITIES.DRIVE_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.ENGINE,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.BOOKED,
    FILTER_ENTITIES.DOORS_SEATS
];

export const ALL_FILTERS_DESKTOP_FOR_SALE = [
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.DISCOUNT,
    FILTER_ENTITIES.FINANCE,
    FILTER_ENTITIES.DOWNPAYMENT,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.CAR_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CATEGORY,
    FILTER_ENTITIES.FEATURES,
    FILTER_ENTITIES.LOW_IMPERFECTION,
    FILTER_ENTITIES.OPTIONS_TYPE,
    FILTER_ENTITIES.CARS24_HUB,
    FILTER_ENTITIES.DRIVE_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.ENGINE,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.BOOKED,
    FILTER_ENTITIES.DOORS_SEATS
];

export const ALL_FILTERS_MOBILE_FOR_SALE = [
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.DISCOUNT,
    FILTER_ENTITIES.FINANCE,
    FILTER_ENTITIES.DOWNPAYMENT,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.CAR_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CATEGORY,
    FILTER_ENTITIES.FEATURES,
    FILTER_ENTITIES.LOW_IMPERFECTION,
    FILTER_ENTITIES.OPTIONS_TYPE,
    FILTER_ENTITIES.CARS24_HUB,
    FILTER_ENTITIES.DRIVE_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.ENGINE,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.BOOKED,
    FILTER_ENTITIES.DOORS_SEATS
];

export const FILTER_DISPLAY_TEXT = {
    discount: "On Discount",
    lighteningDeal: "Lightning deals",
    lowImperfection: "Low Imperfections"
};

export const DEFAULT_MAKE_MODELS = [
    {
        key: "NISSAN PATROL",
        make: "Nissan",
        model: "Patrol"
    },
    {
        key: "JEEP WRANGLER",
        make: "Jeep",
        model: "Wrangler"
    },
    {
        key: "NISSAN KICKS",
        make: "Nissan",
        model: "Kicks"
    },
    {
        key: "TOYOTA RUSH",
        make: "Toyota",
        model: "Rush"
    },
    {
        key: "HONDA	CIVIC",
        make: "Honda",
        model: "Civic"
    },
    {
        key: "DODGE	CHARGER",
        make: "Dodge",
        model: "Charger"
    },
    {
        key: "NISSAN SUNNY",
        make: "Nissan",
        model: "Sunny"
    },
    {
        key: "TOYOTA YARIS",
        make: "Toyota",
        model: "Yaris"
    },
    {
        key: "TOYOTA COROLLA",
        make: "Toyota",
        model: "Corolla"
    },
    {
        key: "NISSAN X TRAIL",
        make: "Nissan",
        model: "X-Trail"
    },
    {
        key: "KIA SPORTAGE",
        make: "KIA",
        model: "Sportage"
    },
    {
        key: "HYUNDAI TUCSON",
        make: "Hyundai",
        model: "Tucson"
    },
    {
        key: "TOYOTA RAV4",
        make: "Toyota",
        model: "Rav4"
    },
    {
        key: "HYUNDAI CRETA",
        make: "Hyundai",
        model: "Creta"
    },
    {
        key: "NISSAN ALTIMA",
        make: "Nissan",
        model: "Altima"
    }
];

export const POPULAR_CATEGORY_OPTIONS = [
    "luxuryInBudget",
    "asGoodAsNew",
    "latestSUVs",
    "budgetFriendly",
    "premiumSedan",
    "onSale"
];

export const POPULAR_CATEGORY_OPTIONS_V2 = [
    "luxuryInBudget",
    "asGoodAsNew",
    "latestSUVs",
    "budgetFriendly",
    "premiumSedan",
    "onSale",
    "saleLive",
    "aiCarFinder"
];
