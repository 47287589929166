/* eslint-disable max-params */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { FILTER_ENTITIES, FILTER_TYPES, FILTER_DISPLAY_TEXT, APPLIED_FILTER_META } from "../filters-v2/constants";
import { CURRENCY } from "../../constants/app-constants";
import { makePriceLabelRoundAE } from "./make-price-label";
import priceFormatter from "./price-formatter";

export const getAppliedFilterTagsName = (filter = { }, allFilters = { }, showUnit = true, currency = CURRENCY.IN, isDesktopView = false) => {

    const { filterKey, displayText, optionKey, isRangeFilter, min, max, filterType } = filter || { };

    const categoryFilter = allFilters?.[FILTER_ENTITIES.CATEGORY] || { };

    const suffix = showUnit && APPLIED_FILTER_META[filterKey] && APPLIED_FILTER_META[filterKey].suffix;
    const prefix = showUnit && APPLIED_FILTER_META[filterKey] && APPLIED_FILTER_META[filterKey].prefix;

    let displayMin = min;
    let displayMax = max;

    if (prefix === "AED" || suffix === "KMs") {
        displayMin = priceFormatter(min, true, currency);
        displayMax = priceFormatter(max, true, currency);
    }

    let displayTag = isRangeFilter ? `${prefix ? `${prefix} ` : ``}${displayMin}${suffix ? ` ${suffix}` : ``} - ${prefix ? `${prefix} ` : ``}${displayMax}${suffix ? ` ${suffix}` : ``}` : displayText;

    if (filterKey === FILTER_ENTITIES.DISCOUNT || filterKey === FILTER_ENTITIES.LIGHTENING_DEAL || filterKey === FILTER_ENTITIES.LOW_IMPERFECTION) {
        displayTag = FILTER_DISPLAY_TEXT[optionKey];
    }

    if (filterType === FILTER_TYPES.GF && filterKey === FILTER_ENTITIES.CATEGORY) {
        displayTag = categoryFilter?.groupFacet?.[optionKey]?.displayText || FILTER_ENTITIES.CATEGORY;
    }

    if (filterType === FILTER_TYPES.GF && isRangeFilter && filterKey === FILTER_ENTITIES.FINANCE) {
        displayTag = `${displayText} ${optionKey === "downPayment" ? `` : `<`} ${makePriceLabelRoundAE(max)}`;
    }

    if (filterType === FILTER_TYPES.GF && isRangeFilter && filterKey === FILTER_ENTITIES.ENGINE) {
        if (optionKey === FILTER_ENTITIES.NO_OF_CYLINDERS) {
            displayTag = `${min}-${max} Cylinders`;
        } else {
            displayTag = `Engine : ${min}-${max} L`;
        }
    }

    if (filterType === FILTER_TYPES.GF && !isRangeFilter && filterKey === FILTER_ENTITIES.DOORS_SEATS) {
        if (optionKey === FILTER_ENTITIES.NO_OF_DOORS) {
            displayTag = `${displayText} Doors`;
        } else {
            displayTag = `${displayText} Seats`;
        }
    }

    if (filterKey === FILTER_ENTITIES.OPTIONS_TYPE && isDesktopView) {
        displayTag = `${displayText} option`;
    }

    if (filterKey === FILTER_ENTITIES.BOOKED) {
        displayTag = "Non-Reserved";
    }

    return displayTag;
};

