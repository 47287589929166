import { B2CMasterData } from "../../../service";
import Types from "./types";
import { IDENTIFIERS, resetReducerKeys } from "../../../constants/app-constants";

const fetchFaqInit = () => ({
    type: Types.FETCH_FAQ
});

const fetchFaqSuccess = (content, isHomepage, isSeller) => ({
    type: Types.FETCH_FAQ_SUCCESS,
    content,
    isHomepage,
    isSeller
});

const fetchFaqFailure = (error) => ({
    type: Types.FETCH_FAQ_FAILURE,
    error
});

const fetchFaq = (params) => dispatch => {
    const { isHomepage, type = IDENTIFIERS.BUY, city } = params || {};
    const isSeller = type === IDENTIFIERS.SELL;
    dispatch(fetchFaqInit());
    return new Promise((resolve, reject) => {
        B2CMasterData.getFaq({ type, isHomepage, city })
            .then(response => {
                dispatch(fetchFaqSuccess(response.data, isHomepage, isSeller));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchFaqFailure(error));
                reject(error);
            });
    });
};

const resetSellerFaqs = () => ({
    type: resetReducerKeys.RESET_FAQS
});

export {
    fetchFaq,
    fetchFaqSuccess,
    resetSellerFaqs
};
