import React from "react";
import styles from "./styles.css";

const ReseverdCar = () => {
    return (
        <React.Fragment>
            <div styleName={"styles.reservecarWrap"} className="reservecarWrap">
                RESERVED
            </div>
        </React.Fragment>
    );
};

export default ReseverdCar;
