import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { setScheduleTestDriveShowMoreCTA } from "../post-booking-confirmation/actions";
import { resetAddress, setSelectedSlot } from "../td-schedule/actions";

// Component
import TdScheduleSlot from "./component";

const mapStateToProps = ({
    tdSchedule: {
        slotAvailabilityDetails
    },
    postBcDetails: {
        isScheduleTestDriveShowMoreCTASelected
    }
}) => ({
    slotAvailabilityDetails,
    isScheduleTestDriveShowMoreCTASelected
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setSelectedSlotConnect: setSelectedSlot,
    setScheduleTestDriveShowMoreCTAConnect: setScheduleTestDriveShowMoreCTA,
    resetAddressConnect: resetAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TdScheduleSlot);
