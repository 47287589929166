/* eslint-disable complexity */
import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";
import useToggle from "../../../hooks/use-toggle";

// Images
import IconSuccess from "../icon-success";
import IconError from "./images/error.svg";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

// Constants
const MAX_LENGTH = 30;

const InputText = ({
    value = "",
    id = "",
    text = "",
    type = "text",
    prefix = "",
    inputRef,
    maxLength = MAX_LENGTH,
    disabled = false,
    placeholder = null,
    isAutoFocused = false,
    showValidCheckmark = false,
    readOnly = false,
    isError,
    errorMessage = "",
    showErrorOnFocus = false,
    serverErrorMessage = errorMessage,
    disableOnChangeError = false,
    isShowErrorIcon = true,
    onChange = () => { },
    onBlurCallback = () => { },
    validator = (e) => { return e; },
    onFocusCallback = () => { },
    onKeyPress = () => { }
}) => {

    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useToggle(isAutoFocused);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(serverErrorMessage || errorMessage);
    const showError = ((isTouched && !isFocused && hasError) || (showErrorOnFocus && hasError) || (isTouched && hasError) || isError);

    useEffect(() => {
        if (isError) {
            setError(serverErrorMessage);
        } else {
            setError("");
        }
    }, [serverErrorMessage, isError]);

    const onChangeHandler = async (e) => {
        onChange(e);
        const inputValue = e.target.value;
        setIsTouched(true);
        if (!disableOnChangeError) {
            if (validator(inputValue)) {
                setHasError(false);
                setError("");
            } else {
                setHasError(true);
                setError(errorMessage);
            }
        }
    };

    const onFocus = async (e) => {
        onFocusCallback(e);
        const inputValue = e.target.value;
        await yieldToMainThread();
        setIsFocused();
        setIsTouched(true);
        if (!validator(inputValue) && showErrorOnFocus) {
            setHasError(true);
            setError(errorMessage);
        } else {
            setHasError(false);
            setError("");
        }
    };

    const onBlur = (e) => {
        onBlurCallback(e);
    };

    return (
        <div styleName={`styles.inputShift ${prefix ? "styles.hasPrefix" : ""}`}>
            <input
                ref={inputRef}
                id={id}
                type={type}
                className={isAutoFocused ? "form-control focused" : "form-control"}
                styleName={`${isAutoFocused ? "styles.focused" : ""} ${showError ? "styles.errorBorder" : ""}`}
                value={value}
                maxLength={maxLength}
                onChange={onChangeHandler}
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
                onKeyPress={onKeyPress}
                readOnly={readOnly}
                autoFocus={isAutoFocused}
            />
            {prefix && <span styleName={"styles.pricePrefixText"}>{prefix}</span>}
            {text && (
                <label
                    styleName={`${(isFocused || !!value || value === 0) ? "active" : ""}`}
                    className={`${(isFocused || !!value || value === 0) ? "active" : ""}`}
                 >
                    {text}
                </label>
            )}
            {showError && (
                <Fragment>
                    <small styleName={"styles.error"}>{error}</small>
                    {isShowErrorIcon && <img styleName={"styles.errorIcon"} className="errorIcon" src={IconError} alt="" width={16} height={16} />}
                </Fragment>
            )}
            {(showValidCheckmark && !hasError) && <div styleName={"styles.success"}><IconSuccess /></div>}
        </div>
    );
};

InputText.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    onBlurCallback: PropTypes.func,
    maxLength: PropTypes.number,
    validator: PropTypes.func,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object,
    onFocusCallback: PropTypes.func,
    placeholder: PropTypes.string,
    isAutoFocused: PropTypes.bool,
    disabled: PropTypes.bool,
    showErrorOnFocus: PropTypes.bool,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showValidCheckmark: PropTypes.bool,
    onKeyPress: PropTypes.func,
    readOnly: PropTypes.bool,
    serverErrorMessage: PropTypes.string,
    isError: PropTypes.bool,
    disableOnChangeError: PropTypes.bool,
    prefix: PropTypes.string,
    isShowErrorIcon: PropTypes.func
};
export default InputText;
