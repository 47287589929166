import Types from "./types";
import { ListingService, CFService } from "../../../service";
import { DEVICE_TYPE } from "../../../constants/app-constants";

const fetchDetails = () => ({
    type: Types.FETCH_CAR_DETAILS
});

const fetchCarDetailsSuccess = (data) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS,
    data
});

const fetchCarDetailsFailure = (error) => ({
    type: Types.FETCH_CAR_DETAILS_FAILURE,
    error
});

const fetchCarDetails = (carId, trackCleverTap, variantType) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    const platform = DEVICE_TYPE.DESKTOP;
    dispatch(fetchDetails());
    return new Promise((resolve, reject) => {
        ListingService.fetchCarDetail(carId, variantType, secureToken, platform)
            .then(response => {
                dispatch(fetchCarDetailsSuccess(response.data));
                //     if (trackCleverTap) {
                //     const { interiorImages, exteriorImages } = response.data.detail?.spinMedia || {};
                //     const imagesList = [...spinCarImageListParser(exteriorImages).slice(NUMBER.ZERO, NUMBER.THREE), ...spinCarImageListParser(interiorImages).slice(NUMBER.ZERO, NUMBER.TWO)];
                //     const imageHost = response?.data?.config?.imageHost;
                //     cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.VIEWED_CAR, {content: response.data.detail, config: response.data.config});
                //     console.log("aaa");
                //     cleverTap.desktopCleverTapEvent(cleverTap.desktopConstants.CAR_DETAILS_PAGE, {
                //         imageHost,
                //         image1: fastlyUrl({
                //                 basePath: imageHost,
                //                 path: imagesList[NUMBER.ZERO]?.path,
                //                 type: imageTypes.aeCarDetailPreview
                //             }),
                //         image2: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.ONE]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image3: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.TWO]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image4: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.THREE]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image5: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.FOUR]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         carDetailsContent: response.data.detail,
                //         cityName: getState()?.cities?.selectedCity?.name
                //     });
                // }

                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

// Similar Cars
const fetchSimilarCarsInit = () => ({
    type: Types.FETCH_SIMILAR_CARS
});

const fetchSimilarCarsSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_SUCCESS,
    data
});

const fetchSimilarCarsFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_FAILURE,
    error
});

const fetchSimilarCars = (carId, params) => dispatch => {
    dispatch(fetchSimilarCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params)
            .then(response => {
                dispatch(fetchSimilarCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsFailure(error));
                reject(error);
            });
    });
};

// Similar Cars For Modal
const fetchSimilarCarsForModalInit = () => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_MODAL_INIT
});

const fetchSimilarCarsForModalSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_MODAL_SUCCESS,
    data
});

const fetchSimilarCarsForModalFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_MODAL_FAILURE,
    error
});

const fetchSimilarCarsForModal = (carId, params) => dispatch => {
    dispatch(fetchSimilarCarsForModalInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params)
            .then(response => {
                dispatch(fetchSimilarCarsForModalSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsForModalFailure(error));
                reject(error);
            });
    });
};

const fetchEmiDataSuccess = (data) => ({
    type: Types.FETCH_EMI_DATA_SUCCESS,
    data
});

const fetchEmiDataFailure = (error) => ({
    type: Types.FETCH_EMI_DATA_FAILURE,
    error
});

const fetchEmiData = (price) => dispatch => {
    return new Promise((resolve, reject) => {
        CFService.fetchFinanceDetail(price)
            .then(response => {
                dispatch(fetchEmiDataSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchEmiDataFailure(error));
                reject(error);
            });
    });
};

const setEmiValues = (data) => ({
    type: Types.SET_EMI_VALUES,
    data
});

const setGsGalleryData = (data) => ({
    type: Types.SET_GS_GALLERY_DATA,
    data
});

const setCarDetailsURL = (data) => ({
    type: Types.SET_CAR_DETAILS_URL,
    data
});

const updateCarDetailsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

// Similar Cars for Comparison
const fetchSimilarCarsCompareInit = () => ({
    type: Types.FETCH_SIMILAR_CARS_COMPARE
});
const fetchSimilarCarsCompareSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_COMPARE_SUCCESS,
    data
});
const fetchSimilarCarsCompareFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_COMPARE_FAILURE,
    error
});
const fetchSimilarCarsCompare = (carId, params) => dispatch => {
    dispatch(fetchSimilarCarsCompareInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params)
            .then(response => {
                dispatch(fetchSimilarCarsCompareSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsCompareFailure(error));
                reject(error);
            });
    });
};

export {
    fetchCarDetails,
    fetchSimilarCars,
    fetchSimilarCarsForModal,
    fetchEmiData,
    setEmiValues,
    setGsGalleryData,
    setCarDetailsURL,
    updateCarDetailsSSRStatus,
    fetchSimilarCarsCompare
};
