import { createReducer } from "../../../utils/redux/create-reducer";
import { GALLERY_TAB_TYPE } from "./constant";
import Types from "./types";

export const INITIAL_STATE = {
    content: {
        city: "",
        price: 0,
        emiDetails: {}
    },
    footerMetaContent: [],
    inspectionReport: null,
    error: null,
    isFetching: null,
    isSSR: false,
    similarCars: [],
    internalLinks: [],
    isSimilarCarsFetching: false,
    isSimilarCarsError: null,
    emiData: {},
    emiDataError: null,
    emiValues: {},
    config: {},
    galleryProps: {
        isOpen: false,
        activeTab: GALLERY_TAB_TYPE.PHOTOS,
        isSpinCarLoaded: false,
        isSpinCarLoading: false,
        selectedImageIndex: 0
    },
    url: {
        relative: "",
        absolute: ""
    },
    similarCarsForModal: {},
    isSimilarCarsForModalLoading: false,
    similarCarsComparison: {
        isSSR: false,
        selectedCars: [],
        compareData: {
            details: {},
            metadata: {}
        }
    }
};

export const updateCarDetailsSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const fetchCarDetails = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetching: true,
        content: {
            city: "",
            price: 0,
            emiDetails: {}
        }, inspectionReport: null };
};

export const fetchCarDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        content: data.detail,
        footerMetaContent: data?.footerMetaContent,
        isFetching: false,
        error: null,
        config: data.config
    };
};

export const fetchCarDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetching: false};
};

// Similar Cars
export const fetchSimilarCars = (state = INITIAL_STATE) => {
    return { ...state, isSimilarCarsFetching: true, similarCars: [] };
};

export const fetchSimilarCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        similarCars: data.similarVehicles,
        internalLinks: data.internalDetails,
        isSimilarCarsFetching: false,
        isSimilarCarsError: null
    };
};

export const fetchSimilarCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isSimilarCarsError: error, isSimilarCarsFetching: false};
};

// Similar Cars For Modal
export const fetchSimilarCarsForModalInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarsForModal: {},
        isSimilarCarsForModalLoading: true
    };
};
export const fetchSimilarCarsForModalSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        similarCarsForModal: data,
        isSimilarCarsForModalLoading: false
    };
};
export const fetchSimilarCarsForModalFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarsForModal: {},
        isSimilarCarsForModalLoading: false
    };
};

export const fetchEmiDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        emiData: data,
        emiDataError: null
    };
};

export const fetchEmiDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, emiDataError: error};
};

export const setEmiValues = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        emiValues: data
    };
};

export const setGsGalleryData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        galleryProps: {
            ...state.galleryProps,
            ...data
        }
    };
};

export const setCarDetailsURL = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        url: data
    };
};

// Similar Cars Compare
export const fetchSimilarCarsCompare = (state = INITIAL_STATE) => {
    return { ...state, isSimilarCarsFetching: true, similarCarsComparison: {
        ...state.similarCarsComparison
    } };
};
export const fetchSimilarCarsCompareSuccess = (state = INITIAL_STATE, { data, isSSR = false }) => {
    const { details, metadata } = data?.comparisonDetails || {};
    return {
        ...state,
        config: data?.config,
        similarCarsComparison: {
            ...state.similarCarsComparison,
            isSSR,
            selectedCars: details ? Object.values(details).map(({ appointmentId, make, model, year, variant, mainImage }) => ({ appointmentId, make, model, year, variant, mainImage})) : [],
            compareData: {
                ...state.similarCarsComparison.compareData,
                details,
                metadata
            }
        }
    };
};
export const fetchSimilarCarsCompareFailure = (state = INITIAL_STATE) => {
    return { ...state, isSimilarCarsFetching: false };
};

export const HANDLERS = {
    [Types.UPDATE_SSR_STATUS]: updateCarDetailsSSRStatus,

    [Types.FETCH_CAR_DETAILS]: fetchCarDetails,
    [Types.FETCH_CAR_DETAILS_SUCCESS]: fetchCarDetailsSuccess,
    [Types.FETCH_CAR_DETAILS_FAILURE]: fetchCarDetailsFailure,

    // Similar Cars
    [Types.FETCH_SIMILAR_CARS]: fetchSimilarCars,
    [Types.FETCH_SIMILAR_CARS_SUCCESS]: fetchSimilarCarsSuccess,
    [Types.FETCH_SIMILAR_CARS_FAILURE]: fetchSimilarCarsFailure,

    // Similar Cars For Modal
    [Types.FETCH_SIMILAR_CARS_FOR_MODAL_INIT]: fetchSimilarCarsForModalInit,
    [Types.FETCH_SIMILAR_CARS_FOR_MODAL_SUCCESS]: fetchSimilarCarsForModalSuccess,
    [Types.FETCH_SIMILAR_CARS_FOR_MODAL_FAILURE]: fetchSimilarCarsForModalFailure,

    [Types.FETCH_EMI_DATA_SUCCESS]: fetchEmiDataSuccess,
    [Types.FETCH_EMI_DATA_FAILURE]: fetchEmiDataFailure,

    [Types.SET_EMI_VALUES]: setEmiValues,
    [Types.SET_GS_GALLERY_DATA]: setGsGalleryData,
    [Types.SET_CAR_DETAILS_URL]: setCarDetailsURL,

    // Similar Cars Compare
    [Types.FETCH_SIMILAR_CARS_COMPARE]: fetchSimilarCarsCompare,
    [Types.FETCH_SIMILAR_CARS_COMPARE_SUCCESS]: fetchSimilarCarsCompareSuccess,
    [Types.FETCH_SIMILAR_CARS_COMPARE_FAILURE]: fetchSimilarCarsCompareFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
