import { resetReducerKeys } from "../../../constants/app-constants";
import { CFService } from "../../../service";
import Types from "./types";

// RESET CAR_LOAN
const resetCarLoan = () => ({
    type: resetReducerKeys.RESET_CAR_LOAN
});

// FETCH EMI CALCULATOR
const fetchEmiCalculatorInit = () => ({
    type: Types.FETCH_EMI_CALCULATOR_INIT
});

const fetchEmiCalculatorSuccess = (data) => ({
    type: Types.FETCH_EMI_CALCULATOR_SUCCESS,
    data
});

const fetchEmiCalculatorFailure = () => ({
    type: Types.FETCH_EMI_CALCULATOR_FAILURE
});

const fetchEmiCalculator = (params) => (dispatch) => {
    dispatch(fetchEmiCalculatorInit());
    return new Promise((resolve, reject) => {
        CFService.updateEmiCalculator(params)
            .then((response) => {
                const data = response?.data || {};
                dispatch(fetchEmiCalculatorSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchEmiCalculatorFailure());
                reject(err);
            });
    });
};

// LOAN ELIGIBILITY CHECK
const loanEligibilityInit = () => ({
    type: Types.LOAN_ELIGIBILITY_INIT
});

const loanEligibilitySuccess = (data) => ({
    type: Types.LOAN_ELIGIBILITY_SUCCESS,
    data
});

const loanEligibilityFailure = (error) => ({
    type: Types.LOAN_ELIGIBILITY_FAILURE,
    error
});

const checkLoanEligibility = (token, params) => (dispatch) => {
    dispatch(loanEligibilityInit());
    return new Promise((resolve, reject) => {
        CFService.checkLoanEligibility(token, params).then((response) => {
            dispatch(loanEligibilitySuccess(response?.data || {}));
            resolve(response.data);
        }).catch((error) => {
            dispatch(loanEligibilityFailure(error));
            reject(error);
        });
    });
};

// USER LOAN ELIGIBILITY
const userLoanEligibilityInit = () => ({
    type: Types.USER_LOAN_ELIGIBILITY_INIT
});

const userLoanEligibilitySuccess = (data) => ({
    type: Types.USER_LOAN_ELIGIBILITY_SUCCESS,
    data
});

const userLoanEligibilityFailure = (error) => ({
    type: Types.USER_LOAN_ELIGIBILITY_FAILURE,
    error
});

const checkUserLoanEligibility = (token, params) => (dispatch) => {
    dispatch(userLoanEligibilityInit());
    return new Promise((resolve, reject) => {
        CFService.checkUserLoanEligibility(token, params).then((response) => {
            dispatch(userLoanEligibilitySuccess(response?.data || {}));
            resolve(response.data);
        }).catch((error) => {
            dispatch(userLoanEligibilityFailure(error));
            reject(error);
        });
    });
};

export {
    fetchEmiCalculator,
    resetCarLoan,
    checkLoanEligibility,
    checkUserLoanEligibility
};
