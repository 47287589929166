import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import CarBanner from "./images/car-banner.png";
import CarSaleBanner from "./images/sale-banner.png";
import WeekendSaleBanner from "./images/weekend-sale-banner.webp";
import IconPattern from "./images/pattern.svg";

// Component
import SearchInfo from "../search-info";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

// Utils
import Filters from "../../../utils/filters-v2";

const LandingHeroBanner = ({
    searchPageLocale,
    clearAllFiltersConnect,
    reloadCarListConnect,
    selectedCityName,
    selectedCityCode,
    bannerInfoLocales,
    activeSaleConfig = {},
    weekendSale
}) => {

    const { active: isActiveSale = false } = activeSaleConfig || {};
    const { active: weekendSaleActive } = weekendSale || {};

    const handleShowAllCars = () => {
        clearAllFiltersConnect();
        trackDesktopCustomEventsAE("viewAllCarsHome", {});
        reloadCarListConnect(true);
    };

    const getViewAllRedirectUrl = () => {
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {}, isGlobalUrl: true });
        return relativeURL;
    };

    return (
        <Fragment>
            <Helmet>
                <link fetchPriority="high" rel="preload" href={CarBanner} as={"image"} type="image/png" />
                <link fetchPriority="high" rel="preload" href={IconPattern} as={"image"} type="image/png" />
            </Helmet>
            {!weekendSaleActive ? <div styleName={"styles.buyUsedCars"}>
                <div className="container" styleName={"styles.wrapper"}>
                    <div styleName={"styles.centerAlign"} className="d-flex justify-content-between align-center">
                        <div styleName={"styles.bannerContent"}>
                            <h1 dangerouslySetInnerHTML={{ __html: bannerInfoLocales.bannerHeading }} />
                            <h2>{bannerInfoLocales.bannerSubheading}</h2>
                            <SearchInfo locales={searchPageLocale} />
                        </div>
                        <div styleName={"styles.bannerImage"}>
                            <img src={isActiveSale ? CarSaleBanner : CarBanner} width={482} height={184} alt="CARS24 Hero banner" />
                        </div>
                    </div>
                    <img styleName={"styles.patternImage"} height={224} width={617} src={IconPattern} alt="Hero image pattern" />
                </div> </div> :
                <div styleName="styles.weekendSaleBanner">
                    <img src={WeekendSaleBanner} width={1440} height={224} alt="Weekend sale banner" />
                    <a styleName={"styles.viewAllCta"} href={getViewAllRedirectUrl()} onClick={handleShowAllCars}>VIEW ALL CARS</a>
                </div>
            }
        </Fragment>
    );
};

LandingHeroBanner.propTypes = {
    searchPageLocale: PropTypes.object,
    bannerInfoLocales: PropTypes.object,
    activeSaleConfig: PropTypes.object,
    weekendSale: PropTypes.object,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func
};

export default LandingHeroBanner;
