import { resetReducerKeys } from "../../../constants/app-constants";
import { C2bAppointmentService } from "../../../service";
import Types from "./types";

const fetchLeadsSuccess = (data) => ({
    type: Types.FETCH_LEADS_SUCCESS,
    data
});

const fetchLeadsFailure = (error) => ({
    type: Types.FETCH_LEADS_FAILURE,
    error
});

export const resetSellerPendingLeads = () => ({
    type: resetReducerKeys.RESET_SELLER_LEADS
});

export const fetchLeadsAgainstPhone = (phone, confirmOnly = false, latestOnly = true) => (dispatch) => {
    const payload = {
        confirmAppointmentLeadsOnly: confirmOnly,
        includeLatestLeadsOnly: latestOnly,
        phone
    };
    return new Promise((resolve, reject) => {
        C2bAppointmentService.getAppointmentsWithPhone(payload)
            .then(response => {
                dispatch(fetchLeadsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchLeadsFailure(error));
                reject(error);
            });
    });
};
