import { FINANCE_STEPS } from "./ae/cf-constants";

const ROUTES = {
    getFinancing: "/buy-used-cars/finance",
    getfinancingPitch: `/buy-used-cars/finance/${FINANCE_STEPS.PITCH}`,
    getFinancingEligibilty: `/buy-used-cars/finance/${FINANCE_STEPS.ELIGIBILITY_FLOW}`,
    getFinancingLoanOffers: `/buy-used-cars/finance/${FINANCE_STEPS.LOAN_OFFERS}`,
    getFinancingIndicativeOffer: `/buy-used-cars/finance/${FINANCE_STEPS.INDICATIVE_LOAN_OFFERS}`,
    calculator: "/calculator"
};

export const ROUTE_PATHS = {
    checkout: "checkout",
    calculator: "calculator",
    financeOffline: "checkout/finance/offline",
    financeSummary: "checkout/finance/summary",
    financeCustomize: "checkout/finance/customize",
    carEvaluation: "car-evaluation",
    postBooking: "booking-confirmation",
    feedback: "feedback",
    features: "features",
    payment: "payment",
    paymentMethod: "payment-method",
    carRepair: "car-repair",
    carReturn: "car-return",
    chooseSlot: "choose-slot",
    viewAllTickets: "tickets",
    carAddress: "car-address",
    zendeskChat: "zendesk-chat",
    vas: "vas",
    wishlist: "my-wishlist",
    loanPreApproval: "loan-pre-approval",
    tofService: "tof-service",
    tofServiceHome: "tof-service-home",
    serviceContract: "service-contract",
    tofServiceSchedule: "tof-service-schedule",
    tofServiceAnchor: "tof-service-anchor",
    myServiceOrders: "my-service-orders",
    epicCarSale: "epic-car-sale",
    salesAgreement: "sales-agreement",
    deliveryFeedback: "delivery-feedback",
    carLoan: "car-loan",
    similarCars: "similar-cars",
    personalizedCars: "personalized-cars",
    aiRecommendedCars: "ai-recommended-cars",
    listing: "buy-used-cars"
};

export const POSTBC_ROUTES = {
    testDrive: "test-drive",
    delivery: "delivery"
};

export const CAR_EVALUATION_PATHS = {
    make: "make",
    model: "model",
    year: "year"
};

export const ALLOWED_ROUTE_PATHS = {
    FINANCE: Object.values(FINANCE_STEPS).join("|"),
    CAR_EVALUATION: Object.values(CAR_EVALUATION_PATHS).join("|")
};

export const ROUTE_NAME = {
    HOME: "ae.home",
    LISTING: "ae.listing",
    DETAIL_PAGE: "ae.car-details",
    ALL_TICKET: "ae.tickets",
    ZENDESK_CHAT: "ae.zendesk-chat",
    POST_BC: "booking-confirmation",
    CAR_DETAILS_CHECKOUT: "ae.car-details-checkout",
    CAR_EVALUATION: "ae.car-evaluation",
    SELL_ONLINE: "ae.sell-online",
    MENU: "ae.menu",
    BOOK_APPOINTMENT: "ae.book-appointment",
    SELLER_REVALUATION: "ae.seller-revaluation",
    DOCUMENTS_AND_PICKUP: "ae.post-price-acceptance",
    EPIC_DROP_SALE: "ae.epic-car-sale",
    CAR_LOAN: "ae.car-loan",
    SERVICE_CONTRACT: `ae.${ROUTE_PATHS.tofService}`,
    SERVICE_CONTRACT_HOME: `ae.${ROUTE_PATHS.tofServiceHome}`,
    MY_SERVICE_ORDERS: `ae.${ROUTE_PATHS.myServiceOrders}`,
    TOF_SERVICE_SCHEDULE: `ae.${ROUTE_PATHS.tofServiceSchedule}`,
    TOF_SERVICE_ANCHOR: `ae.${ROUTE_PATHS.tofServiceAnchor}`,
    PROFILE: "ae.profile",
    SIMILAR_CARS: "ae.similar-cars",
    AI_RECOMMENDED_CARS: `ae.${ROUTE_PATHS.aiRecommendedCars}`,
    BOOKING_CONFIRMATION: `ae.${ROUTE_PATHS.postBooking}`,
    PERSONALIZED_CARS: `ae.${ROUTE_PATHS.personalizedCars}`,
    SEO_CAR_DETAILS: "ae.seo-car-details",
    C2C_MARKET_PLACE: "ae.market-place",
    C2C_SELLER_CENTER: "ae.seller-center",
    FAQ: "faq"
};

export const DOCUMENTS_UPLOAD_ROUTE = "/seller/upload-documents/";
export const SELLER_REVALUATION_ROUTE = "/seller/revaluation/";
export const SELLER_POST_PRICE_ACCEPTANCE = "/seller/post-price-acceptance";
export const USER_REVIEWS_ROUTE = "/user-reviews";
export const HOW_TO_BUY_ROUTE = "/how-to-buy";
export const HELP_CENTER_ROUTE = "/help-center";
export const EDIT_PROFILE_ROUTE = "/profile";
export const EPIC_CAR_SALE = "/epic-car-sale";
export const SELLER_APPOINTMENT_ADDRESS = "/book-appointment/address";
export default ROUTES;
