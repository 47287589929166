import desktopEventsAE from "./ae.desktop-events";
import desktopSellerEventsAE from "./ae.desktop-seller-events";
import { trackCustomEvents } from "../custom-event";

// DESKTOP
export const trackDesktopCustomEventsAE = (category, extras = {}) => {
    trackCustomEvents({
        platformEvents: desktopEventsAE, category
    }, extras);
};

export const trackDesktopMultipleEventsAE = (category, params, extras = {}) => {
    trackCustomEvents({
        platformEvents: desktopEventsAE, category, params
    }, extras);
};

export const trackDesktopSellerCustomerEvents = (category, extras = {}) => {
    trackCustomEvents({
        platformEvents: desktopSellerEventsAE, category
    }, extras);
};
