import Types from "./types";
import { CheckoutAEService as CheckoutService, CFService, couponService } from "../../../service";
import { CHECKOUT_STEP_KEY } from "../../../constants/ae/checkout-constants";
import { resetReducerKeys } from "../../../constants/app-constants";
import { setFinanceData as setFinanceDataEmiDetails } from "../emi-details-section/actions";
import { setFinanceUpdatedData as setFinanceUpdatedDataEmiDetails } from "../emi-details-section/actions";
import { API_SOURCES } from "../../../constants/api-constants";

const setUserPersonalDetail = (data) => ({
    type: Types.SETUSER_PERSONAL_DETAIL,
    userPersonalDetail: data
});

const setStep = ({ currentStep, tabs }) => {
    return {
        type: Types.SET_CURRENT_STEP,
        currentStep,
        tabs
    };
};

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setPersonalDetailSuccessMessage = ({ successMessage }) => {
    return {
        type: Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE,
        successMessage
    };
};

const setOrder = ({
    bookingAmount,
    bookingConfirmDate,
    chargeDetails,
    country,
    createdDate,
    currency,
    deliveryAddress,
    deliveryMode,
    financeOpted,
    financeTerms,
    id,
    orderId,
    pickupStoreId,
    pickupTime,
    status,
    storeId,
    totalAmount,
    userId,
    vehiclePrice,
    vehicleType,
    claimedCoupons,
    fasterCheckout,
    hubToHomeChangeAttempted
}) => {
    return {
        type: Types.SET_ORDER,
        bookingAmount,
        bookingConfirmDate,
        chargeDetails,
        country,
        createdDate,
        currency,
        deliveryAddress,
        deliveryMode,
        financeOpted,
        financeTerms,
        id,
        orderId,
        pickupStoreId,
        pickupTime,
        status,
        storeId,
        totalAmount,
        userId,
        vehiclePrice,
        vehicleType,
        claimedCoupons,
        fasterCheckout,
        hubToHomeChangeAttempted
    };
};

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const getOrderDetailInit = (data) => ({
    type: Types.GET_ORDER_DETAIL_INIT,
    data
});

const getOrderDetailSuccess = (data) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    data
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const getOrderDetail = (appointmentId, withLoader = true) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    if (!secureToken) {
        return false;
    }
    dispatch(getOrderDetailInit(withLoader));
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummary(appointmentId, secureToken)
            .then(response => {
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createOrderSuccess = (data) => ({
    type: Types.CREATE_ORDER_SUCCESS,
    data
});

const createOrderFailure = (error) => ({
    type: Types.CREATE_ORDER_FAILURE,
    error
});

const createOrder = (params) => (dispatch, getState) => {
    const { user: { secureToken, gaId } } = getState();
    dispatch(getOrderDetailInit(true));
    return new Promise((resolve, reject) => {
        CheckoutService.createOrder({ ...params, gaId }, secureToken, API_SOURCES?.MSITE)
            .then(response => {
                dispatch(createOrderSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
    });
};

const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
});

const fetchFinance = (vehiclePrice, carYear, financeEligibility) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CFService.fetchFinanceDetailAE(vehiclePrice, carYear, financeEligibility)
            .then(response => {

                const { data } = response;
                const updatedFinanceDataPayload = {
                    creditScore: data.defaultRoi,
                    downPayment: data.defaultDownPaymentValue,
                    emi: data.defaultMonthlyEmiValue,
                    tenure: data.defaultLoanTenureValue,
                    chargeList: null
                };
                dispatch(setFinanceData(data));
                dispatch(setFinanceDataEmiDetails(data));
                dispatch(setFinanceUpdatedData(updatedFinanceDataPayload));
                dispatch(setFinanceUpdatedDataEmiDetails(updatedFinanceDataPayload));
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

const updateFinancePersonalDetail = (params, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinancePersonalDetail(params, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateFinanceDetail = (order, orderId) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail(order, orderId, secureToken)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const getDeliveryInfoRequest = () => ({
    type: Types.GET_DELIVERY_INFO_REQUEST
});

const getDeliveryInfoSuccess = (data, pincode) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode
});

const getDeliveryInfoFailure = (error) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error
});

const getDeliveryInfo = (appointmentId, cityCode, token) => (dispatch) => {
    dispatch(getDeliveryInfoRequest());
    return new Promise((resolve, reject) => {
        CFService.getBenefits(token, appointmentId, cityCode).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data, cityCode));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error));
            reject(error);
        });
    });
};

const updateDeliveryModeSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_MODE_SUCCESS,
    data
});

const updateDeliveryModeFailure = (error) => ({
    type: Types.UPDATE_DELIVERY_MODE_FAILURE,
    error
});

const updateDeliveryMode = (order, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateDeliveryMode(order, orderId, token).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};

const updateBookingTypeSuccess = (data) => ({
    type: Types.UPDATE_BOOKING_TYPE_SUCCESS,
    data
});

const updateBookingTypeFailure = (error) => ({
    type: Types.UPDATE_BOOKING_TYPE_FAILURE,
    error
});

const updateBookingType = (orderId, bookingType, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateBookingType(orderId, bookingType, token).then((response) => {
            dispatch(updateBookingTypeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateBookingTypeFailure(error));
            reject(error);
        });
    });
};

const initiateNexOrderPayment = ({ orderId, bookingType, payload, token }) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateNexOrderPayment({ orderId, bookingType, payload, token, gaId }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const initiateExOrderPayment = (orderId, payload, token) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateExOrderPayment(orderId, payload, token, gaId).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const initiateBookingPayment = ({orderId, payload, token}) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    const params = {type: "NEX", gaId};
    return new Promise((resolve, reject) => {
        CheckoutService.initiateBookingPayment(orderId, payload, token, params).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const initiateSeamlessBookingPayment = ({orderId, payload, token}) => (_dispatch, getState) => {
    const { user: { gaId } } = getState();
    const params = {type: "NEX", gaId};
    return new Promise((resolve, reject) => {
        CheckoutService.initiateSeamlessBookingPayment(orderId, payload, token, params).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPaymentStatus = (orderId, paymentId) => (_dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentStatus(orderId, paymentId, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateOrderBooking = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setSelectedFinancingData = ({ loanTenure }) => ({
    type: Types.SET_SELECTED_FINANCING_DATA,
    loanTenure
});

const setOrderId = ({ orderId }) => ({
    type: Types.SET_ORDER_ID,
    orderId
});

const setOrderCreatedDateTime = ({ orderCreatedDatetime }) => ({
    type: Types.SET_ORDER_CREATED_DATE_TIME,
    orderCreatedDatetime
});

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const setChargeDetailsList = (chargeDetailsList) => ({
    type: Types.SET_CHARGE_LIST,
    chargeDetailsList
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_FINANCE_SCREEN,
    activeScreen
});

const setData = ({ type, data }, { stepper }) => dispatch => {
    switch (type) {
        case CHECKOUT_STEP_KEY.Financing:
            dispatch(setSelectedFinancingData({
                loanTenure: data.loanTenure,
                stepper
            }));
            break;
    }
};

const setDeliveryData = (data) => ({
    type: Types.SET_DELIVERY_DATA,
    data
});

const setConfirmBookingData = (data) => ({
    type: Types.SET_CONFIRM_BOOKING_DATA,
    data
});

const cancelOrder = (orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.cancelOrder(orderId, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
    });
};

const resetCheckoutData = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_CHECKOUT });
};

// const updateVisitedSteps = (step) => ({
//     type: Types.UPDATE_VISITED_STEPS,
//     step
// });
const setTradeInChoice = (data) => dispatch => {
    dispatch({ type: Types.TRADE_IN_CHOICE, data });
};

const setTestDriveModes = (data) => ({
    type: Types.SET_TEST_DRIVE_MODE,
    data
});

const getTestDriveLocations = ({cityCode = "", orderId = ""}, tokenPassed) => (_dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getTestDriveLocations({cityCode, orderId}, tokenPassed || secureToken).then((response) => {
            _dispatch(setTestDriveModes(response.data));
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setSelectedDeliveryInfo = (data) => (dispatch) => {
    dispatch({ type: Types.SET_SELECTED_DELIVERY_INFO, data });
};

const removeCoupon = (orderId, token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.removeCoupon(orderId, token, params)
            .then(response => {
                dispatch(setOrder(response.data));
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const applyCoupon = ({ orderId, couponCode, userId, gaId, secureToken }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.applyCoupon(orderId, { couponCode, userId, gaId }, secureToken)
            .then((response) => {
                dispatch(setOrder(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const setChangeTdLocation = (data) => ({
    type: Types.SET_CHANGE_TD_LOCATION,
    data
});

const fetchTradeInCouponDetails = () => (_, getState) => {
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        couponService.fetchTradeInCouponDetails(secureToken)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// C2C Checkout : START
const submitCarOfferInit = (withLoader = true) => ({
    type: Types.SUBMIT_CAR_OFFER_INIT,
    withLoader
});

const submitCarOfferSuccess = (data) => ({
    type: Types.SUBMIT_CAR_OFFER_SUCCESS,
    data
});

const submitCarOfferFailure = () => ({
    type: Types.SUBMIT_CAR_OFFER_FAILURE
});

const submitCarOffer = (payLoad, tokenPassed, withLoader = true) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    dispatch(submitCarOfferInit(withLoader));
    return new Promise((resolve, reject) => {
        CheckoutService.submitOfferC2c(payLoad, tokenPassed || secureToken, API_SOURCES.MSITE)
            .then((response) => {
                const orderData = response?.data || {};
                dispatch(submitCarOfferSuccess(orderData));
                resolve(orderData);
            })
            .catch((error) => {
                dispatch(submitCarOfferFailure());
                reject(error);
            });
    });
};

const acceptCarOfferInit = () => ({
    type: Types.ACCEPT_CAR_OFFER_INIT
});

const acceptCarOfferSuccess = (data) => ({
    type: Types.ACCEPT_CAR_OFFER_SUCCESS,
    data
});

const acceptCarOfferFailure = () => ({
    type: Types.ACCEPT_CAR_OFFER_FAILURE
});

const acceptCarOffer = (offerId, payLoad, secureToken) => (dispatch) => {
    dispatch(acceptCarOfferInit());
    return new Promise((resolve, reject) => {
        CheckoutService.acceptOfferC2c(offerId, payLoad, secureToken, API_SOURCES.MSITE)
            .then((response) => {
                const orderData = response?.data || {};
                dispatch(acceptCarOfferSuccess(orderData));
                resolve(orderData);
            })
            .catch((error) => {
                dispatch(acceptCarOfferFailure());
                reject(error);
            });
    });
};
// C2C Checkout : END

export {
    setUserPersonalDetail,
    setStep,
    setData,
    setPersonalDetailSuccessMessage,
    setOrderCreatedDateTime,
    fetchFinance,
    setFinanceData,
    getDeliveryInfo,
    getOrderDetail,
    updateFinancePersonalDetail,
    setDeliveryMode,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    createOrder,
    setOrderId,
    setOrder,
    updateFinanceDetail,
    updateDeliveryMode,
    updateBookingType,
    updateOrderBooking,
    initiateNexOrderPayment,
    initiateExOrderPayment,
    setFinanceUpdatedData,
    setDeliveryData,
    setFinancingScreen,
    setConfirmBookingData,
    cancelOrder,
    setChargeDetailsList,
    resetCheckoutData,
    getPaymentStatus,
    setTradeInChoice,
    getTestDriveLocations,
    setSelectedDeliveryInfo,
    removeCoupon,
    applyCoupon,
    setChangeTdLocation,
    fetchTradeInCouponDetails,
    initiateBookingPayment,
    initiateSeamlessBookingPayment,

    // C2C Checkout : START,
    submitCarOffer,
    acceptCarOffer
    // C2C Checkout : END
};
