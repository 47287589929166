import Types from "./types";
import confirmationType from "../post-booking-confirmation/types";
import { SlotPlannerService } from "../../../service";
import { resetReducerKeys } from "../../../constants/app-constants";

const setSelectedEmirate = (selectedEmirate) => {
    return {
        type: Types.SET_SELECTED_EMIRATE,
        selectedEmirate
    };
};

const setSelectedZone = (selectedZone) => {
    return {
        type: Types.SET_SELECTED_ZONE,
        selectedZone
    };
};

const setSelectedLocation = (selectedLocation) => {
    return {
        type: Types.SET_SELECTED_LOCATION,
        selectedLocation
    };
};

const setSelectedSlot = (selectedSlot) => {
    return {
        type: Types.SET_SELECTED_SLOT,
        selectedSlot
    };
};

const resetSelectedSlot = () => ({
    type: Types.RESET_SELECTED_SLOT
});

const setAddress = (addressDetails) => {
    return {
        type: Types.SET_ADDRESS,
        addressDetails
    };
};

const resetAddress = () => ({
    type: Types.RESET_ADDRESS
});

const resetSelectedLocation = () => ({
    type: Types.RESET_SELECTED_LOCATION
});

const setRescheduleReason = (selectedReason) => {
    return {
        type: Types.SET_RESCHEDULE_REASON,
        selectedReason
    };
};

const resetTdSchedule = () => ({
    type: resetReducerKeys.RESET_TD_SCHEDULE
});

const fetchEmiratesListSuccess = (data) => ({
    type: Types.FETCH_EMIRATES_LIST_SUCCESS,
    data
});

const fetchEmiratesListFailure = (error) => ({
    type: Types.FETCH_EMIRATES_LIST_FAILURE,
    error
});

const fetchEmiratesList = (slotType = "TEST_DRIVE") => (dispatch, getState) => {
    const { postBcDetails: { isMRL = false } = {} } = getState() || {};
    return new Promise((resolve, reject) => {
        SlotPlannerService.getEmiratesList(slotType, isMRL).then((response) => {
            dispatch(fetchEmiratesListSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchEmiratesListFailure(error));
            reject(error);
        });
    });
};

const fetchSelectedEmirateZonesSuccess = (data) => ({
    type: Types.FETCH_SELECTED_EMIRATE_ZONES_SUCCESS,
    data
});

const fetchSelectedEmirateZonesFailure = (error) => ({
    type: Types.FETCH_SELECTED_EMIRATE_ZONES_FAILURE,
    error
});

const fetchSelectedEmirateZones = (selectedEmirateCode, slotType = "TEST_DRIVE") => (dispatch, getState) => {
    const { postBcDetails: { isMRL = false } = {} } = getState() || {};
    return new Promise((resolve, reject) => {
        SlotPlannerService.getSelectedEmirateZonesList(selectedEmirateCode, slotType, isMRL).then((response) => {
            dispatch(fetchSelectedEmirateZonesSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchSelectedEmirateZonesFailure(error));
            reject(error);
        });
    });
};

const fetchSlotAvailabilityDetailsSuccess = (data) => ({
    type: Types.FETCH_SLOT_AVAILABILITY_SUCCESS,
    data
});

const fetchSlotAvailabilityDetailsFailure = (error) => ({
    type: Types.FETCH_SLOT_AVAILABILITY_FAILURE,
    error
});

const fetchSlotAvailabilityDetails = (params) => (dispatch) => {
    const { orderId, payload, slotType = "TEST_DRIVE", token } = params || {};
    return new Promise((resolve, reject) => {
        SlotPlannerService.getAvailableSlots(orderId, slotType, payload, token).then((response) => {
            dispatch(fetchSlotAvailabilityDetailsSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchSlotAvailabilityDetailsFailure(error));
            reject(error);
        });
    });
};

const confirmSlotScheduling = (params) => (dispatch) => {
    const { orderId, payload, slotType = "TEST_DRIVE", token } = params || {};
    return new Promise((resolve, reject) => {
        SlotPlannerService.bookAvailableSlot(orderId, slotType, payload, token).then((response) => {
            const addressObj = JSON.parse(response.data.address) || {};
            dispatch({
                type: confirmationType.UPDATE_SLOT_TD,
                bookedSlotDetail: {
                    address: {
                        addressLine1: addressObj?.line1 || "",
                        addressLine2: addressObj?.line2 || "",
                        city: response.data.locationName
                    },
                    from: response.data.from,
                    to: response.data.to,
                    giftAvailable: response.data.giftAvailable
                }
            });
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const confirmSlotReScheduling = (params) => (dispatch) => {
    const { orderId, payload, slotType = "TEST_DRIVE", token } = params || {};
    return new Promise((resolve, reject) => {
        SlotPlannerService.resheduleBookedSlot(orderId, slotType, payload, token).then((response) => {
            const addressObj = JSON.parse(response.data.address) || {};
            dispatch({
                type: confirmationType.UPDATE_SLOT_TD,
                bookedSlotDetail: {
                    address: {
                        addressLine1: addressObj?.line1 || "",
                        addressLine2: addressObj?.line2 || "",
                        city: response.data.locationName
                    },
                    from: response.data.from,
                    to: response.data.to,
                    giftAvailable: response.data.giftAvailable
                }
            });
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const fetchOrderRescheduleReasons = (params) => () => {
    const { slotType = "TEST_DRIVE", token } = params || {};
    return new Promise((resolve, reject) => {
        SlotPlannerService.getRescheduleReasons(slotType, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

export {
    setSelectedEmirate,
    setSelectedZone,
    setSelectedLocation,
    setSelectedSlot,
    resetSelectedSlot,
    setAddress,
    resetAddress,
    setRescheduleReason,
    fetchEmiratesList,
    fetchSelectedEmirateZones,
    fetchSlotAvailabilityDetails,
    confirmSlotScheduling,
    confirmSlotReScheduling,
    fetchOrderRescheduleReasons,
    resetTdSchedule,
    resetSelectedLocation
};
