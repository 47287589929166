import Types from "./types";
import { CheckoutAEService as CheckoutService } from "../../../service";
import { CHECKOUT_STEP_KEY } from "../../../constants/ae/checkout-constants";
import {resetReducerKeys} from "../../../constants/app-constants";

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setCreditScoreValue = ({ creditScoreValue }) => {
    return {
        type: Types.SET_CREDIT_SCORE_VALUE,
        creditScoreValue
    };
};

const setDownPaymentValue = ({ downPaymentValue }) => {
    return {
        type: Types.SET_DOWNPAYMENT_VALUE,
        downPaymentValue
    };
};

const setMonthlyEmiValue = ({ monthlyEmiValue }) => {
    return {
        type: Types.SET_MONTHLY_EMI_VALUE,
        monthlyEmiValue
    };
};

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
});

const updateFinanceDetail = (order, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail(order, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const setSelectedFinancingData = ({ loanTenure }) => ({
    type: Types.SET_SELECTED_FINANCING_DATA,
    loanTenure
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setData = ({ type, data }, { stepper }) => dispatch => {
    switch (type) {
    case CHECKOUT_STEP_KEY.Financing:
        dispatch(setSelectedFinancingData({
            loanTenure: data.loanTenure,
            stepper
        }));
        break;
    }
};

const resetEmiDetailsData = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_EMI_DETAILS });
};

export {
    setData,
    setFinanceData,
    setLoanTenureValue,
    setCreditScoreValue,
    setDownPaymentValue,
    setMonthlyEmiValue,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    updateFinanceDetail,
    setFinanceUpdatedData,
    resetEmiDetailsData
};
