import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
const LoadableLazyLoadComponent = loadable.lib(() => import("react-lazy-load-image-component"));

const LazyComponent = (props) => (
    <React.Fragment>
        <LoadableLazyLoadComponent fallback={<img src={props?.src || ""} />}>
        {({ LazyLoadComponent }) => <LazyLoadComponent {...props} />}
        </LoadableLazyLoadComponent>
    </React.Fragment>
);

LazyComponent.propTypes = {
    src: PropTypes.string
};

export default LazyComponent;
