import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarDetailMainSlider from "./component";
import { setGsGalleryData } from "../car-details/actions";

const mapStateToProps = ({
    carDetails: {
        content,
        config: {
            imageHost
        }
    },
    config: {
        resolutionInfo: {
            msite: resolutions
        }
    }
}) => {
    return {
        content,
        imageHost,
        resolutions
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setGsGalleryDataConnect: setGsGalleryData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarDetailMainSlider);
