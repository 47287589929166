/* eslint-disable no-invalid-this */
/* eslint-disable max-params */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArrowIcon from "../../shared/arrow";
import Checkbox from "../../shared/checkbox/component";
import NoCarFoundIcon from "./images/not-found.png";
// import Search from "../../shared/search";
import styles from "./styles.css";
import SearchFilterV2 from "../search-filter-v2";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import Accordion from "../../shared/accordion";
import checkAllOptionStatus from "../../../utils/filters-v2/check-all-option-status";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findByKey } from "../../../utils/helpers/find-by-key";
import { reloadCarList } from "../car-listing/actions";
import { toTitleCase } from "../../../utils/helpers/convert-title-case";

const UP_ARROW_ROTATE_DEGREE = 180;
const DOWN_ARROW_ROTATE_DEGREE = 0;

const ModelFilterRevamp = ({
    filter,
    handleUpdateSelectedFilters,
    onOptionSelection,
    selectedFilters,
    handleSubFilterUpdate
}) => {
    const [filterOptions, setFilterOptions] = useState(filter.options);
    const [brands, setBrands] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const onChangeSubFilterOption = (filterKey, filterOptionKey, subFilterOptionKey, isSelected, displayText) => {
        handleUpdateSelectedFilters({
            filterKey,
            optionKey: filterOptionKey,
            subOptionKey: subFilterOptionKey,
            isSelected,
            displayText
        });
        onOptionSelection();
    };

    const onSearchHandler = ({ options, value }) => {
        setSearchValue(value);
        setFilterOptions(options);
    };

    const onChangeAllSubOptions = (filterKey, optionKey, isSelected) => {
        handleSubFilterUpdate({
            filterKey, optionKey, isSelected
        });
        onOptionSelection();
    };

    const handleListExpand = (filterOptionIndex, onClick) => {
        onClick({ index: filterOptionIndex });
    };
    const handleCheckboxClick = (filterKey, filterOptionKey, isSelected) => {
        onChangeAllSubOptions(filterKey, filterOptionKey, !isSelected);
    };

    useEffect(() => {
        setFilterOptions(filter.options);
    }, [filter.options]);
    useEffect(() => {
        if (!filterOptions?.length) {
            return;
        }
        const topBrands = (filterOptions.filter((brand) => filter?.suggestions?.some((topBrand) => topBrand.key === brand.key))) || [];
        const remainingBrands = (filterOptions.filter((brand) => !topBrands.includes(brand))) || [];
        setBrands([
            { brandsData: topBrands, type: "Top Brands" },
            { brandsData: remainingBrands, type: "All Brands" }
        ]);
    }, [filter.suggestions, filterOptions]);
    return (
        <React.Fragment>
            <div styleName={"styles.makeModelWrap"}>
                <div styleName={"styles.searchWrapper"}>
                    <SearchFilterV2
                        placeholder="Search by Brand or Model"
                        searchItems={{ options: filter.options }}
                        onSearchHandler={onSearchHandler}
                    />
                </div>
                <div styleName="styles.listContainer">
                    {
                        searchValue && !filterOptions?.length ?
                            <div styleName={"styles.noResultFound"}>
                                <span>{`No Results Found for “${searchValue}”`}</span>
                                <img src={NoCarFoundIcon} alt="no car found" height={96} width={96} />
                            </div> :
                            brands && brands.map(({ brandsData, type } = {}, i) => (
                                (brandsData && brandsData?.length > 0) && <div key={i} styleName="styles.brandsLists">
                                    <h5 styleName={`styles.heading ${i === 0 ? "styles.zeroMargin" : ""}`}>{toTitleCase(type)}</h5>
                                    {
                                        (brandsData || []).map((filterOption, filterOptionIndex) => {
                                            const isAllOptionSelected = checkAllOptionStatus(filter.key, filterOption.key, filterOption.subFilter.options.length, selectedFilters);
                                            return (
                                                <Accordion key={filterOptionIndex} toggleAll={true} totalItems={filterOptions.length} visibleStateFlag={searchValue ? false : true} >
                                                    {({ onClick, currentVisibleStates }) => (
                                                        <Accordion.Content>
                                                            <div key={filterOption.key} styleName={"styles.accordianWrap styles.brandItem"}>
                                                                <Accordion.Heading>
                                                                    <div styleName={"styles.accordianHeading"} onClick={handleListExpand.bind(null, filterOptionIndex, onClick)}>
                                                                        <Checkbox
                                                                            checkedStatus={isAllOptionSelected || false}
                                                                            semiCheckedStatus={findByKey(selectedFilters, "optionKey", filterOption.key)?.optionKey ? !isAllOptionSelected : false}
                                                                            onChange={() => handleCheckboxClick(filter.key, filterOption.key, isAllOptionSelected)}
                                                                            id={`option_${filterOption.key}_${filterOption?.type}`}
                                                                            name={`option_${filterOption.key}_${filterOption?.type}`} />

                                                                        <div styleName={"styles.headingWrap"}>
                                                                            <h3 styleName={"styles.modelName"}>{`${toTitleCase(filterOption.displayText)} ${filterOption.count > 0 ? `(${filterOption.count})` : ""}`}</h3>
                                                                        </div>
                                                                        <ArrowIcon greyish={"greyish"} height={16} width={16} rotateBy={currentVisibleStates[filterOptionIndex] ? DOWN_ARROW_ROTATE_DEGREE : UP_ARROW_ROTATE_DEGREE} />
                                                                    </div>
                                                                </Accordion.Heading>
                                                                <Accordion.Body>
                                                                    {
                                                                        !currentVisibleStates[filterOptionIndex] && (
                                                                            <div styleName={"styles.accordianContent"}>
                                                                                <ul styleName={"styles.selectModelName"}>
                                                                                    {
                                                                                        filterOption.subFilter.options.map((subFilterOption) => {
                                                                                            const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: filterOption.key, subOptionKey: subFilterOption.key }, selectedFilters);
                                                                                            return (
                                                                                                <li styleName="styles.itemWrap styles.modelItem" key={`sub_option_${subFilterOption.key}`}>
                                                                                                    <Checkbox
                                                                                                        checkedStatus={isSelected}
                                                                                                        onChange={onChangeSubFilterOption.bind(null, filter.key, filterOption.key, subFilterOption.key, !isSelected, subFilterOption.displayText)}
                                                                                                        id={`sub_option_${subFilterOption.key}`}
                                                                                                        name={`sub_option_${subFilterOption.key}`} />
                                                                                                    {toTitleCase(subFilterOption.displayText)}
                                                                                                </li>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        )
                                                                    }

                                                                </Accordion.Body>
                                                            </div>
                                                        </Accordion.Content>
                                                    )}
                                                </Accordion>
                                            );
                                        })
                                    }
                                </div>
                            ))}
                </div>
            </div>
        </React.Fragment>
    );
};

ModelFilterRevamp.propTypes = {
    filter: PropTypes.object,
    handleUpdateSelectedFilters: PropTypes.func,
    onOptionSelection: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    selectedFilters: PropTypes.array,
    handleSubFilterUpdate: PropTypes.func
};

const mapStateToProps = ({
    filters: {
        selectedFilters,
        selectedSuggestions
    }
}) => ({
    selectedFilters,
    selectedSuggestions
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModelFilterRevamp);
