import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import MobileScreen from "./images/mobile-screen.png";
import QrCode from "../../shared/icon-social/images/app-download-qr-code.png";
import IconAppstore from "../../shared/icon-social/images/appstore-white.svg";
import IconPlaystore from "../../shared/icon-social/images/playstore-white.svg";

// Component
import PostBcTdWhatsNext from "../post-bc-td-whats-next";
import CarDetailCard from "../../shared/car-detail-card";

// Constant
import { DOWNLOAD_APP_URL, DOWNLOAD_APP_URL_IOS, INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";
import { TD_STEP_NAMES } from "../td-schedule/constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { BOOKING_TYPES } from "../../../constants/ae/checkout-constants";

const PostBcTdComplete = ({
    postBcDetails = {},
    carDetails = {}
}) => {

    const { orderId = "", bookingType } = postBcDetails || {};
    const isVideoTd = bookingType === BOOKING_TYPES.VIRTUAL_TD;
    const { content: { price: listingPrice, inventoryType } } = carDetails || {};

    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;

    useEffect(() => {
        trackDesktopCustomEventsAE("c2cBcDonePage", {eventLabel: orderId});
    }, [orderId]);

    const getPageText = () => {
        if (isC2C) {
            return {
                congratalutionsSubText: "Your Offer is approved. Please download the app to schedule test drive."
            };
        } else if (isVideoTd) {
            return {
                congratalutionsSubText: "Your video car viewing is complete. Please download the app to pay token. "
            };
        }

        return {
            congratalutionsSubText: "Your test Drive is complete. Please download the app to pay token."
        };
    };

    const { congratalutionsSubText } = getPageText();

    return (
        <Fragment>
            <div styleName={"styles.leftSection"}>
                <h2 styleName={"styles.heading"}>Congratulations!</h2>
                <h3 styleName={"styles.subheading"}>{congratalutionsSubText}</h3>
                <div styleName={"styles.downloadApp"}>
                    <div styleName={"styles.dFlex"}>
                        <div styleName={"styles.content"}>
                            <h3>Get the Best CARS24 Experience</h3>
                            <h4>Get best offers & updates!</h4>
                            <div styleName={"styles.qrGrid"}>
                                <div styleName={"styles.ctaDownload"}>
                                    <a href={DOWNLOAD_APP_URL_IOS} target="_blank">
                                        <img src={IconAppstore} width={108} height={36} alt="AppStore Icon" />
                                    </a>
                                    <a href={DOWNLOAD_APP_URL} target="_blank">
                                        <img src={IconPlaystore} width={108} height={36} alt="PlayStore Icon" />
                                    </a>
                                </div>
                                <figure>
                                    <img src={QrCode} width={80} height={80} alt="QR Code" />
                                </figure>
                            </div>
                        </div>
                        <figure>
                            <img src={MobileScreen} alt="Mobile Banner" width={119} />
                        </figure>
                    </div>
                </div>
            </div>
            <div styleName={"styles.rightSection"}>
                <div styleName={"styles.orderId"}>
                    Booking ID : #{orderId}
                </div>
                <CarDetailCard vehiclePrice={listingPrice} carDetails={carDetails} />
                <div styleName={"styles.whatsNextGrid"}>
                    <PostBcTdWhatsNext type={`${isC2C ? TD_STEP_NAMES.TD_SELECTION : TD_STEP_NAMES.TD_COMPLETED}`} />
                </div>
            </div>
        </Fragment>
    );
};

PostBcTdComplete.propTypes = {
    postBcDetails: PropTypes.object,
    carDetails: PropTypes.object
};

export default PostBcTdComplete;
