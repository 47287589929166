/* eslint-disable no-invalid-this */
/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import RangeFilterV2 from "../range-filter-v2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearSuggestions } from "../filters/actions";

const FILTER_TYPE = {
    ENGINE_SIZE: "engineSize",
    NO_OF_CYLINDERS: "noOfCylinders"
};

const EngineFilter = ({
    filter,
    onOptionSelection,
    appliedFilters,
    clearSuggestionsConnect,
    ...restProps
}) => {
    const {
        key,
        filterType,
        groupFacet
    } = filter;

    const { engineSize = {}, noOfCylinders = {} } = groupFacet;
    const [selectedSliderValue, setSelectedSliderValue] = useState({
        engineSize: {
            min: engineSize.min,
            max: engineSize.max
        }, noOfCylinders: {
            min: noOfCylinders.min,
            max: noOfCylinders.max
        }
    });

    const updateFiltersInStore = (type, rangeData) => {
        onOptionSelection({
            filterKey: key,
            isSelected: true,
            isRangeFilter: true,
            optionKey: type,
            filterType,
            displayText: groupFacet[type].displayText,
            rangeValues: {
                min: rangeData.min,
                max: rangeData.max
            }
        });
    };

    const handleOptionSelection = (type, rangeData) => {
        setSelectedSliderValue({ ...selectedSliderValue, [type]: { min: rangeData.min, max: rangeData.max } });
        updateFiltersInStore(type, rangeData);
    };

    let appliedEngineSizeMin = engineSize.min;
    let appliedNoOfCylindersMax = noOfCylinders.max;

    let appliedEngineSizeMax = engineSize.max;
    let appliedNoOfCylindersMin = noOfCylinders.min;

    const selectedEngineSizeAppliedFilter = getAppliedFilter({ filterKey: key, optionKey: FILTER_TYPE.ENGINE_SIZE }, appliedFilters);
    const selectedNoOfCylindersAppliedFilter = getAppliedFilter({ filterKey: key, optionKey: FILTER_TYPE.NO_OF_CYLINDERS }, appliedFilters);

    if (selectedEngineSizeAppliedFilter) {
        appliedEngineSizeMin = selectedEngineSizeAppliedFilter.min;
        appliedEngineSizeMax = selectedEngineSizeAppliedFilter.max;
    }

    if (selectedNoOfCylindersAppliedFilter) {
        appliedNoOfCylindersMin = selectedNoOfCylindersAppliedFilter.min;
        appliedNoOfCylindersMax = selectedNoOfCylindersAppliedFilter.max;
    }

    return (
        <React.Fragment>
            <div styleName={"styles.rangeFilterWrap"}>
                <div styleName={"styles.priceFilterType"}>{engineSize.displayText}</div>
                <RangeFilterV2
                    rangeType={FILTER_TYPE.ENGINE_SIZE}
                    filterLabel={{
                        min: "Min",
                        max: "Max"
                    }}
                    minValue={appliedEngineSizeMin}
                    maxValue={appliedEngineSizeMax}
                    minRange={engineSize.min}
                    maxRange={engineSize.max}
                    clearSuggestionsConnect={clearSuggestionsConnect.bind(null, key)}
                    step={engineSize.stepRate}
                    vertical={false}
                    showColumnLayout={false}
                    showLabelTop={true}
                    filterKey={key}
                    onOptionSelection={handleOptionSelection.bind(this, FILTER_TYPE.ENGINE_SIZE)}
                    {...restProps}
                />
                <p styleName={"styles.engineMinText"}>{`Minimum size of Engine is ${engineSize.min} and Maximum size is ${engineSize.max}.`}</p>
                <div styleName={"styles.emptySpace"} />
                <div styleName={"styles.priceFilterType"}>{noOfCylinders.displayText}</div>
                <RangeFilterV2
                    rangeType={FILTER_TYPE.NO_OF_CYLINDERS}
                    filterLabel={{
                        min: "Min",
                        max: "Max"
                    }}
                    minValue={appliedNoOfCylindersMin}
                    maxValue={appliedNoOfCylindersMax}
                    clearSuggestionsConnect={clearSuggestionsConnect.bind(null, key)}
                    minRange={noOfCylinders.min}
                    maxRange={noOfCylinders.max}
                    step={noOfCylinders.stepRate}
                    vertical={false}
                    showColumnLayout={false}
                    showLabelTop={true}
                    filterKey={key}
                    onOptionSelection={handleOptionSelection.bind(this, FILTER_TYPE.NO_OF_CYLINDERS)}
                    {...restProps}
                />
                <p styleName={"styles.engineMinText"}>{`Minimum value of Cylinders is ${noOfCylinders.min} and Maximum value is ${noOfCylinders.max}.`}</p>
            </div>
        </React.Fragment>
    );
};

EngineFilter.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    clearSuggestionsConnect: PropTypes.func,
    financePriceLimit: PropTypes.number
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearSuggestionsConnect: clearSuggestions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EngineFilter);
