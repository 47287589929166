import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import SaleStripDetailPage from "./component";

const mapStateToProps = ({
    config: {
        activeSaleConfig = {}
    }
}) => ({
    activeSaleConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleStripDetailPage);

