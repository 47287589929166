import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarDetailServiceHistory from "./component";

const mapStateToProps = ({
    carDetails: {
        content: {
            appointmentId,
            odometerReading,
            serviceHistory,
            assortmentCategory,
            inventoryType
        }
    }
}) => ({
    appointmentId,
    odometerReading,
    serviceHistory,
    assortmentCategory,
    inventoryType
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarDetailServiceHistory);
