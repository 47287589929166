/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Components
import CarDetailAtf from "../car-detail-atf";
import ArrowIcon from "../../shared/arrow";
import SimilarCarList from "../similar-car-list";
import CarGallery from "../car-gallery";
import withLogin from "../with-login";
import InternalLinkCarDetail from "../internal-link-car-detail";
import withWishlist from "../with-wishlist";
import BuyCarBreadCrumb from "../buy-car-breadcrumb";
import MetaTagsCarDetailsV2 from "../../shared/meta-tags-car-details-v2";
import PageNotFoundAe from "../../../components/shared/page-not-found-ae";
import PageLoader from "../../../components/shared/page-loader";
const SimilarCarComparisonList = loadable(() => import("../similar-car-comparison-list"), { ssr: false });
import Modal from "../../shared/modal";
import TestimonialsDetail from "../car-detail-testimonials";
import CarDetailSimilarCarList from "../car-detail-similar-car-list";
import ToastMessage from "../../shared/toast-message";

// Utilities
import { addToRecentlyViewed, getRecentlyViewed } from "../../../utils/helpers/recently-viewed";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { getPostBcCustomUrl } from "../../../utils/helpers/get-postbc-url";
import { carListingEventDataParserGA4 } from "../../../tracking/parser/car-listing-ga4";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import { checkCarStatus } from "../../../utils/helpers/car-properties.ae";

// Constants
import { CAR_STATUS, COOKIE_CONSTANTS, DEFAULT_AE_CITY_NAME, GA_EVENT_SOURCE, INVENTORY_TYPE_LABEL, LISTING_TYPE, NUMBER, PRIMARY_CTA_TEXT } from "../../../constants/app-constants";
import { C2C_OFFER_STATUS, ORDER_STATUS } from "../../../constants/ae/checkout-constants";
import { POST_BC_STEPS } from "../../../constants/ae/post-booking-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import carListingEventDataParser from "../../../tracking/parser/car-listing";

// Tracking
import cleverTap from "../../../tracking/clevertap";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import SeoLinksListingPageV2 from "../../shared/seo-links-listing-page-v2/index";
import { getPriceRangeFromPrice } from "../../../utils/helpers/get-price-range-from-price";
import { computeCarPriceURL } from "../../../utils/filters-v2/carprice";
import { getPrevAppliedFilters } from "../../../utils/helpers/prev-applied-filters";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { appUrl } from "../../../constants/url-constants";
import carDetailClevertapData from "../../../utils/helpers/car-detail-clevertap-data";
// import epochToFormattedDate from "../../../utils/helpers/epoch-to-formatted-date";

const SHOW_RECENTLY_VIEWED_AFTER_COUNT = 2;

const CarDetails = ({
    content,
    isSSR,
    fetchCarDetailsConnect,
    match,
    fetchRecentlyViewedCarListConnect,
    isGalleryOpen,
    secureToken,
    isLoggedIn,
    similarVehicleCount,
    fetchFinanceConnect,
    selectedCity,
    getDeliveryInfoConnect,
    getOrderDetailConnect,
    setChargeDetailsListConnect,
    setOrderIdConnect,
    setOrderConnect,
    checkout,
    updateCarDetailsSSRStatusConnect,
    fetchSimilarCarsConnect,
    history,
    resetCheckoutDataConnect,
    config,
    internalLinks = [],
    isCarDetailsFetching,
    impressionSource,
    isWishlistedCar,
    onClickAddToWishList,
    mobile,
    fetchFiltersConnect,
    fetchWishlistedCarsListConnect,
    getNumberOfBookingsConnect,
    bookingAllowed,
    carDetailError,
    allFilters,
    isFetching,
    isFilterSSR,
    detailConfig
}) => {
    const { params: { lid: carId } } = match;
    const { order } = checkout || {};
    const { orderId } = order || {};

    const {
        make,
        model,
        year,
        city,
        appointmentId,
        price: listingPrice, bodyType,
        booked, parentHubLocation,
        listingActive, carHighlights,
        emiDetails,
        inventoryType,
        assortmentCategory
    } = content || {};
   const {
        imageHost
    } = detailConfig || {};

    const [isCarSold, setIsCarSold] = useState(false);
    const isReservedCar = checkCarStatus({ booked, listingActive }, CAR_STATUS.RESERVED);
    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;
    const triggerGA4ViewItemEvent = () => {
        const dataGA4 = carListingEventDataParserGA4(content, {
            filterDimensions: {},
            listingType: LISTING_TYPE.CAR_DETAILS_PAGE
        });
        trackDesktopCustomEventsAE("clearEccomerce");
        trackDesktopCustomEventsAE("ga4ViewItem", {
            user_id: mobile,
            ...dataGA4
        });
    };

    useEffect(() => {
        updateCarDetailsSSRStatusConnect(false);
        resetCheckoutDataConnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (listingActive !== undefined) {
            setIsCarSold(!listingActive);
        }
    }, []);

    useEffect(() => {
        if (content) {
            carDetailClevertapData(content, detailConfig, selectedCity.name);
        }
    }, [isLoggedIn, content]);

    useEffect(() => {
        if (!isSSR) {
            fetchCarDetailsConnect(carId, true, "updatedGalleryDesign");
        }
        updateCarDetailsSSRStatusConnect(false);
    }, [carId]);
    const carCitySlug = dashedLowercase(selectedCity && selectedCity.name || DEFAULT_AE_CITY_NAME);
    const triggerCarDetailViewClevertapEvent = () => {
        const priceRangeDetails = getPriceRangeFromPrice(allFilters?.[FILTER_ENTITIES.PRICE]?.suggestions, listingPrice) || {};
        const priceRangeUrl = computeCarPriceURL(priceRangeDetails)?.[NUMBER.ONE] || "";
        const prevAppliedFilters = getPrevAppliedFilters();
        // const gaIdCoupon = couponList.length ? couponList.reduce((highest, current) => (current.config.maxCouponValue
        //     > highest.config.maxCouponValue
        //     ? current : highest), couponList[0]) : null;
        const clevertapEventData = {
            resumeSessionDeepLink: prevAppliedFilters?.latestFilterUrl ? `${appUrl()}${prevAppliedFilters?.latestFilterUrl}` : "",
            makeModelDeepLink: `${appUrl()}/buy-used-${dashedLowercase(make)}-${dashedLowercase(model)}-cars-${dashedLowercase(carCitySlug)}/`,
            makeDeepLink: `${appUrl()}/buy-used-${dashedLowercase(make)}-cars-${dashedLowercase(carCitySlug)}/`,
            bodyTypeDeepLink: `${appUrl()}/buy-used-${dashedLowercase(bodyType)}-cars-${dashedLowercase(carCitySlug)}/`,
            priceRange: `${priceRangeDetails?.min} - ${priceRangeDetails?.max}`,
            priceRangeDeepLink: `${appUrl()}/buy-used-cars-${priceRangeUrl}-${dashedLowercase(carCitySlug)}`,
            topHighlights: (carHighlights || [])?.slice(NUMBER.ZERO, NUMBER.THREE)?.map(item => item?.name)?.join(","),
            similarCarsUrl: `${appUrl()}/similar-cars?appointmentId=${appointmentId}`,
            parentHubLocation: parentHubLocation?.locationName,
            recommendedCarsUrl: `${appUrl()}/recommended-cars?appointmentId=${appointmentId}`,
            emi: `${emiDetails?.emi}/month`,
            // couponDiscount: gaIdCoupon?.config?.maxCouponValue || 0,
            // couponCode: gaIdCoupon?.code || "",
            // couponExpiryDate: epochToFormattedDate(gaIdCoupon?.expiry) || "",
            carType: assortmentCategory || ""
        };
        cleverTap.updateProfileCarDetailsView(clevertapEventData);
    };

    useEffect(() => {
        if (!isFetching && content) {
            triggerGA4ViewItemEvent();
            triggerCarDetailViewClevertapEvent(content);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, content]);

    useEffect(() => {
        const recentlyViewedCars = getRecentlyViewed(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);
        if (recentlyViewedCars?.length <= NUMBER.TWENTY_FIVE) {
            addToRecentlyViewed(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS, carId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId]);

    useEffect(() => {
        const recentlyViewedCars = getRecentlyViewed(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);
        if (recentlyViewedCars.length > NUMBER.TWENTY_FIVE) recentlyViewedCars.length = NUMBER.TWENTY_FIVE;
        if (recentlyViewedCars.length > SHOW_RECENTLY_VIEWED_AFTER_COUNT) {
            fetchRecentlyViewedCarListConnect(recentlyViewedCars);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (listingPrice) {
            fetchFinanceConnect(listingPrice, year, emiDetails?.financeEligibility);
        }
    }, [listingPrice]);

    useEffect(() => {
        if (isFilterSSR) return;
        try {
            const filterParams = { variant: "filterV3" };
            fetchFiltersConnect(filterParams);
        } catch (err) {
            // Handle if any error
        }
    }, [fetchFiltersConnect]);

    useEffect(() => {
        if (carId && secureToken && !isCarDetailsFetching) {
            getOrderDetailConnect(carId, secureToken).then((data) => {
                const { orderId: oid, chargeDetails } = data;
                setChargeDetailsListConnect(chargeDetails);
                setOrderIdConnect({ orderId: oid });
                setOrderConnect({
                    ...data
                });
                // createOrderHelper(data);
            }).catch(() => {
                // createOrderHelper({});
            });
        }
    }, [carId, secureToken, isCarDetailsFetching]);

    useEffect(() => {
        if (appointmentId && selectedCity?.code) {
            try {
                getDeliveryInfoConnect(appointmentId, selectedCity.code);
                // eslint-disable-next-line no-empty
            } catch (error) { }
        }
    }, [appointmentId, selectedCity?.code]);

    useEffect(() => {
        if (appointmentId) {
            trackDesktopCustomEventsAE("b2cDetailPageVisited", {
                car_id: appointmentId || "NA",
                car_type: assortmentCategory || ""
            });
            try {
                fetchSimilarCarsConnect(appointmentId, { size: 10 });
                // eslint-disable-next-line no-empty
            } catch (error) { }
        }
    }, [appointmentId]);

    const carName = `${make} ${model}`;
    const { relativeURL: carCheckoutUrl } = getDetailPageUrl({ year, carName, carId: carId || appointmentId, city, isCheckout: true, country: "ae" });

    const { order: { status: orderStatus, orderId: bookingId, latestOffer = {} } } = checkout;
    const { offerStatus = "" } = latestOffer || {};

    useEffect(() => {
        if (booked && orderStatus !== ORDER_STATUS.BOOKED) {
            trackDesktopCustomEventsAE("reservedcarCardClick", {
                eventLabel: appointmentId
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPrimaryCtaState = () => {

        let text = isC2C ? PRIMARY_CTA_TEXT.MAKE_AN_OFFER : PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE;
        let isDisabled = false;
        let notify = false;
        const isCarWishListed = isWishlistedCar(appointmentId);
        if (isLoggedIn && secureToken && listingActive) {
            if (orderStatus === ORDER_STATUS.BOOKED || ((orderStatus === ORDER_STATUS.CREATED) && (offerStatus === C2C_OFFER_STATUS.ACCEPTED))) {
                text = PRIMARY_CTA_TEXT.TRACK_BOOKING;
                isDisabled = false;
                // showEmiCalculator = false;
            } else if (booked && !isCarWishListed) {
                text = PRIMARY_CTA_TEXT.NOTIFY;
                notify = true;
                // showEmiCalculator = false;
            } else if (booked && isCarWishListed) {
                text = PRIMARY_CTA_TEXT.WISHLISTED;
                notify = true;
                // showEmiCalculator = false;
            } else if (!bookingAllowed) {
                isDisabled = true;
                // showEmiCalculator = false;
            } else {
                text = isC2C ? PRIMARY_CTA_TEXT.MAKE_AN_OFFER : PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE;
                isDisabled = false;
            }
        } else if (booked && !isCarWishListed && listingActive) {
            text = PRIMARY_CTA_TEXT.NOTIFY;
            notify = true;
            // showEmiCalculator = false;
        } else if (booked && isCarWishListed && listingActive) {
            text = PRIMARY_CTA_TEXT.WISHLISTED;
            notify = true;
            // showEmiCalculator = false;
        } else if (booked && listingActive) {
            text = PRIMARY_CTA_TEXT.RESERVED;
            isDisabled = true;
            // showEmiCalculator = false;
        }

        if (!listingActive && booked && !orderId) {
            text = PRIMARY_CTA_TEXT.NOT_AVAILABLE;
            isDisabled = true;
            // showEmiCalculator = false;
        } else if ((!listingActive && booked && orderId) || (!listingActive && !booked && orderId)) {
            text = PRIMARY_CTA_TEXT.TRACK_BOOKING;
            isDisabled = false;
            // showEmiCalculator = false;
        } else if (!listingActive) {
            text = PRIMARY_CTA_TEXT.NOT_AVAILABLE;
            isDisabled = true;
            // showEmiCalculator = false;
        }

        return {
            text,
            disabled: isDisabled,
            notify
        };
    };

    const primaryCtaState = getPrimaryCtaState();

    const addToWishlist = (token = "") => {
        if (isWishlistedCar(appointmentId)) {
            onClickAddToWishList(appointmentId, token, { removeFromWishlist: true }, false);
        } else {
            trackDesktopCustomEventsAE("notifyMeClick");
            onClickAddToWishList(appointmentId, token, {
                callback: (shortListed) => {
                    if (shortListed) {
                        trackDesktopCustomEventsAE("notifyMeSuccess");
                    }
                }
            }, true);
        }
    };

    const redirectToCheckout = (source) => {
        cleverTap.desktopCleverTapEvent(cleverTap.desktopConstants.START_PURCHASE, {
            imageHost,
            carDetailsContent: content,
            cityName: selectedCity?.name
        });
        trackDesktopCustomEventsAE("b2cPurchaseProcessProceedCTA");
        history.push(`${carCheckoutUrl}?source=${source ? source : GA_EVENT_SOURCE.DETAIL_PAGE}`);
    };

    const redirectToPostBcStepsPage = () => {
        const { relativeURL: postBcStepsRelativeUrl } = getPostBcCustomUrl({ lid: appointmentId, step: POST_BC_STEPS.testDrive });
        history.push(postBcStepsRelativeUrl);
    };

    const triggerGA4AddToCartEvent = () => {
        const dataGA4 = carListingEventDataParserGA4(content, {
            filterDimensions: {},
            listingType: LISTING_TYPE.CAR_DETAILS_PAGE
        });
        trackDesktopCustomEventsAE("clearEccomerce");
        trackDesktopCustomEventsAE("ga4AddToCart", {
            user_id: mobile,
            booking_id: orderId || "NA",
            appointment_id: appointmentId,
            ...dataGA4
        });
    };

    const onCheckoutButtonClick = (setShowLogin, source = null) => {

        if (primaryCtaState && ([PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE, PRIMARY_CTA_TEXT.MAKE_AN_OFFER].includes(primaryCtaState.text))) {
            trackDesktopCustomEventsAE("startPurchaseDetailPage", {
                appointment_id: appointmentId,
                eventLabel: source ? source : GA_EVENT_SOURCE.DETAIL_PAGE
            });
        }

        if (primaryCtaState?.text === PRIMARY_CTA_TEXT.TRACK_BOOKING) {
            redirectToPostBcStepsPage();
        } else if (isReservedCar && !isLoggedIn) {
            setShowLogin(true);
        } else if (isReservedCar && isLoggedIn) {
            addToWishlist(secureToken);
        } else {
            redirectToCheckout(source);
        }
        triggerGA4AddToCartEvent();
    };

    const onLoginSuccessCallback = (handleShowLogin, token) => {
        handleShowLogin(false);
        if (booked && orderStatus !== ORDER_STATUS.BOOKED) {
            addToWishlist(token);
            return;
        } else {
            getNumberOfBookingsConnect(token).then((response) => {
                if (response.bookingAllowed) {
                    trackDesktopCustomEventsAE("b2c_pushedBookingInitiated", {
                        booking_id: bookingId || "NA",
                        eventLabel: GA_EVENT_SOURCE.DETAIL_PAGE,
                        appointment_id: appointmentId
                    });
                    const data = carListingEventDataParser(content, {
                        step: "1"
                    });
                    trackDesktopCustomEventsAE("checkout", data.checkout);
                    redirectToCheckout();
                }
            });
        }
    };

    const ctaProps = {
        onCheckoutButtonClick,
        onLoginSuccessCallback,
        ...primaryCtaState
    };

    const onBreadcrumbClick = ({ url }) => {
        history.push(url);
    };

    const onClickViewAllMake = () => {
        history.push(`/buy-used-${dashedLowercase(make)}-cars-${dashedLowercase(selectedCity?.name)}/`);
    };

    const onTestimonialsShown = () => {
        trackDesktopCustomEventsAE("testimonialsShown");
    };

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
            getNumberOfBookingsConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (appointmentId) {
            const data = carListingEventDataParser(content, {
                list: impressionSource,
                excludedKeys: [
                    "list"
                ]
            });
            trackDesktopCustomEventsAE("carDetails", data.carDetails);
        }
    }, [appointmentId, content, impressionSource]);

    if (carDetailError?.error) {
        return <PageNotFoundAe />;
    }

    return (
        <React.Fragment>
            {(!isFetching && content?.appointmentId) ?
                <React.Fragment>
                    <MetaTagsCarDetailsV2 config={config} carDetails={content} cityName={selectedCity.name || DEFAULT_AE_CITY_NAME} countryCode="ae" />
                    <div>
                        {isCarSold && <div styleName={"styles.soldHeight"}>
                            {similarVehicleCount > 0 && <section>
                                <div className={"container"}>
                                    <h2>Similar cars</h2>
                                    <SimilarCarList appointmentId={appointmentId} limit={NUMBER.THREE} />
                                </div>
                                <button onClick={onClickViewAllMake} styleName={"styles.similarBtnCta styles.similarMakeBtnCta"}>VIEW ALL {make} CARS<ArrowIcon darkblue rotateBy={-90} /></button>
                            </section>}
                        </div>}
                        <div>
                            <div className={"container"} styleName={"styles.breadcrumbWrapper"}>
                                <BuyCarBreadCrumb onClickCallback={onBreadcrumbClick} />
                            </div>
                        </div>
                        <InView rootMargin={"100px 0px 100px 0px"} as="div" />
                        <div>
                            <CarDetailAtf ctaProps={ctaProps} />
                        </div>
                        <div className="container" styleName={"styles.sectionGapTop"}>
                            <h2 styleName={"styles.headingWrap"}>Compare similar cars</h2>
                            <SimilarCarComparisonList />
                        </div>
                        <InView
                            rootMargin={"0px 0px 0px 0px"}
                            id={"ourHappyCustomers"}
                            as="div"
                            threshold={NUMBER.DECIMAL_FIVE}
                            onChange={onTestimonialsShown}
                        >
                            <TestimonialsDetail />
                        </InView>
                        {similarVehicleCount > 0 && !isCarSold && !isReservedCar && <section styleName={"styles.similarCarsWrapper"}>
                            <div className={"container"}>
                                <h2>Similar cars</h2>
                                <CarDetailSimilarCarList appointmentId={appointmentId} />
                            </div>
                        </section>}
                        <section styleName={"styles.moreCar"}>
                            <div className={"container"}>
                                <h2>More cars for you</h2>
                                <InternalLinkCarDetail
                                    internalLinks={internalLinks}
                                    selectedCityName={selectedCity?.name}
                                    config={config}
                                />
                            </div>
                        </section>
                        <section styleName={"styles.seoLinkWrap"}>
                            <div className="container">
                                {!isCarDetailsFetching && (
                                    <SeoLinksListingPageV2 pageSource={ROUTE_NAME.DETAIL_PAGE} />
                                )}
                            </div>
                        </section>
                        {
                            isGalleryOpen
                            && <Modal isOpen={true}>
                                <CarGallery ctaProps={ctaProps} />
                            </Modal>
                        }
                        <ToastMessage />
                    </div>
                </React.Fragment> : <PageLoader />}
        </React.Fragment>
    );
};

CarDetails.propTypes = {
    content: PropTypes.object,
    isSSR: PropTypes.bool,
    fetchCarDetailsConnect: PropTypes.func,
    match: PropTypes.object,
    fetchRecentlyViewedCarListConnect: PropTypes.func,
    isGalleryOpen: PropTypes.bool,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    similarVehicleCount: PropTypes.number,
    fetchFinanceConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    getDeliveryInfoConnect: PropTypes.func,
    checkout: PropTypes.object,
    getOrderDetailConnect: PropTypes.func,
    setChargeDetailsListConnect: PropTypes.func,
    setOrderIdConnect: PropTypes.func,
    setOrderConnect: PropTypes.func,
    updateCarDetailsSSRStatusConnect: PropTypes.func,
    fetchSimilarCarsConnect: PropTypes.func,
    history: PropTypes.object,
    resetCheckoutDataConnect: PropTypes.func,
    config: PropTypes.object,
    isCarDetailsFetching: PropTypes.bool,
    impressionSource: PropTypes.string,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    mobile: PropTypes.string,
    detailConfig: PropTypes.object,
    fetchFiltersConnect: PropTypes.func,
    fetchWishlistedCarsListConnect: PropTypes.func,
    getNumberOfBookingsConnect: PropTypes.func,
    bookingAllowed: PropTypes.bool,
    carDetailError: PropTypes.object,
    allFilters: PropTypes.array,
    isFetching: PropTypes.bool,
    getConfigConnect: PropTypes.func,
    internalLinks: PropTypes.array,
    isFilterSSR: PropTypes.bool
};

export default withWishlist(withLogin(CarDetails));
