import React from "react";

// Images
import odometerReading from "./images/kilometer.svg";
import transmissionType from "./images/transmission.svg";
import specs from "./images/gcc.svg";
import fuelType from "./images/fuel-type.svg";
import drive from "./images/drive-type.svg";
import interiorTrimType from "./images/trim.svg";
import noOfAirbags from "./images/airbag.svg";
import numberOfSeats from "./images/seating.svg";
import noOfKeys from "./images/keys.svg";
import engineSize from "./images/engine-size.svg";
import bodyType from "./images/body-type.svg";
import alloyWheels from "./images/wheel-type.svg";
import carExteriorColor from "./images/exterior-color.svg";
import optionsType from "./images/options-type.svg";
import batterycapacityinkwh from "./images/battery-capacity.svg";
import electricmotorpowerinkw from "./images/electric-power-motor.svg";
import batterychargingtime from "./images/electrical-services.svg";
import topspeed from "./images/top-speed.svg";
import vin from "./images/vin-number.svg";
import bootSpace from "./images/boot-space.svg";
import defaultIcon from "./images/check_circle.svg";
import loanstatus from "./images/loan-status.svg";
import firstowner from "./images/first-owner.svg";
import registrationemirate from "./images/registration-emirate.svg";
import nationality from "./images/seller-nationality.svg";
import accidentFree from "./images/accident-free.svg";

const ICONS_CAR_OVERVIEW = {
    odometerReading,
    specs,
    engineSize,
    transmissionType,
    drive,
    bodyType,
    defaultIcon,
    fuelType,
    alloyWheels,
    carExteriorColor,
    interiorTrimType,
    noOfAirbags,
    noOfKeys,
    numberOfSeats,
    optionsType,
    vin,
    batterycapacityinkwh,
    electricmotorpowerinkw,
    drivingrange: odometerReading,
    batterychargingtime,
    topspeed,
    bootSpace,
    nationality,
    firstowner,
    registrationemirate,
    loanstatus,
    accidentFree
};

export default ICONS_CAR_OVERVIEW;
