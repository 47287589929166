import { resetReducerKeys } from "../../../constants/app-constants";
import Types from "./types";

const updateLoanEligibility = (payload) => ({
    type: Types.UPDATE_LOAN_ELIGIBILITY,
    data: payload
});

// RESET Loan Eligibility
const resetLoanEligibility = () => ({
    type: resetReducerKeys.RESET_LOAN_ELIGIBILITY
});

export {
    updateLoanEligibility,
    resetLoanEligibility
};
