import Statsig from "statsig-js";
import { NUMBER } from "../../constants/app-constants";
const { v4: uuidv4 } = require("uuid");
import saveCookie from "../helpers/save-cookie";
import parseCookie from "../helpers/parse-cookie";
import statsigEnv from "./statsig-host";
export const statsigInitialization = async ({ city, statsigId = null }) => {
    const userId = parseCookie("userId");
    const statsigStableId = parseCookie("statsigStableId");
    const finalUserId = !statsigStableId ? uuidv4() : statsigStableId;
    if (!statsigStableId && statsigId) {
        saveCookie("statsigStableId", statsigId, NUMBER.ONE_HUNDRED_EIGHTY);
    } else if (!statsigStableId) {
        saveCookie("statsigStableId", finalUserId, NUMBER.ONE_HUNDRED_EIGHTY);
    }
    const statsigUser = {
        userID: userId,
        customIDs: { stableID: finalUserId },
        custom: {
            city
        }
    };
    await Statsig.initialize(
        process.env.STATSIG_SECRET_CSR_KEY,
        statsigUser,
        {
            environment: { tier: statsigEnv() },
            overrideStableID: finalUserId
        } // optional, pass options here if needed
    );
};
