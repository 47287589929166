import Types from "./types";
import { UserAuthService, userProfileService, couponService } from "../../service";
import { COOKIE_CONSTANTS, HTTP_CODES } from "../../constants/app-constants";
import cleverTap from "../../tracking/clevertap";
import { proxyAuthState } from "../../service/userAuthApi";
import { resetCarCfDetails } from "../ae.mobile/checkout-finance-info/actions";
import { resetUserCfDetails } from "../ae.mobile/financing-info/actions";
import { resetLeadCreation } from "../ae.mobile/car-evaluation-info/actions";
import { resetWishlistedCars } from "../ae.mobile/my-wishlist/actions";
import { resetTofService } from "../ae.mobile/tof-service/actions";
import { resetCarLoan } from "../ae.mobile/car-loan-home/actions";
import { setTradeInChoice } from "../ae.mobile/checkout-info/actions";
import deleteCookie from "../../utils/helpers/delete-cookie";
import { resetLoanEligibility, updateLoanEligibility } from "../ae.mobile/loan-eligibility-modal/actions";
import { resetSellerPendingLeads } from "../ae.mobile/seller-leads-return-user-snackbar/actions";

const setName = (name) => ({
    type: Types.SET_NAME,
    name
});

const setProfileName = (profileName) => ({
    type: Types.SET_PROFILE_NAME,
    profileName
});

const setMobile = (mobile) => ({
    type: Types.SET_MOBILE,
    mobile
});

const setLastVerifiedMobile = (lastVerifiedMobile) => ({
    type: Types.SET_LAST_VERIFIED_MOBILE,
    lastVerifiedMobile
});

const setProfileMobile = (profileMobile) => ({
    type: Types.SET_PROFILE_MOBILE,
    profileMobile
});

const setEmail = (email) => ({
    type: Types.SET_EMAIL,
    email
});

const setCheckAuth = (checkAuth) => ({
    type: Types.CHECK_AUTH,
    checkAuth
});

// eslint-disable-next-line
const getProfileDetailsSuccess = ({ email, name, mobilePhone, token }) => ({
    type: Types.GET_PROFILE_DETAILS_SUCCESS,
    email,
    name,
    token,
    phoneNumber: mobilePhone
});

const markUnauthorized = () => ({
    type: Types.MARK_UNAUTHORIZED
});

const setProfileWithToken = (token, params = { updateConfig: true, updateDnd: false, isWhatsappOptIn: true }) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        try {
            const { config: { platform } } = getState();
            // eslint-disable-next-line no-undef
            const userData = JSON.parse(atob(token?.split(".")[1]));
            dispatch(getProfileDetailsSuccess({ ...userData, token }));
            // Sending userData as our "source of truth" and not user reducer
            if (params && params.updateDnd) { cleverTap.login({ user: { ...userData, mobile: userData?.mobilePhone }, platform, isWhatsappOptIn: params.isWhatsappOptIn }); }
            dispatch(setCheckAuth(false));
            resolve(userData);
        } catch (error) {
            dispatch(setCheckAuth(false));
            reject(error);
        }
    });
};

const userProfile = (token, params) => () => {
    return new Promise((resolve, reject) => {
        userProfileService.userProfile(token, params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const getUserProfile = (token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        userProfileService.getUserProfile(token)
            .then(response =>  {
                dispatch(updateLoanEligibility(response?.data?.userFinancialDetail));
                resolve(response.data);
            }).catch(error => reject(error));
    });
};

const getRefreshTokenInfo = () => dispatch => {
    return new Promise((resolve, reject) => {
        UserAuthService.getRefreshTokenInfo().then(response => {
            const { access_token: accessToken } = response.data;
            proxyAuthState.accessToken = accessToken;
            proxyAuthState.isLoggedIn = true;
            dispatch(setProfileWithToken(accessToken, { updateDnd: true, isWhatsappOptIn: true, updateConfig: false }));
            resolve(response.data);
        }).catch(error => {
            proxyAuthState.accessToken = null;
            proxyAuthState.isLoggedIn = false;
            // dispatch(logoutFailure(error));
            reject(error);
        });
    });
};

const updateProfileInit = () => ({
    type: Types.UPDATE_PROFILE
});

const updateProfileSuccess = ({ name, email }
) => ({
    type: Types.UPDATE_PROFILE_SUCCESS,
    name, email
});

const updateProfileFailure = () => ({
    type: Types.UPDATE_PROFILE_FAILURE
});

const updateProfile = (name = "", email = "", mobile = "") => (dispatch, getState) => {
    const { user: { isDNDActive, secureToken } } = getState();
    dispatch(updateProfileInit());
    return new Promise((resolve, reject) => {
        UserAuthService.updateProfile(name, email, secureToken).then(response => {
            dispatch(updateProfileSuccess({ name, email }));
            dispatch(getRefreshTokenInfo());
            cleverTap.updateDND(isDNDActive, { mobile, email });
            resolve(response);
        }).catch(error => {
            dispatch(updateProfileFailure(error));
            reject(error);
        });
    });
};

const setFirstLoad = () => ({
    type: Types.SET_FIRST_LOAD
});

const updateLeadType = (leadType) => ({
    type: Types.UPDATE_LEAD_TYPE,
    leadType
});

const setSocketId = (socketId) => ({ type: Types.SET_SOCKET_ID, socketId });

const setTruecallerPromptCount = (trueCallerPromptCount) => ({
    type: Types.TRUECALLER_PROMPT_COUNT,
    trueCallerPromptCount
});

const updateDNDStatus = (isDNDActive = false) => ({
    type: Types.UPDATE_DND_STATUS,
    isDNDActive
});

const updateWAStatus = (isWAConsent = false) => ({
    type: Types.UPDATE_WA_STATUS,
    isWAConsent
});

const updateWebviewStatus = (isWebview) => ({
    type: Types.UPDATE_WEBVIEW_STATUS,
    isWebview
});

const setGAId = (gaId) => ({
    type: Types.SET_GA_ID,
    gaId
});

const updateCurrentPageType = (currentPageType) => ({
    type: Types.UPDATE_CURRENT_PAGE_TYPE,
    currentPageType
});

const getTokenInfo = () => dispatch => {
    return new Promise((resolve, reject) => {
        UserAuthService.getTokenInfo()
            .then(response => {
                const { access_token: accessToken } = response.data;
                dispatch(setProfileWithToken(accessToken));
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === HTTP_CODES.UNAUTHORIZED) {
                    dispatch(markUnauthorized());
                }
                dispatch(setCheckAuth(false));
                reject(error);
            });
    });
};

const verifyOtp = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        UserAuthService.verifyOTP(params)
            .then(response => {
                const { access_token: accessToken } = response.data;
                dispatch(setProfileWithToken(accessToken));
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const logoutSuccess = () => {
    return {
        type: Types.LOGOUT_SUCCESS
    };
};

const logoutFailure = () => ({
    type: Types.LOGOUT_FAILURE
});

const setLoginModal = (isOpen) => ({
    type: Types.SET_LOGIN_MODAL,
    isOpen
});

const logout = () => dispatch => {
    dispatch(updateProfileInit());
    return new Promise((resolve, reject) => {
        UserAuthService.logout().then(response => {
            dispatch(logoutSuccess());
            dispatch(resetCarCfDetails());
            dispatch(resetUserCfDetails());
            dispatch(resetLeadCreation());
            dispatch(resetSellerPendingLeads());
            dispatch(resetWishlistedCars());
            dispatch(resetTofService());
            dispatch(resetCarLoan());
            dispatch(setTradeInChoice(null));
            dispatch(resetLoanEligibility());
            deleteCookie(COOKIE_CONSTANTS.TOKEN);
            resolve(response);
        }).catch(error => {
            dispatch(logoutFailure(error));
            reject(error);
        });
    });
};

const updateShowCouponNudge = (showCouponNudge = false) => ({
    type: Types.UPDATE_SHOW_COUPON_NUDGE,
    showCouponNudge
});

const updateDiscountCoupon = (discountCoupon) => ({
    type: Types.UPDATE_DISCOUNT_COUPON,
    discountCoupon
});

const fetchLockedCouponList = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        couponService.getLockedCoupons(params)
            .then(response => {
                dispatch(updateDiscountCoupon(response.data));
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

const updateLocationPopupShown = (showLocationPopup) => dispatch => {
    dispatch({
        type: Types.UPDATE_LOCATION_POPUP_SHOWN,
        showLocationPopup
    });
};

const updateOpenAICarFinderModal = (openAICarFinderModal) => dispatch => {
    dispatch({
        type: Types.UPDATE_OPEN_AI_CAR_FINDER_MODAL,
        openAICarFinderModal
    });
};

const updateLoanbudgetNudge = (showLoanBugetNudge) => dispatch => {
    dispatch({
        type: Types.UPDATE_LOAN_BUDGET_NUDGE,
        showLoanBugetNudge
    });
};
const updateDownloadNudgeStatus = (showDownloadNudge) => dispatch => {
    dispatch({
        type: Types.UPDATE_DOWNLOAD_NUDGE_STATUS,
        showDownloadNudge
    });
};

export {
    setName,
    setProfileName,
    setMobile,
    setProfileMobile,
    setEmail,
    setProfileWithToken,
    setCheckAuth,
    setFirstLoad,
    updateLeadType,
    setSocketId,
    setTruecallerPromptCount,
    updateWebviewStatus,
    setGAId,
    updateCurrentPageType,
    updateProfile,
    setLastVerifiedMobile,
    getTokenInfo,
    verifyOtp,
    logout,
    logoutSuccess,
    getRefreshTokenInfo,
    updateDNDStatus,
    updateWAStatus,
    userProfile,
    getUserProfile,
    setLoginModal,
    updateShowCouponNudge,
    updateLocationPopupShown,
    updateOpenAICarFinderModal,
    updateLoanbudgetNudge,
    updateDiscountCoupon,
    fetchLockedCouponList,
    updateDownloadNudgeStatus
};
