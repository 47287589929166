import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "./component";
import { updateUserConsent, sendOTP, fetchUserConsent } from "./actions";
import { updateProfile, setProfileWithToken, userProfile, getTokenInfo, verifyOtp, updateWAStatus } from "../../ae.configuration/actions";
import withLogin from "../with-login";

const mapStateToProps = ({
    user: { mobile, name, isLoggedIn, email, isWAConsent },
    checkout,
    carDetails: { content: { make, model } },
    location: {
        selectedCity: {
            code: cityCode,
            lat,
            lng
        }
    },
    config: {
        activeSaleConfig = {}
    }
}) => ({
    mobile,
    name,
    isLoggedIn,
    email,
    make,
    model,
    checkout,
    isWAConsent,
    cityCode,
    lat,
    lng,
    activeSaleConfig
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    verifyOtpConnect: verifyOtp,
    sendOTPConnect: sendOTP,
    updateProfileConnect: updateProfile,
    getTokenInfoConnect: getTokenInfo,
    setProfileWithTokenConnect: setProfileWithToken,
    updateUserConsentConnect: updateUserConsent,
    userProfileConnect: userProfile,
    updateWAStatusConnect: updateWAStatus,
    fetchUserConsentConnect: fetchUserConsent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
export { withLogin };
