/* eslint-disable max-statements */
import { C2B_PURCHASE_REQUEST } from "../constants/api-constants";

/* eslint-disable max-params */
export default (api) => {
    const fetchLeadDetail = (appointmentId) => api.get(`${C2B_PURCHASE_REQUEST}/apt-${appointmentId}`);
    const uploadDocument = (appointmentId, params) => api.put(`/v1/purchase-request/apt-${appointmentId}/server/document/upload`, params, { headers: { "content-type": "multipart/form-data" } });
    const updateLoanDetails = (appointmentId, payload) => api.put(`${C2B_PURCHASE_REQUEST}/apt-${appointmentId}/post-price-acceptance`, payload);
    const updatePaymentMode = (appointmentId, customerId, payload) => api.put(`${C2B_PURCHASE_REQUEST}/apt-${appointmentId}/payment-method/${customerId}`, payload);
    const fetchRTA = () => api.get(`/v1/config/rta-centers`);
    const updateRTADetails = (appointmentId, payload) => api.put(`${C2B_PURCHASE_REQUEST}/apt-${appointmentId}/provide-rta-pro`, payload);

    // C2C
    const fetchAvailabelPackages = () => api.get(`/v1/c2c/available/package`);
    const fetchPackageDetailById = (packageId) => api.get(`/v1/c2c/available/package?packageId=${packageId}`);
    const createOrder = (payload, secureToken) => api.post(`/v1/c2c/order-detail/orders`, payload, { headers: { "Authorization": `Bearer ${secureToken}` } });
    const getOrderDetails = (appointmentId, secureToken) => api.get(`/v1/c2c/order-detail/apt-${appointmentId}/orders`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const makeC2CPayment = (payload, secureToken) => api.post(`/v1/c2c/orders/${payload?.orderId}/payment`, payload, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const getPaymentStatus = (appId, orderId, secureToken) => api.get(`/v1/c2c/orders/${appId}/payment-status/${orderId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const getGsOfferForSnackbar = (appId, secureToken) => api.get(`/v1/c2c/gs/apt-${appId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const acceptC2CGsOffer = (appId, secureToken) => api.post(`/v1/c2c/gs/accept/apt-${appId}`, {}, {headers: { "Authorization": `Bearer ${secureToken}` }});

    return {
        fetchLeadDetail,
        uploadDocument,
        updateLoanDetails,
        updatePaymentMode,
        fetchRTA,
        updateRTADetails,
        fetchAvailabelPackages,
        fetchPackageDetailById,
        createOrder,
        getOrderDetails,
        makeC2CPayment,
        getPaymentStatus,
        getGsOfferForSnackbar,
        acceptC2CGsOffer
    };
};
