import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_SSR_STATUS
    
    FETCH_CAR_DETAILS
    FETCH_CAR_DETAILS_SUCCESS
    FETCH_CAR_DETAILS_FAILURE

    FETCH_SIMILAR_CARS
    FETCH_SIMILAR_CARS_SUCCESS
    FETCH_SIMILAR_CARS_FAILURE

    FETCH_SIMILAR_CARS_FOR_MODAL_INIT
    FETCH_SIMILAR_CARS_FOR_MODAL_SUCCESS
    FETCH_SIMILAR_CARS_FOR_MODAL_FAILURE

    FETCH_EMI_DATA_SUCCESS
    FETCH_EMI_DATA_FAILURE

    SET_EMI_VALUES

    SET_GS_GALLERY_DATA
    SET_CAR_DETAILS_URL

    FETCH_SIMILAR_CARS_COMPARE
    FETCH_SIMILAR_CARS_COMPARE_SUCCESS
    FETCH_SIMILAR_CARS_COMPARE_FAILURE
`,
    {
        prefix: "cardetails/"
    }
);
