/* eslint-disable complexity */

import { FILTER_ENTITIES } from "../../utils/filters-v2/constants";
import { formatDate, DATE_FORMAT_TYPE } from "../../utils/helpers/get-day-from-date";
import gaId from "../../utils/helpers/get-ga-from-cookie";
const basicParser = filter => filter.map(fv => fv.displayText).join("|");
// const basicParser = (filter = {}) => filter.displayText;
const rangeParser = (filter = []) => (filter && filter[0]) ? [filter[0].min, filter[0].max].join("-") : undefined;
const budgetParser = (filter = []) => (filter && filter[0]) ? { min: filter[0].min, max: filter[0].max } : {};

const parserMap = {
    [FILTER_ENTITIES.PRICE]: budgetParser,
    [FILTER_ENTITIES.BODY_TYPE]: basicParser,
    [FILTER_ENTITIES.ODOMETER_READING]: rangeParser,
    [FILTER_ENTITIES.YEAR]: rangeParser,
    [FILTER_ENTITIES.FUEL_TYPE]: basicParser,
    [FILTER_ENTITIES.TRANSMISSION]: basicParser,
    [FILTER_ENTITIES.MAKE]: basicParser
};

export const parseFilterToDimension = (filters, applied) => {
    const appliedFilters = Object.keys(filters || {})
        .filter(filter => applied.filter(f => f.filterKey === filter).length && parserMap[filter])
        .reduce((pv, cv) => {
            return {
                ...pv,
                ...{ [cv]: parserMap[cv](applied.filter(f => f.filterKey === cv)) }
            };
        }, {});

    return {
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension57: appliedFilters[FILTER_ENTITIES.PRICE].min }),
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension58: appliedFilters[FILTER_ENTITIES.PRICE].max }),
        ...(appliedFilters[FILTER_ENTITIES.MAKE] && { dimension59: appliedFilters[FILTER_ENTITIES.MAKE] }),
        ...(appliedFilters[FILTER_ENTITIES.ODOMETER_READING] && { dimension60: appliedFilters[FILTER_ENTITIES.ODOMETER_READING] }),
        ...(appliedFilters[FILTER_ENTITIES.YEAR] && { dimension61: appliedFilters[FILTER_ENTITIES.YEAR] }),
        ...(appliedFilters[FILTER_ENTITIES.FUEL_TYPE] && { dimension62: appliedFilters[FILTER_ENTITIES.FUEL_TYPE] }),
        ...(appliedFilters[FILTER_ENTITIES.TRANSMISSION] && { dimension63: appliedFilters[FILTER_ENTITIES.TRANSMISSION] }),
        ...(appliedFilters[FILTER_ENTITIES.BODY_TYPE] && { dimension65: appliedFilters[FILTER_ENTITIES.BODY_TYPE] })
    };
};

const carData = (carDetailsData, {
    list,
    position,
    excludedKeys = [],
    filterDimensions = {}
}) => {
    const {
        appointmentId,
        carId,
        price,
        lastPrice,
        listingPrice,
        make,
        bodyType,
        fuelType,
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        registrationNumber,
        model,
        transmissionType
    } = carDetailsData || {};
    const data = {
        name: `${year} ${make} ${model}`,
        id: appointmentId || carId,
        price: price || lastPrice || listingPrice,
        brand: make,
        category: bodyType,
        variant: fuelType,
        list,
        position,
        dimension51: city,
        dimension52: views,
        dimension53: odometerReading || kilometerDriven,
        dimension54: year,
        dimension58: registrationNumber,
        dimension56: model,
        dimension55: transmissionType,
        ...filterDimensions
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

const checkoutData = (carDetailsData, {
    bookingAmount,
    excludedKeys = []
}) => {
    const {
        appointmentId,
        carId,
        price,
        lastPrice,
        listingPrice,
        make,
        bodyType,
        fuelType,
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        registrationNumber,
        model,
        transmissionType
    } = carDetailsData || {};
    const data = {
        name: `${year} ${make} ${model}`,
        id: appointmentId || carId,
        price: price || lastPrice || listingPrice,
        brand: make,
        category: bodyType,
        variant: fuelType,
        dimension51: city,
        dimension52: views,
        dimension53: odometerReading || kilometerDriven,
        dimension54: year,
        dimension58: registrationNumber,
        dimension56: model,
        dimension55: transmissionType,
        dimension66: bookingAmount
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

const autosuggestionsData = (suggestionsData, suggestionProperties) => {
    const topSuggestion = {};
    const date = new Date();
    if (suggestionsData.length) {
        (suggestionsData || []).forEach((item, index) => {
            if (item.suggestion) {
                topSuggestion[`suggestion${index + 1}`] = item.suggestion;
            }
        });
    }

    const data = {
        ...suggestionProperties,
        ...topSuggestion,
        timestamp: formatDate(new Date(date), DATE_FORMAT_TYPE.DATE_MONTH_YEAR_TIME),
        userId: gaId
    };
    return data;
};

export default (payload, properties) => {
    return {
        carsImpression: {
            ecommerce: {
                currencyCode: "AED",
                impressions: [carData(payload, properties)]
            }
        },
        carDetails: {
            ecommerce: {
                detail: {
                    actionField: {
                        list: properties.list
                    },
                    products: [carData(payload, properties)]
                }
            }
        },
        carClick: {
            ecommerce: {
                click: {
                    actionField: { "list": properties.list },
                    products: [carData(payload, properties)]
                }
            }
        },
        startPurchase: {
            ecommerce: {
                currencyCode: "AED",
                add: {
                    products: [carData(payload, properties)]
                }
            }
        },
        checkout: {
            ecommerce: {
                checkout: {
                    actionField: { "step": properties.step, option: properties.option },
                    products: [{
                        ...checkoutData(payload, properties)
                    }]
                }
            }
        },
        thankYou: {
            ecommerce: {
                currencyCode: "INR",
                detail: {
                    actionField: {
                        ...properties.payload, coupon: "", affiliation: "Online Store"
                    },
                    products: [{
                        ...checkoutData(payload, properties)
                    }]
                }
            }
        },
        searchSuggestions: {
            "event": "searchSuggestions",
            ecommerce: {
                autosuggestions: [{ ...autosuggestionsData(payload, properties) }]
            }

        }
    };
};
