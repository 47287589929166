import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import RangeFilterV2 from "../range-filter-v2/component";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { clearSuggestions } from "../filters/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const YearFilterRevamp = ({ filter, onOptionSelection, appliedFilters, clearSuggestionsConnect, ...restProps }) => {
    const {
        min,
        max,
        stepRate,
        key
        // displayText
    } = filter;

    const handleOptionSelection = (rangeData) => {
        onOptionSelection({
            filterKey: key,
            isSelected: true,
            isRangeFilter: true,
            rangeValues: {
                ...rangeData
            }
        });
    };

    const selectedAppliedFilter = getAppliedFilter({ filterKey: key }, appliedFilters);

    let appliedMin = min;
    let appliedMax = max;

    if (selectedAppliedFilter) {
        appliedMin = selectedAppliedFilter.min;
        appliedMax = selectedAppliedFilter.max;
    }

    return (
        <div styleName={"styles.yearFilterRevamp styles.rangeFilterWrap"}>
            <RangeFilterV2
                filterLabel={{
                    min: "Min Year",
                    max: "Max Year"
                }}
                minValue={appliedMin}
                maxValue={appliedMax}
                minRange={min}
                maxRange={max}
                step={stepRate}
                onOptionSelection={handleOptionSelection}
                clearSuggestionsConnect={clearSuggestionsConnect.bind(null, key)}
                priceFormatting={false}
                vertical={false}
                showColumnLayout={false}
                showLabelTop={true}
                {...restProps}
            />
        </div>
    );
};

YearFilterRevamp.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    clearSuggestionsConnect: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearSuggestionsConnect: clearSuggestions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(YearFilterRevamp);
