/* eslint-disable complexity */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./styles.css";

//Components
import Login from "../login-revamp";

// Images
import CarBanner from "./images/sale-banner.webp";
import ImageQr from "./images/qr.webp";
import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";
import { SOURCES } from "../login/constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const SeasonalSaleListingBanner = ({
    seasonalSale,
    isLoggedIn,
    isloginMandatoryForSale = false
}) => {
    const [showLogin, setShowLogin] = useState(false);
    const { additionalData = {} } = seasonalSale || {};
    const { imageUrl = "", backgroundGradients = "" } = additionalData || {};
    const inlineStyles = backgroundGradients ? { backgroundImage: `linear-gradient(${backgroundGradients})` } : {};
    const parsedSaleData = useMemo(() => parseSeasonalSaleData(seasonalSale), [seasonalSale]) || {};
    const { title, subTitle, description } = parsedSaleData || {};

    const onLoginSuccess = () => {
        setShowLogin(false);
    };
    const closeLoginModal = () => {
        setShowLogin(false);
    };

    const onLoginClick = () => {
        trackDesktopCustomEventsAE("saleBannerLoginClickListingPage");
        setShowLogin(true);
    };

    return (
        <Login isVisible={showLogin} onSuccess={onLoginSuccess} onClose={closeLoginModal} loginFor={SOURCES.SALE_BANNER_LISTING}>
            <div styleName={"styles.bannerWrapper"} style={inlineStyles}>
                <div styleName={"styles.flexRow"}>
                    <div styleName={"styles.content"}>
                        <h1 dangerouslySetInnerHTML={{ __html: title || "" }} />
                        <p dangerouslySetInnerHTML={{ __html: subTitle || "" }} />
                        {isloginMandatoryForSale && !isLoggedIn ?
                            <span styleName={"styles.loginCta"} onClick={onLoginClick} >Login to view discounts</span> : <ul>
                                {
                                    (description || []).map((item, index) => {
                                        return <li key={index} dangerouslySetInnerHTML={{ __html: item || "" }} />;
                                    })
                                }
                            </ul>
                        }
                    </div>
                    <div styleName={"styles.banner"}>
                        <img src={imageUrl || CarBanner} alt="sale banner" width={198} height={132} />
                        <img src={ImageQr} alt="sale qr" width={150} height={100} />
                    </div>
                </div>
            </div>
        </Login>
    );
};

SeasonalSaleListingBanner.propTypes = {
    seasonalSale: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isloginMandatoryForSale: PropTypes.bool
};

export default SeasonalSaleListingBanner;
