import React from "react";
import PropTypes from "prop-types";
import RangeFilterV2 from "../range-filter-v2";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { clearSuggestions } from "../filters/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const KmFilterRevamp = ({ filter, onOptionSelection, appliedFilters, clearSuggestionsConnect, ...restProps }) => {
    const {
        min,
        max,
        stepRate,
        key
        // displayText
    } = filter;

    const handleOptionSelection = (rangeData) => {
        onOptionSelection({
            filterKey: key,
            isSelected: true,
            isRangeFilter: true,
            rangeValues: {
                ...rangeData
            }
        });
    };

    const selectedAppliedFilter = getAppliedFilter({ filterKey: key }, appliedFilters);

    let appliedMin = min;
    let appliedMax = max;

    if (selectedAppliedFilter) {
        appliedMin = selectedAppliedFilter.min;
        appliedMax = selectedAppliedFilter.max;
    }

    return (
        <React.Fragment>
            <div styleName={"styles.rangeFilterWrap"}>
                <RangeFilterV2
                    filterLabel={{
                        min: "Min Kms",
                        max: "Max Kms"
                    }}
                    minValue={appliedMin}
                    maxValue={appliedMax}
                    minRange={0}
                    maxRange={max}
                    step={stepRate}
                    onOptionSelection={handleOptionSelection}
                    clearSuggestionsConnect={clearSuggestionsConnect.bind(null, key)}
                    vertical={false}
                    showColumnLayout={false}
                    showLabelTop={true}
                    editable={true}
                    {...restProps}
                />
            </div>
        </React.Fragment>
    );
};

KmFilterRevamp.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    clearSuggestionsConnect: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearSuggestionsConnect: clearSuggestions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KmFilterRevamp);
