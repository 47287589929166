// Services
import { ListingService } from "../../../service";

// Utilities
import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import initAppliedFilters from "../../../utils/filters-v2/init-applied-filters";
import { updateSSRFiltersList } from "../filters/actions";

// Constants
import { ALL_FILTERS_MOBILE } from "../../../utils/filters-v2/constants";

// Types
import Types from "./types";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString, version = "v2") => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchCarListInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, version, secureToken)
            .then(response => {
                dispatch(fetchCarListSuccess(response.data, params));
                if (response.data.selectedFilters) {
                    const data = response.data.selectedFilters;
                    const parsedFilters = getFiltersData(data, true);
                    const appliedFiltersData = initAppliedFilters(parsedFilters.allFilters);
                    const onScreenFiltersList = ALL_FILTERS_MOBILE.filter((filter) => !parsedFilters.allFilters[filter]);
                    dispatch(updateSSRFiltersList({ ...appliedFiltersData, isSubFacetFullySelected: data?.make?.buckets, isSSR: false, selectedFilterCount: response.data.selectedFilterCount, onScreenFiltersList }));
                }
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarCarListInit = () => ({
    type: Types.FETCH_SIMILAR_CAR_LIST
});

const fetchSimilarCarListSuccess = (data, params) => ({
    type: Types.FETCH_SIMILAR_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchSimilarCarListFailure = (error) => ({ type: Types.FETCH_SIMILAR_CAR_LIST_FAILURE, error });

const fetchSimilarCarListOnSearch = (params, queryString, version = "v2") => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchSimilarCarListInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, version, secureToken)
            .then(response => {
                dispatch(fetchSimilarCarListSuccess(response.data, params));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(fetchSimilarCarListFailure(error));
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const resetCarList = () => ({
    type: Types.RESET_CAR_LIST
});

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});

const toggleSnackBar = (showSnackBar) => ({
    type: Types.TOGGLE_SNACK_BAR,
    showSnackBar
});

// RECOMMENDED ACTIONS
const fetchRecommendedCarListInit = () => ({
    type: Types.FETCH_RECOMMENDED_CAR_LIST
});

const fetchRecommendedCarListSuccess = (data, params) => ({
    type: Types.FETCH_RECOMMENDED_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchRecommendedCarListFailure = (error) => ({ type: Types.FETCH_RECOMMENDED_CAR_LIST_FAILURE, error });

const fetchRecommendedCarList = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchRecommendedCarListInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, "", secureToken)
            .then(response => {
                dispatch(fetchRecommendedCarListSuccess(response.data, params));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchRecommendedCarListFailure(error));
                reject(error);
            });
    });
};

const resetRecommendedCarList = () => ({
    type: Types.RESET_RECOMMENDED_CAR_LIST
});

const reloadRecommendedCarList = (shouldReloadList) => ({
    type: Types.RELOAD_RECOMMENDED_CAR_LIST,
    shouldReloadList
});

export {
    fetchCarList,
    updateSSRStatus,
    resetCarList,
    reloadCarList,
    fetchSimilarCarListOnSearch,

    // RECOMMENDED
    fetchRecommendedCarList,
    resetRecommendedCarList,
    reloadRecommendedCarList,

    toggleSnackBar
};
